import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from "react-helmet";


const Accounting = () => {
  return (
    <div>
       <Helmet>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="keywords"
        content="accounting software, financial software, business accounting, manage finances"
      />
      <meta
        name="description"
        content="Explore top accounting software solutions with Vilva Business. Streamline your financial operations and manage your business finances efficiently with our recommended accounting software products."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <title>Accounting Softwares - Vilva Business</title>
    </Helmet>
      <Navbar/>
         <div>
    

      <div className="features-layout2 pt-5 mt-5">
        <div className="row mx-auto container">
          <div className="col-12 col-md-4 mb-5">
            <div className="layout-col-2 mt-3">
              <div className="top-bg" />
              <div className="imgforcol-test">
                {/* <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png" alt="" /> */}
              </div>
              <h3>Enhanced Accuracy</h3>
              <p>Automates calculations and data entry, reducing human errors and ensuring precise financial records.</p>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-5">
            <div className="layout-col-2 mt-3">
              <div className="top-bg-c" />
              <div className="imgforcol">
                {/* <img src= "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png" alt="" /> */}
              </div>
              <h3>Time Savings</h3>
              <p>Speeds up financial processes such as invoicing and expense tracking, allowing more time for strategic business activities.</p>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-5">
            <div className="layout-col-2 mt-3">
              <div className="top-bg" />
              <div className="imgforcol-test">
                {/* <img src= "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png" alt="" /> */}
              </div>
              <h3>Real-Time Insights</h3>
              <p>Provides up-to-date financial information and reports, facilitating informed decision-making and better financial management.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="start-project mt-5">
        <div className="row mx-auto">
          <div className="col-12 col-md-6 project">
            <img src= "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
          </div>
          <div className="col-12 col-md-6 project-des" style={{ color: '#001e60' }}>
            <div className="project-des-con">
              <h2>Start your project with Us today</h2>
              <p>Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!</p>

              <div className="layout-btn">
                <button type="button">
                  Start a Project &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagesblog/>

    </div>
    <Footer/>
    </div>
  )
}

export default Accounting
