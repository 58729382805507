import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Growsuite = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="GrowSuite, business growth, customer solutions, business software"
        />
        <meta
          name="description"
          content="Discover GrowSuite by Vilva Business, your ultimate solution for business growth. Tailored to enhance efficiency and drive success in your organization."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>GrowSuite - Vilva Business</title>
      </Helmet>
      <Navbar />
      {/* <section
        className="page-title bg-overlay-black-60 jarallax"
        data-speed="0.6"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-title-name">
                <h1>Growsuite</h1>
                <p></p>
              </div>
              <ul className="page-breadcrumb">
                <li>
                  <a href="/">
                    <i className="fa fa-home"></i> Home
                  </a>{" "}
                  <i className="fa fa-angle-double-right"></i>
                </li>
                <li>
                  <span>Growsuite</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}

      <div className="prod-des container pt-5">
        <div className="prod-head">
          <h5 style={{ color: "#4a12f0" }}>Ready to move forward?</h5>
          <h2 style={{ color: "#001e60" }}>
            Comprehensive tools for business growth
          </h2>
        </div>
        <div className="benifits mx-auto">
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Comprehensive Growth Tools</h3>
            <p>
              GrowSuite provides a wide range of integrated tools to manage and
              optimize various aspects of business growth, including marketing,
              sales, and customer engagement.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Data-Driven Insights</h3>
            <p>
              It offers robust analytics and reporting features that help
              businesses make informed decisions based on real-time data and
              performance metrics.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Scalable Solutions</h3>
            <p>
              GrowSuite’s flexible and scalable platform can adapt to the needs
              of businesses of all sizes, allowing for easy expansion and
              customization as the business grows.
            </p>
          </div>
        </div>
      </div>

      <div className="usecase-all">
        <div className="usecase-head">
          <h5 style={{ color: "#4a12f0" }}>
            Optimizes marketing, sales, CRM and analytics
          </h5>
          <h2 style={{ color: "#001e60" }}>
            Manage Every Endpoint in One Place
          </h2>
          <h5 style={{ color: "#28334a" }}>
            Unify the management of every endpoint regardless of platform or
            ownership model.
          </h5>
        </div>
        <div className="row container pt-5 mx-auto">
          <div className="col-md-6 col-12">
            <div className="useimg">
              <img
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg"
                alt=""
                style={{ height: "70%", width: "90%" }}
              />
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="row" id="row1">
              <h3 style={{ color: "#040e56" }}>Marketing Automation</h3>
              <p>
                Streamline email campaigns, social media management, and lead
                nurturing with automated tools.
              </p>
            </div>
            <div className="row" id="row2">
              <h3 style={{ color: "#040e56" }}>Customer Relationship Management (CRM)</h3>
              <p>
                Enhance your customer relationships with our powerful CRM tools.
                Effectively maintain and analyze customer data to deliver
                personalized service, improve customer interactions, and drive
                satisfaction.
              </p>
            </div>
            <div className="row" id="row3">
              <h3 style={{ color: "#040e56" }}>Analytics and Reporting</h3>
              <p>
                Unlock actionable insights with comprehensive analytics and
                reporting features. Generate detailed reports to monitor
                performance, guide strategic decisions, and measure the success
                of your marketing campaigns.
              </p>
            </div>
            <div className="row" id="row4">
              <h3 style={{ color: "#040e56" }}>Lead Generation and Management</h3>
              <p>
                Supercharge your lead generation and management with integrated
                tools. Capture, track, and convert leads efficiently, and drive
                substantial growth for your business.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ready mt-5 pb-5 container">
        <div className="ready-des">
          <h3 style={{ color: "#001e60" }}>
            Start Your Journey with Vilva Business!
          </h3>
          <h5 style={{ color: "#28334a" }}>
            Experience device management, made easy!
          </h5>
          <div className="contact-us">
            <button type="button">
              <a href="/contact" target="_blank" style={{ color: "white" }}>
                Explore the Benefits
              </a>
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <Pagesblog/>
      <Footer />
    </div>
  );
};

export default Growsuite;
