import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Virtualapi = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="virtual API accounts, API integration, online payment APIs, secure API services"
        />
        <meta
          name="description"
          content="Discover Vilva Business's virtual API accounts for seamless integration of online payment APIs. Enhance your transactional capabilities with our secure API services."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Virtual API Accounts - Vilva Business</title>
      </Helmet>
      <Navbar/>
     
<div className="features-layout2 mt-5 pt-5">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img
            src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}"
            alt=""
            srcSet=""
          />
        </div>
        <h3>Efficient Financial Management</h3>
        <p>
          Virtual account APIs streamline financial operations by automating
          the creation and management of virtual accounts, enabling businesses
          to handle transactions and reconciliations more efficiently and
          accurately.
        </p>
      </div>
    </div>
    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img
            src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}"
            alt=""
            srcSet=""
          />
        </div>
        <h3>Enhanced Cash Flow Control</h3>
        <p>
          With virtual accounts, businesses can easily segregate funds for
          different purposes or departments, providing better control over cash
          flow and simplifying financial tracking and reporting.
        </p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img
            src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}"
            alt=""
            srcSet=""
          />
        </div>
        <h3>Improved Reconciliation and Reporting</h3>
        <p>
          Virtual account APIs facilitate detailed transaction tracking and
          reconciliation by generating unique virtual accounts for different
          purposes, making it easier to manage and report on financial
          activities accurately.
        </p>
      </div>
    </div>
  </div>
</div>
<div className="start-project mt-5">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img
        src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png"
        alt=""
        className="img-fluid"
        srcSet=""
      />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: "#001e60" }}>
      <div className="project-des-con">
        <h2>Start your project with Us today</h2>
        <p>
          Get started with our cutting-edge e-commerce solutions today and
          elevate your online business with tailored strategies and innovative
          technology. Transform your customer experience and drive growth
          effortlessly!
        </p>

        <div className="layout-btn">
          <button type="button">
            Start a Project &nbsp;&nbsp;&nbsp;
            <i className="ri-arrow-right-line"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Virtualapi
