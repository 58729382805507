import React from "react";
import "./dashboard.css";

const Wallet = () => {
  return (
    <div>
      <div className="path_dashboard">
        <p>
          <span>
            Dashboard
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.5 15L12.5 10L7.5 5"
                stroke="#999999"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          Wallet
        </p>
      </div>
      <div className="graph_img">
        <div className="graph_img_1">
          <div className="graph1_des">
            <div className="balance_all">
              <div className="balance">
                <div className="balance_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <circle opacity="0.2" cx="9" cy="9" r="9" fill="#F44D4D" />
                    <circle cx="9" cy="9" r="6" fill="#FF6666" />
                  </svg>
                </div>
                <h2>Balance</h2>
              </div>
              <div className="this_month_all">
                <div className="this_month">
                  <p>This Month</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="black"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="price">
              <p>₹ 20,000</p>
            </div>
            <div className="graph_percent_all">
              <div className="g_percent">
                <span>+2%</span>
                <div className="graph_percent_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5.8335 5.83333H14.1668V14.1667"
                      stroke="#0DC410"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.8335 14.1667L14.1668 5.83333"
                      stroke="#0DC410"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="graph_img_2">
          <div className="graph1_des">
            <div className="balance_all">
              <div className="balance">
                <div className="balance_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <circle opacity="0.2" cx="9" cy="9" r="9" fill="#F7AC3B" />
                    <circle cx="9" cy="9" r="6" fill="#F7AC3B" />
                  </svg>
                </div>
                <h2>Spending</h2>
              </div>
              <div className="this_month_all">
                <div className="this_month">
                  <p>This Month</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="black"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="price">
              <p>₹ 10,000</p>
            </div>
            <div className="graph_percent_all">
              <div className="g_percent">
                <span>+2%</span>
                <div className="graph_percent_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5.8335 5.83333H14.1668V14.1667"
                      stroke="#0DC410"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.8335 14.1667L14.1668 5.83333"
                      stroke="#0DC410"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="graph_img_3">
          <div className="graph1_des">
            <div className="balance_all">
              <div className="balance">
                <div className="balance_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <circle opacity="0.2" cx="9" cy="9" r="9" fill="#0EF511" />
                    <circle cx="9" cy="9" r="6" fill="#0DC410" />
                  </svg>
                </div>
                <h2>Added Amount</h2>
              </div>
              <div className="this_month_all">
                <div className="this_month">
                  <p>This Month</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="black"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="price">
              <p>₹ 30,000</p>
            </div>
            <div className="graph_percent_all">
              <div className="g_percent">
                <span>+2%</span>
                <div className="graph_percent_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5.8335 5.83333H14.1668V14.1667"
                      stroke="#0DC410"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.8335 14.1667L14.1668 5.83333"
                      stroke="#0DC410"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="invoice_table">
        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>SI. NO</th>
                <th>Date</th>
                {/* <th>Product</th> */}
                <th>Description</th>
                <th>Amount</th>
                <th> Opening Balance</th>
                <th>Closing Balance</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </td>
              </tr>{" "}
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </td>
              </tr>{" "}
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </td>
              </tr>{" "}
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Business Growth Software</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>₹ 25,000</td>
                <td>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                      stroke="#666666"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
