import React from "react";
import Navbar from '../Navbar'
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from "react-helmet";


const Aadharpan = () => {
  return (
    <div>
      <Helmet>
      <title>Aadhaar and PAN Verification API - Vilva Business</title>
      <meta name="keywords" content="Aadhaar verification API, PAN verification API, identity verification, KYC solutions, API integration, secure verification"/>
<meta name="description" content="Leverage Aadhaar and PAN Verification API by Vilva Business for secure and efficient identity verification, enabling seamless KYC processes and compliance."/>
<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>

      </Helmet>
      <Navbar/>
      <>
       

        <div className="prod-des container mt-5">
          <div className="prod-head">
            <h5 style={{ color: "#4a12f0" }}>
              Minimize fraud with reliable verification.
            </h5>
            <h2 style={{ color: "#001e60" }}>
              Ensure accurate identity verification
            </h2>
          </div>
          <div className="benifits mx-auto">
            <div className="col-md-3 col-12 ben-ai">
              <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png" alt="Enhanced Identity Verification" />
              <h3 style={{ color: "#001e60" }}>
                Enhanced Identity Verification
              </h3>
              <p>
                Ensures accurate and reliable identity verification by
                cross-checking Aadhar and PAN details.
              </p>
            </div>
            <div className="col-md-3 col-12 ben-ai">
              <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png" alt="Reduced Fraud Risk" />
              <h3 style={{ color: "#001e60" }}>Reduced Fraud Risk</h3>
              <p>
                Minimizes fraudulent activities by validating government-issued
                IDs and ensuring authenticity.
              </p>
            </div>
            <div className="col-md-3 col-12 ben-ai">
              <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png" alt="Regulatory Compliance" />
              <h3 style={{ color: "#001e60" }}>Regulatory Compliance</h3>
              <p>
                Assists businesses in meeting legal and regulatory requirements
                for identity and financial verification.
              </p>
            </div>
          </div>
        </div>

        <div className="usecase-all">
          <div className="usecase-head">
            <h5 style={{ color: "#4a12f0" }}>Use Cases</h5>
            <h2 style={{ color: "#001e60" }}>
              Manage Every Endpoint in One Place
            </h2>
            <h5 style={{ color: "#28334a" }}>
              Streamline and secure identity verification
            </h5>
          </div>
          <div className="row container pt-5 mx-auto">
            <div className="col-md-6 col-12">
              <div className="useimg">
                <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="Use Case" style={{ height: '100%', width: '90%' }} />
              </div>
            </div>
            <div className="col-12 col-md-6 mt-5">
              <div className="row" id="row1">
                <h3 style={{ color: "#040e56" }}>Customer Onboarding</h3>
                <p>
                  Verifying the identity of new customers during account
                  creation.
                </p>
              </div>
              <div className="row" id="row2">
                <h3 style={{ color: "#040e56" }}>KYC Compliance</h3>
                <p>
                  Ensuring compliance with Know Your Customer (KYC) regulations
                  in financial services.
                </p>
              </div>
              <div className="row" id="row3">
                <h3 style={{ color: "#040e56" }}>Loan Processing</h3>
                <p>
                  Validating the identity and financial status of applicants for
                  loans.
                </p>
              </div>
              <div className="row" id="row4">
                <h3 style={{ color: "#040e56" }}>Fraud Prevention</h3>
                <p>
                  Detecting and preventing fraudulent activities by
                  cross-checking identity documents.
                </p>
              </div>
              <div className="row" id="row5">
                <h3 style={{ color: "#040e56" }}>Tax Filing</h3>
                <p>
                  Verifying taxpayer identities and PAN details during
                  tax-related processes.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ready mt-5 pb-5 container">
          <div className="ready-des">
            <h3 style={{ color: "#001e60" }}>Ready to Get Started?</h3>
            <h5 style={{ color: "#28334a" }}>
              Experience device management, made easy!
            </h5>
            <div className="contact-us">
              <button type="button">
                Contact Us <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
        <Pagesblog/>

      </>
      <Footer/>

    </div>
  );
};

export default Aadharpan;
