import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Bankverification = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="bank verification API, financial services API, verify bank accounts, secure API"
        />
        <meta
          name="description"
          content="Explore Vilva Business's bank verification API for secure and reliable verification of bank accounts. Integrate our API to streamline your financial services."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Bank Verification API - Vilva Business</title>
      </Helmet>
      <Navbar/>
     

<div className="features-layout2 mt-5 pt-5">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
        </div>
        <h3>Enhanced Security and Fraud Prevention</h3>
        <p>Our bank verification APIs ensure secure transactions by verifying account details in real-time, reducing the risk of fraud and enhancing the safety of financial transactions.</p>
      </div>
    </div>
    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
        </div>
        <h3>Streamlined Onboarding and Compliance</h3>
        <p>The APIs simplify the customer onboarding process by quickly verifying bank details, ensuring compliance with regulatory requirements and reducing manual checks and administrative overhead.</p>
      </div>
    </div>
    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
        </div>
        <h3>Real-Time Verification and Accuracy</h3>
        <p>Our APIs provide instant and accurate verification of bank account information, improving the efficiency of financial operations and ensuring that transactions are processed smoothly and correctly.</p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: "#001e60" }}>
      <div className="project-des-con">
        <h2>Start your project with Us today</h2>
        <p>Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!</p>
        <div className="layout-btn">
          <button type="button">Start a Project &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Bankverification
