import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Eventsignup = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="event signup, ticket sales, event marketing, event promotion, Vilva Business"
        />
        <meta
          name="description"
          content="Discover Vilva Business's event signup and ticket sales marketing strategies for effective event promotion and attendee engagement. Enhance your event success with our targeted marketing solutions."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Event Signup & Ticket Sales Marketing - Vilva Business</title>
      </Helmet>
      <Navbar/>
  

<div className="prod-des container mt-5">
  <div className="prod-head">
    <h5 style={{color: "#4a12f0"}}>Transform Events with Seamless Ticketing</h5>
    <h2 style={{color: "#001e60"}}>Maximize attendance with smart ticketing solutions</h2>
  </div>
  <div className="benefits row mx-auto">
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png" alt="" />
      <h3 style={{color: "#001e60"}}>Increased Reach</h3>
      <p>Targeted marketing strategies help you reach a wider audience through digital channels, boosting ticket sales and event awareness.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png" alt="" />
      <h3 style={{color: "#001e60"}}>Real-Time Analytics</h3>
      <p>Track ticket sales and attendee behavior in real-time, allowing for quick adjustments to marketing strategies and maximizing event success.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png" alt="" />
      <h3 style={{color: "#001e60"}}>Automated Processes</h3>
      <p>Streamline the ticketing process with automated sign-ups, reminders, and confirmations, reducing administrative workload and enhancing the customer experience.</p>
    </div>
  </div>
</div>

<div className="usecase-all">
  <div className="usecase-head">
    <h5 style={{color: "#4a12f0"}}>Use Cases</h5>
    <h2 style={{color: "#001e60"}}>Manage Every Endpoint in One Place</h2>
    <h5 style={{color: "#28334a"}}>Boost event registrations effortlessly</h5>
  </div>
  <div className="row container pt-5 mx-auto">
    <div className="col-md-6 col-12">
      <div className="useimg">
        <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="" style={{height: "100%", width: "90%"}} />
      </div>
    </div>
    <div className="col-12 col-md-6 mt-5">
      <div className="row" id="row1">
        <h3 style={{ color: "#040e56" }}>Concert Promotions</h3>
        <p>Engage music fans effectively.</p>
      </div>
      <div className="row" id="row2">
        <h3 style={{ color: "#040e56" }}>Conference Registrations</h3>
        <p>Simplify attendee sign-ups.</p>
      </div>
      <div className="row" id="row3">
        <h3 style={{ color: "#040e56" }}>Festival Sales</h3>
        <p>Boost ticket sales efficiently.</p>
      </div>
      <div className="row" id="row4">
        <h3 style={{ color: "#040e56" }}>Workshop Bookings</h3>
        <p>Manage participant registrations easily.</p>
      </div>
      <div className="row" id="row5">
        <h3 style={{ color: "#040e56" }}>Webinar Invitations</h3>
        <p>Enhance virtual event engagement.</p>
      </div>
    </div>
  </div>
</div>

<div className="ready mt-5 pb-5 container">
  <div className="ready-des">
    <h3 style={{color: "#001e60"}}>Ready to Get Started?</h3>
    <h5 style={{color: "#28334a"}}>Experience device management, made easy!</h5>
    <div className="contact-us">
      <button type="button">Contact Us <i className="fa-solid fa-arrow-right"></i></button>
    </div>
  </div>
</div>

{/* <div className="latest-news pt-5 pb-5">
  <h3 style={{color: "#4a12f0", textAlign: "center", paddingBottom: "30px"}}>Latest News</h3>
  <div className="container">
    <div className="row">
      <div className="col-md-7 col-12">
        <div className="latest-img-all">
          <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="" style={{height: "85%", width: "50%"}} />
          <div className="img-des">
            <h6 style={{color: "#4a12f0"}}>Blog</h6>
            <h3 style={{color: "#001e60"}}>Event sign-up and ticket sales marketing</h3>
            <p style={{color: "#28334a"}}>
              It is evolving with innovations like dynamic pricing, which adjusts ticket costs based on demand, and integration with social media platforms for targeted ads. The rise of mobile-first strategies ensures that ticket purchasing is optimized for smartphones, while AI-powered analytics provides deeper insights into customer behavior. Additionally, virtual and hybrid events are becoming more prevalent, necessitating seamless online registration systems and real-time updates.
            </p>
            <div className="read-more">
              <button type="button">Read More &nbsp; &nbsp; <i className="fa-solid fa-arrow-right"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 col-12">
        <div className="row">
          <div className="blog">
            <h5>Features</h5>
            <h4>Dynamic Pricing</h4>
            <div className="b-btn">
              <button type="button">Read More &nbsp; &nbsp; <i className="fa-solid fa-arrow-right"></i></button>
              <i className="fa-solid fa-newspaper" style={{color: "#616899", fontSize: "20px"}}></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="blog">
            <h5>Features</h5>
            <h4>AI Integration</h4>
            <div className="b-btn">
              <button type="button">Read More &nbsp; &nbsp; <i className="fa-solid fa-arrow-right"></i></button>
              <i className="fa-solid fa-newspaper" style={{color: "#616899", fontSize: "20px"}}></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="blog">
            <h5>Features</h5>
            <h4>AI-driven Analytics</h4>
            <div className="b-btn">
              <button type="button">Read More &nbsp; &nbsp; <i className="fa-solid fa-arrow-right"></i></button>
              <i className="fa-solid fa-newspaper" style={{color: "#616899", fontSize: "20px"}}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Eventsignup
