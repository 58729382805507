import React from "react";
import "./dashboard.css";
import Searchbar from "./Searchbar";
const Vilvakart = () => {
  return (
    <div>
      <div className="route_navigation_all pe-4">
        <div className="route_navigation">
          <p style={{ cursor: "pointer" }}>
            <span>
              Dashboard{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span>
              Services{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            VilvaKart
          </p>
        </div>
        <div className="route_filters_all">
          <div className="route_filters">
            <p>Filter</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M2.5 5H17.5"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.83337 10H14.1667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.33337 15H11.6667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="route_filters">
            <p>This Month</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="black"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="working_on_it">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="177"
          height="240"
          viewBox="0 0 177 240"
          fill="none"
        >
          <path
            d="M70.9497 69.67C71.4852 65.7795 65.9939 62.5784 61.8682 62.2459C59.9005 62.0872 57.8569 62.3882 55.9929 61.7382C51.9544 60.3304 50.3406 55.1129 46.4001 53.4504C43.4542 52.2077 40.0622 53.3139 37.2057 54.7518C34.3492 56.189 31.527 57.9987 28.3358 58.2025C25.5601 58.3798 22.5463 57.3509 20.1174 58.7065C18.3077 59.7169 17.3595 61.8019 15.6921 63.0324C14.0604 64.2358 11.9318 64.4911 9.90687 64.6012C7.88193 64.7113 5.7862 64.732 3.94288 65.5758C2.09955 66.4195 0.574411 68.3693 0.956948 70.3607L70.9497 69.67Z"
            fill="#EBEBEB"
          />
          <path
            d="M131.679 126.077H129.489V176.658H131.679V126.077Z"
            fill="#263238"
          />
          <path
            d="M113.489 127.905H111.299V178.486H113.489V127.905Z"
            fill="#263238"
          />
          <path
            d="M132.229 130.606H128.944V125.708C128.944 125.547 128.825 125.416 128.68 125.416H114.303C114.158 125.416 114.04 125.547 114.04 125.708V129.781H110.754V125.708C110.754 123.735 112.346 122.131 114.303 122.131H128.68C130.637 122.131 132.229 123.736 132.229 125.708V130.606Z"
            fill="#455A64"
          />
          <path
            d="M111.121 236.045C111.121 237.925 109.597 239.449 107.716 239.449C105.836 239.449 104.311 237.925 104.311 236.045C104.311 234.164 105.836 232.64 107.716 232.64C109.597 232.64 111.121 234.164 111.121 236.045Z"
            fill="#263238"
          />
          <path
            d="M140.497 236.045C140.497 237.925 138.972 239.45 137.092 239.45C135.212 239.45 133.687 237.925 133.687 236.045C133.687 234.165 135.212 232.64 137.092 232.64C138.972 232.64 140.497 234.165 140.497 236.045Z"
            fill="#263238"
          />
          <path
            d="M145.57 210.427V185.376C147.018 185.376 148.191 186.55 148.191 187.998V207.805C148.191 209.253 147.018 210.427 145.57 210.427Z"
            fill="#263238"
          />
          <path
            d="M139.115 234.383H104.909C100.684 234.383 97.2578 230.957 97.2578 226.732V172.091C97.2578 167.866 100.684 164.44 104.909 164.44H139.115C143.341 164.44 146.767 167.866 146.767 172.091V226.732C146.767 230.957 143.341 234.383 139.115 234.383Z"
            fill="#455A64"
          />
          <path
            d="M146.72 173.198C146.72 173.276 135.633 173.34 121.959 173.34C108.281 173.34 97.196 173.276 97.196 173.198C97.196 173.119 108.281 173.055 121.959 173.055C135.633 173.055 146.72 173.119 146.72 173.198Z"
            fill="#263238"
          />
          <path
            d="M146.72 180.585C146.72 180.664 135.633 180.727 121.959 180.727C108.281 180.727 97.196 180.663 97.196 180.585C97.196 180.506 108.281 180.442 121.959 180.442C135.633 180.442 146.72 180.506 146.72 180.585Z"
            fill="#263238"
          />
          <path
            d="M146.72 187.971C146.72 188.05 135.633 188.114 121.959 188.114C108.281 188.114 97.196 188.05 97.196 187.971C97.196 187.893 108.281 187.829 121.959 187.829C135.633 187.829 146.72 187.893 146.72 187.971Z"
            fill="#263238"
          />
          <path
            d="M146.72 195.358C146.72 195.437 135.633 195.501 121.959 195.501C108.281 195.501 97.196 195.436 97.196 195.358C97.196 195.279 108.281 195.216 121.959 195.216C135.633 195.215 146.72 195.279 146.72 195.358Z"
            fill="#263238"
          />
          <path
            d="M146.72 202.745C146.72 202.824 135.633 202.887 121.959 202.887C108.281 202.887 97.196 202.823 97.196 202.745C97.196 202.666 108.281 202.603 121.959 202.603C135.633 202.603 146.72 202.666 146.72 202.745Z"
            fill="#263238"
          />
          <path
            d="M146.72 210.131C146.72 210.209 135.633 210.273 121.959 210.273C108.281 210.273 97.196 210.209 97.196 210.131C97.196 210.052 108.281 209.988 121.959 209.988C135.633 209.988 146.72 210.052 146.72 210.131Z"
            fill="#263238"
          />
          <path
            d="M146.72 217.517C146.72 217.596 135.633 217.66 121.959 217.66C108.281 217.66 97.196 217.596 97.196 217.517C97.196 217.439 108.281 217.375 121.959 217.375C135.633 217.375 146.72 217.439 146.72 217.517Z"
            fill="#263238"
          />
          <path
            d="M146.72 224.904C146.72 224.983 135.633 225.046 121.959 225.046C108.281 225.046 97.196 224.982 97.196 224.904C97.196 224.826 108.281 224.762 121.959 224.762C135.633 224.761 146.72 224.825 146.72 224.904Z"
            fill="#263238"
          />
          <path
            d="M127.549 183.819H116.48V185.822H127.549V183.819Z"
            fill="white"
          />
          <path
            d="M60.3316 34.3443C60.3316 34.3443 59.4018 35.6186 59.4182 37.9217C59.4269 39.1527 60.2653 41.6552 62.4535 41.7795C61.873 45.3055 64.1297 47.912 67.8878 47.9175C71.6459 47.9229 75.9472 41.4767 79.4282 40.0606L89.5357 37.3911C90.4233 37.1841 90.7891 36.1032 90.7727 35.1915C90.7563 34.2803 90.5219 33.3329 90.8433 32.4798C91.2847 31.3107 92.6394 30.6859 93.1016 29.525C93.6273 28.2047 92.813 26.7388 91.8744 25.6721C90.9359 24.6054 89.7914 23.5907 89.4935 22.2015C89.2197 20.9245 89.7104 19.4849 89.0724 18.3448C88.2877 16.9419 86.231 16.7661 85.276 15.4733C84.8138 14.8474 84.6698 14.0314 84.2131 13.4012C83.4344 12.3273 81.9351 12.0459 80.6171 12.1904C79.299 12.3345 78.0297 12.812 76.7073 12.9078C74.8926 13.0398 73.0176 12.4527 71.2763 12.9784C69.3542 13.5589 68.1829 15.2641 66.9366 16.9145L65.3108 18.8294C64.9746 19.1569 64.6181 19.4592 64.2266 19.7138C63.5415 20.1595 62.7574 20.4678 62.1693 21.0351C60.9104 22.2486 61.0232 24.577 62.3932 25.6634C60.9969 26.1129 59.9981 27.575 60.0868 29.0393C60.0868 29.0393 59.9066 30.7138 60.9695 31.4591C60.9695 31.4591 59.4472 32.6068 60.3316 34.3443Z"
            fill="#263238"
          />
          <path
            d="M67.1133 37.7654C66.2799 35.2016 65.4569 32.6186 65.0774 29.9496C64.6979 27.2807 64.7784 24.4983 65.7181 21.9712C65.8604 21.5879 67.0744 19.0181 67.4808 19.0586C67.7715 19.0871 67.0235 21.4844 67.1708 21.7368C68.5737 24.1457 68.9549 27.043 68.7309 29.8215C68.5069 32.6005 67.9287 35.1003 67.1133 37.7654Z"
            fill="#263238"
          />
          <path
            d="M73.7584 61.2816C67.7918 60.5297 67.1062 50.9474 67.1062 50.9474L66.8341 27.4804C66.6145 20.7231 70.3644 14.9625 77.1255 14.9302L77.7503 14.9636C84.0399 15.3009 89.0181 20.4094 89.1878 26.7056C89.3455 32.5637 89.4447 39.0384 89.2382 42.1657C88.8105 48.6393 82.2772 49.2997 82.2772 49.2997C82.263 49.2997 82.119 50.8056 82.2208 52.7068C82.5905 59.59 75.4324 61.4278 73.7584 61.2816Z"
            fill="#FFBE9D"
          />
          <path
            d="M82.2831 49.301C82.2831 49.301 78.1871 49.3218 73.9822 46.355C73.9822 46.355 75.7859 51.1765 82.2815 50.94L82.2831 49.301Z"
            fill="#EB996E"
          />
          <path
            d="M87.476 32.0188C87.4672 32.5084 87.0456 32.9043 86.5341 32.9048C86.0238 32.9048 85.6164 32.5089 85.6251 32.0194C85.6339 31.5298 86.0555 31.1334 86.5659 31.1328C87.0774 31.1328 87.4848 31.5293 87.476 32.0188Z"
            fill="#263238"
          />
          <path
            d="M87.6661 29.3203C87.5451 29.4347 86.8721 28.8784 85.8761 28.8275C84.8822 28.7623 84.1243 29.2354 84.023 29.1078C83.9726 29.0509 84.1013 28.8395 84.4315 28.6249C84.7568 28.4113 85.3033 28.2153 85.9232 28.2514C86.543 28.2881 87.0523 28.5444 87.3398 28.7941C87.6338 29.0454 87.7258 29.2694 87.6661 29.3203Z"
            fill="#263238"
          />
          <path
            d="M78.135 32.0169C78.1262 32.5064 77.704 32.9023 77.1926 32.9023C76.6822 32.9023 76.2748 32.507 76.2836 32.0169C76.2923 31.5279 76.7145 31.1309 77.2249 31.1309C77.7363 31.1309 78.1438 31.5279 78.135 32.0169Z"
            fill="#263238"
          />
          <path
            d="M77.8775 29.2005C77.7559 29.3149 77.0824 28.7586 76.0869 28.7082C75.0936 28.643 74.3351 29.1162 74.2338 28.9886C74.184 28.9316 74.3111 28.7203 74.6429 28.5061C74.9682 28.292 75.5147 28.0966 76.1345 28.1321C76.7544 28.1688 77.2637 28.4251 77.5512 28.6748C77.8441 28.9256 77.9367 29.1496 77.8775 29.2005Z"
            fill="#263238"
          />
          <path
            d="M81.0587 37.0406C81.0571 36.9836 81.6819 36.9097 82.6911 36.8352C82.9468 36.8227 83.19 36.7849 83.242 36.614C83.3115 36.4339 83.2201 36.154 83.1127 35.8512C82.9068 35.2237 82.6911 34.5649 82.4649 33.8755C81.5685 31.0636 80.9344 28.7566 81.0478 28.7216C81.1617 28.6865 81.9798 30.9371 82.8762 33.7496C83.0908 34.4428 83.2967 35.1032 83.4917 35.7357C83.5722 36.0297 83.72 36.366 83.57 36.7405C83.4911 36.9267 83.2973 37.0548 83.1319 37.0899C82.9665 37.1309 82.8231 37.126 82.6971 37.1266C81.6852 37.1266 81.0593 37.0975 81.0587 37.0406Z"
            fill="#263238"
          />
          <path
            d="M78.0752 37.576C78.2379 37.5754 78.182 38.6558 79.0658 39.4723C79.948 40.2915 81.1062 40.2318 81.1067 40.3829C81.1182 40.4514 80.8389 40.579 80.3521 40.5664C79.8746 40.5571 79.1863 40.3665 78.6453 39.8671C78.1064 39.3666 77.8973 38.7259 77.8841 38.2807C77.8666 37.8268 78.0013 37.5656 78.0752 37.576Z"
            fill="#263238"
          />
          <path
            d="M78.0428 25.1587C77.93 25.4243 76.9432 25.2425 75.7675 25.3208C74.5902 25.3756 73.6303 25.6669 73.4885 25.4161C73.4255 25.2962 73.6035 25.0503 73.9983 24.8088C74.3898 24.5673 75.0053 24.351 75.7106 24.3105C76.4159 24.2716 77.0516 24.4173 77.4678 24.6128C77.8878 24.8083 78.0921 25.0328 78.0428 25.1587Z"
            fill="#263238"
          />
          <path
            d="M87.4291 25.9905C87.2419 26.2073 86.5689 25.9357 85.7448 25.8804C84.9228 25.7961 84.2137 25.9511 84.0659 25.7057C84.0018 25.5875 84.1233 25.3668 84.4404 25.1691C84.7525 24.972 85.2651 24.8247 85.8302 24.8723C86.3958 24.9205 86.8761 25.1521 87.1504 25.3991C87.4292 25.6466 87.5124 25.8848 87.4291 25.9905Z"
            fill="#263238"
          />
          <path
            d="M67.1006 32.3061C66.9928 32.2525 62.7199 30.751 62.6241 35.1876C62.5282 39.6242 67.0021 38.8055 67.0125 38.6785C67.0223 38.5509 67.1006 32.3061 67.1006 32.3061Z"
            fill="#FFBE9D"
          />
          <path
            d="M65.6933 36.9679C65.6742 36.9531 65.615 37.0204 65.483 37.0774C65.3544 37.1333 65.1348 37.1683 64.9053 37.0769C64.4404 36.8945 64.0621 36.1421 64.0577 35.3421C64.0549 34.938 64.148 34.5552 64.2981 34.2403C64.4404 33.9189 64.6551 33.6966 64.8922 33.6593C65.1282 33.6101 65.2919 33.7552 65.344 33.8811C65.4009 34.0065 65.3708 34.0941 65.3943 34.1023C65.4064 34.1138 65.4913 34.0301 65.4595 33.8461C65.4431 33.7574 65.3932 33.6517 65.2908 33.5641C65.1863 33.4753 65.0307 33.4277 64.8659 33.4419C64.5231 33.4578 64.2083 33.7661 64.0434 34.1122C63.86 34.4594 63.7461 34.8892 63.7488 35.3432C63.7581 36.2379 64.1973 37.0845 64.8347 37.2833C65.1452 37.3715 65.4075 37.2827 65.5444 37.1809C65.6829 37.0747 65.7087 36.9761 65.6933 36.9679Z"
            fill="#EB996E"
          />
          <path
            d="M86.4107 19.3194C87.1341 18.9322 85.0083 16.5792 83.2544 15.7557C80.603 14.5115 76.5393 13.7142 74.0713 14.3801C71.0995 15.1818 68.6211 16.623 67.0999 19.2991C65.5787 21.9746 64.9796 25.1074 65.0267 28.1849C65.041 29.124 65.1258 30.1036 65.617 30.9037C66.2243 31.8926 67.4055 32.5256 67.3359 33.8782C67.3107 34.3726 67.4454 34.8737 67.7795 35.2384C68.58 36.1123 69.5838 35.314 70.0509 34.5057C71.0486 32.7803 71.1838 30.7065 71.295 28.7166C71.3744 27.3022 71.45 25.8571 71.0683 24.493C70.8608 23.7505 70.5207 23.0474 70.3548 22.2944C70.1889 21.5415 70.2223 20.69 70.6976 20.0833C70.7748 19.9847 70.9018 19.8604 71.0628 19.7235C72.3332 18.6382 74.097 18.331 75.6648 18.9087C78.1519 19.8248 82.4023 21.1998 83.5276 20.3658C85.1463 19.1666 85.54 18.3759 85.54 18.3759L86.4107 19.3194Z"
            fill="#263238"
          />
          <path
            d="M74.3199 13.7796C76.7682 12.9955 79.5439 13.0738 81.8104 14.2867C83.014 14.9307 84.0949 15.9492 84.4476 17.2678C84.8002 18.5864 84.2187 20.1957 82.9483 20.6941L82.8607 20.7477C80.9594 21.3605 79.7323 21.0911 77.853 20.4137C76.2721 19.8436 74.8719 18.8454 73.2882 18.283C71.6044 17.685 69.7404 17.6067 68.0122 18.0618C69.7338 16.1529 71.8711 14.5643 74.3199 13.7796Z"
            fill="#263238"
          />
          <path
            d="M65.8801 18.1672C65.9179 18.2012 66.2755 17.7768 66.9961 17.2763C67.3564 17.0304 67.806 16.7599 68.3421 16.5562C68.8749 16.3525 69.4997 16.2008 70.1464 16.2545C71.4644 16.3804 72.5629 16.9926 73.2397 17.5298C73.9259 18.0747 74.2446 18.5314 74.2862 18.4991C74.301 18.4887 74.2391 18.3633 74.0929 18.1579C73.9489 17.9509 73.7106 17.6722 73.3739 17.3694C72.7064 16.7709 71.5767 16.094 70.1699 15.9593C69.4586 15.9018 68.7944 16.0754 68.2402 16.3032C67.6817 16.5321 67.2272 16.8322 66.8712 17.1082C66.1594 17.6695 65.8549 18.1502 65.8801 18.1672Z"
            fill="#455A64"
          />
          <path
            d="M79.3954 13.3765C79.385 13.4252 79.856 13.5348 80.5706 13.8195C81.2852 14.1021 82.2347 14.5954 83.1404 15.3385C84.0428 16.0854 84.7093 16.9233 85.1227 17.5705C85.5389 18.2178 85.7365 18.6586 85.782 18.6394C85.8121 18.6285 85.6829 18.1553 85.3023 17.4626C84.9256 16.7716 84.2619 15.8812 83.3288 15.1096C82.3929 14.3414 81.3925 13.8606 80.6423 13.6229C79.8899 13.3809 79.4004 13.3447 79.3954 13.3765Z"
            fill="#455A64"
          />
          <path
            d="M63.605 216.202L52.8711 224.836C52.8711 224.836 58.7895 237.979 56.9616 239.768L38.7263 220.224L54.8989 206.085L63.605 216.202Z"
            fill="#D5191F"
          />
          <g opacity="0.6">
            <path
              d="M48.3191 217.83C47.874 217.531 47.2004 217.641 46.8834 218.074C46.5669 218.507 46.6731 219.193 47.1134 219.499C47.5531 219.806 48.3662 219.698 48.6324 219.233C48.8985 218.767 48.7298 217.968 48.2293 217.777"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              d="M38.7261 220.225L40.3563 218.8L57.3064 238.285C57.3064 238.285 57.6147 239.168 56.911 240L38.7261 220.225Z"
              fill="white"
            />
          </g>
          <path
            d="M53.2529 225.077C53.1516 225.16 52.6795 224.704 51.9419 224.494C51.2098 224.263 50.5658 224.383 50.5264 224.258C50.4667 224.151 51.1841 223.787 52.0728 224.058C52.9632 224.322 53.3619 225.02 53.2529 225.077Z"
            fill="#263238"
          />
          <path
            d="M54.2184 227.953C54.1422 228.059 53.6045 227.777 52.897 227.803C52.189 227.808 51.6644 228.114 51.5833 228.012C51.4875 227.933 52.0066 227.364 52.8866 227.349C53.7671 227.325 54.3103 227.87 54.2184 227.953Z"
            fill="#263238"
          />
          <path
            d="M52.7125 231.353C52.614 231.282 52.988 230.718 53.748 230.503C54.5064 230.283 55.1219 230.564 55.0759 230.677C55.0431 230.803 54.4939 230.754 53.8729 230.94C53.2481 231.111 52.8073 231.443 52.7125 231.353Z"
            fill="#263238"
          />
          <path
            d="M55.4757 222.838C55.3498 222.875 55.0946 222.304 54.5738 221.779C54.064 221.244 53.5011 220.972 53.5411 220.847C53.563 220.728 54.2951 220.836 54.8996 221.463C55.5086 222.085 55.5951 222.82 55.4757 222.838Z"
            fill="#263238"
          />
          <g opacity="0.3">
            <path
              d="M61.47 217.909L59.5589 219.45L50.3911 210.022L52.7151 207.98L61.47 217.909Z"
              fill="black"
            />
          </g>
          <path
            d="M57.601 221.055C57.561 221.091 57.1629 220.753 56.7303 220.038C56.5157 219.681 56.301 219.228 56.1395 218.697C56.059 218.432 55.9916 218.148 55.9451 217.849C55.9243 217.704 55.8892 217.541 55.9183 217.338C55.9265 217.139 56.1346 216.851 56.404 216.838C56.6488 216.808 56.8744 216.932 57.0036 217.076C57.1372 217.22 57.2117 217.367 57.2796 217.501C57.4198 217.773 57.5276 218.048 57.6081 218.317C57.7669 218.855 57.8157 219.361 57.8031 219.78C57.7746 220.625 57.5446 221.103 57.4959 221.086C57.4247 221.07 57.5364 220.574 57.4816 219.787C57.4543 219.395 57.3754 218.931 57.2089 218.444C57.1246 218.201 57.0178 217.953 56.8853 217.708C56.7561 217.445 56.6 217.262 56.4576 217.29C56.404 217.301 56.3876 217.311 56.3635 217.41C56.3459 217.506 56.364 217.642 56.3843 217.785C56.422 218.064 56.4768 218.332 56.5414 218.584C56.6717 219.088 56.8437 219.528 57.0129 219.887C57.353 220.606 57.6558 221.01 57.601 221.055Z"
            fill="#263238"
          />
          <path
            d="M57.3638 221.048C57.3183 221.026 57.5023 220.525 58.1107 219.935C58.4129 219.644 58.8258 219.345 59.3384 219.115C59.5952 219.001 59.8761 218.906 60.1762 218.841C60.4784 218.787 60.8705 218.7 61.2456 219.044C61.4263 219.237 61.4137 219.539 61.3294 219.711C61.2506 219.896 61.1449 220.011 61.0479 220.136C60.8442 220.377 60.6088 220.573 60.3667 220.727C59.8816 221.036 59.3712 221.169 58.9474 221.199C58.0816 221.257 57.6074 220.947 57.6337 220.911C57.6545 220.835 58.143 221.014 58.908 220.881C59.2852 220.815 59.7266 220.664 60.1362 220.378C60.3405 220.236 60.5365 220.061 60.7052 219.853C60.8924 219.636 60.9965 219.405 60.9045 219.344C60.8191 219.242 60.5097 219.212 60.2665 219.277C59.9955 219.33 59.7387 219.408 59.501 219.501C59.0262 219.69 58.6287 219.938 58.3215 220.179C57.7033 220.664 57.4251 221.089 57.3638 221.048Z"
            fill="#263238"
          />
          <path
            d="M62.9454 217.96L63.9863 231.696C63.9863 231.696 50.3497 236.366 50.2949 238.923L77.0245 238.863L76.2808 217.394L62.9454 217.96Z"
            fill="#D5191F"
          />
          <g opacity="0.6">
            <path
              d="M72.2033 230.213C72.7257 230.333 73.1057 230.901 73.0072 231.428C72.908 231.954 72.3347 232.346 71.8101 232.235C71.2855 232.124 70.8086 231.457 70.9657 230.944C71.1229 230.431 71.8216 230.008 72.3041 230.243"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              d="M77.027 238.86L76.952 236.696L51.1441 237.655C51.1441 237.655 50.2887 238.034 50.1633 239.116L77.027 238.86Z"
              fill="white"
            />
          </g>
          <path
            d="M63.5449 231.578C63.5543 231.708 64.2086 231.742 64.8668 232.135C65.536 232.512 65.8892 233.064 66.0069 233.007C66.1263 232.977 65.9007 232.205 65.0952 231.742C64.2941 231.272 63.5121 231.46 63.5449 231.578Z"
            fill="#263238"
          />
          <path
            d="M60.795 232.848C60.7698 232.976 61.3437 233.175 61.8086 233.709C62.2894 234.229 62.4246 234.821 62.5544 234.81C62.6776 234.826 62.7378 234.059 62.147 233.406C61.5622 232.748 60.7928 232.724 60.795 232.848Z"
            fill="#263238"
          />
          <path
            d="M59.3318 236.262C59.4512 236.285 59.6067 235.626 59.2437 234.925C58.8855 234.221 58.2591 233.964 58.2087 234.075C58.1392 234.185 58.5504 234.553 58.8395 235.132C59.1429 235.706 59.202 236.254 59.3318 236.262Z"
            fill="#263238"
          />
          <path
            d="M63.6667 228.419C63.7259 228.536 64.3173 228.332 65.0565 228.353C65.7958 228.359 66.3795 228.584 66.443 228.469C66.5147 228.372 65.9354 227.912 65.0647 227.899C64.1941 227.88 63.5983 228.32 63.6667 228.419Z"
            fill="#263238"
          />
          <g opacity="0.3">
            <path
              d="M63.5435 225.829L63.7258 228.277L76.6468 228.051L76.5559 225.433L63.5435 225.829Z"
              fill="black"
            />
          </g>
          <path
            d="M63.4939 225.646C63.4945 225.7 64.0136 225.76 64.8311 225.586C65.238 225.499 65.7155 225.346 66.2133 225.1C66.4619 224.978 66.7149 224.832 66.9651 224.662C67.085 224.578 67.228 224.493 67.3566 224.331C67.4957 224.19 67.5642 223.841 67.3889 223.635C67.2438 223.436 66.9985 223.357 66.8058 223.36C66.6086 223.362 66.4509 223.408 66.3069 223.449C66.0123 223.533 65.7374 223.643 65.4866 223.768C64.9856 224.02 64.5825 224.33 64.2857 224.627C63.6889 225.225 63.4967 225.721 63.5427 225.744C63.6034 225.785 63.8887 225.365 64.5004 224.866C64.8054 224.618 65.1975 224.358 65.6668 224.146C65.9017 224.042 66.1558 223.95 66.4252 223.879C66.705 223.794 66.9459 223.782 67.0232 223.905C67.0511 223.952 67.0555 223.971 66.9996 224.055C66.9416 224.134 66.8299 224.214 66.7116 224.297C66.4816 224.46 66.2494 224.604 66.0211 224.729C65.5644 224.979 65.1252 225.154 64.7479 225.276C63.9911 225.52 63.489 225.576 63.4939 225.646Z"
            fill="#263238"
          />
          <path
            d="M63.6819 225.826C63.729 225.845 63.9694 225.367 63.9831 224.52C63.9886 224.101 63.925 223.595 63.7427 223.064C63.6501 222.799 63.5275 222.529 63.3698 222.265C63.2028 222.008 62.998 221.662 62.4898 221.624C62.2253 221.625 62.0139 221.84 61.946 222.019C61.865 222.203 61.8535 222.359 61.8283 222.514C61.7916 222.828 61.8097 223.134 61.8634 223.415C61.9701 223.98 62.2215 224.444 62.4893 224.774C63.039 225.446 63.5888 225.58 63.5981 225.535C63.6392 225.468 63.1743 225.234 62.7488 224.585C62.5391 224.265 62.3469 223.84 62.2757 223.345C62.2395 223.099 62.233 222.836 62.2697 222.571C62.2998 222.286 62.3973 222.053 62.5046 222.077C62.6371 222.07 62.8715 222.275 62.9897 222.497C63.1365 222.731 63.2553 222.971 63.3501 223.209C63.5368 223.685 63.6277 224.144 63.6622 224.533C63.7301 225.315 63.6102 225.809 63.6819 225.826Z"
            fill="#263238"
          />
          <path
            d="M53.0015 119.067C53.0015 119.067 55.8993 160.2 57.0284 169.635C57.7458 175.631 61.2077 226.056 61.2077 226.056L78.6446 225.517L75.8349 145.661V121.163L53.0015 119.067Z"
            fill="#263238"
          />
          <path
            d="M50.6587 207.098L62.2008 218.8C62.2008 218.8 97.1574 184.737 98.5767 182.183C99.9961 179.629 99.5701 148.126 99.5701 148.126L98.5499 119.893L73.5923 121.164L76.0581 149.249L79.3809 175.088L50.6587 207.098Z"
            fill="#263238"
          />
          <path
            d="M82.3724 198.955C82.3724 198.955 82.2558 199.088 82.016 199.333C81.7668 199.583 81.4114 199.942 80.9514 200.406C80.0047 201.348 78.6297 202.717 76.8823 204.456C73.3558 207.944 68.3284 212.915 62.2895 218.887L62.203 218.972L62.1171 218.885C58.6097 215.337 54.7098 211.39 50.5629 207.194L50.4714 207.101L50.5585 207.004C53.5122 203.711 56.6483 200.214 59.8752 196.616C66.9769 188.712 73.6367 181.299 79.2922 175.005L79.2616 175.102C78.2195 166.287 77.3499 158.932 76.7394 153.768C76.4442 151.199 76.212 149.177 76.0516 147.784C75.9777 147.101 75.9202 146.574 75.8802 146.205C75.8638 146.033 75.8506 145.9 75.8408 145.8C75.8331 145.709 75.8309 145.662 75.8342 145.662C75.8375 145.661 75.8463 145.707 75.8605 145.797C75.8753 145.897 75.8944 146.03 75.9191 146.2C75.9678 146.567 76.0374 147.094 76.1277 147.774C76.3035 149.165 76.5592 151.185 76.8834 153.75C77.5186 158.911 78.4232 166.262 79.5074 175.072L79.514 175.127L79.4762 175.17C73.8289 181.471 67.179 188.892 60.0877 196.806C56.8585 200.402 53.7203 203.897 50.7649 207.189L50.7606 206.999C54.8993 211.204 58.791 215.158 62.2912 218.714L62.1192 218.712C68.1876 212.771 73.2397 207.825 76.7837 204.355C78.5514 202.636 79.9422 201.284 80.9 200.352C81.3714 199.899 81.7356 199.55 81.9902 199.305C82.2372 199.07 82.3724 198.955 82.3724 198.955Z"
            fill="#455A64"
          />
          <path
            d="M84.8532 177.547C84.8187 177.618 83.6798 177.129 82.3102 176.455C80.9402 175.781 79.8581 175.177 79.8932 175.106C79.9277 175.035 81.0661 175.524 82.4362 176.198C83.8062 176.873 84.8883 177.476 84.8532 177.547Z"
            fill="#455A64"
          />
          <path
            d="M77.9625 144.286C77.9636 144.31 77.583 144.346 76.91 144.389C76.1757 144.427 75.2256 144.478 74.1025 144.537L73.9815 144.543V144.419C73.9831 142.967 73.9755 141.192 73.9366 139.278C73.8884 136.792 73.7942 134.543 73.7219 132.914C73.6885 132.144 73.6606 131.498 73.6381 130.985C73.6212 130.53 73.6212 130.277 73.6409 130.275C73.6606 130.273 73.6978 130.524 73.7488 130.977C73.7991 131.43 73.8599 132.088 73.9229 132.902C74.0466 134.529 74.1726 136.781 74.2213 139.272C74.2602 141.189 74.2487 142.968 74.2153 144.421L74.0943 144.303C75.219 144.284 76.1697 144.268 76.9051 144.255C77.5797 144.252 77.9619 144.261 77.9625 144.286Z"
            fill="#455A64"
          />
          <path
            d="M76.7492 131.223C76.7475 131.209 76.8444 131.171 77.0262 131.191C77.2064 131.209 77.4681 131.306 77.7085 131.536C77.9391 131.763 78.1619 132.14 78.1209 132.605C78.0891 133.076 77.6921 133.509 77.1998 133.657C76.7103 133.817 76.1375 133.688 75.8423 133.319C75.5434 132.96 75.5116 132.524 75.5707 132.206C75.637 131.88 75.7963 131.651 75.9343 131.533C76.0723 131.413 76.1736 131.389 76.1802 131.401C76.2185 131.436 75.838 131.647 75.7701 132.236C75.741 132.516 75.7974 132.879 76.0395 133.145C76.2722 133.417 76.7103 133.512 77.1144 133.386C77.5185 133.259 77.8235 132.931 77.8597 132.575C77.9062 132.219 77.7452 131.888 77.5623 131.675C77.1708 131.229 76.7377 131.273 76.7492 131.223Z"
            fill="#455A64"
          />
          <path
            d="M66.7309 51.4399C66.7309 51.4399 71.2666 56.3967 83.1395 52.4431L90.6453 63.4223L98.3587 125.93L73.0682 130.009L53.3713 126.884L59.6495 63.4218L66.7309 51.4399Z"
            fill="#E0E0E0"
          />
          <path
            d="M67.805 52.0925C68.7704 54.2062 71.0999 59.1811 71.0999 59.1811C71.0999 59.1811 65.2292 98.1466 64.6104 104.309C63.9292 111.093 68.1292 135.121 68.1292 135.121L47.5376 131.9L53.3289 68.3658L50.5181 56.108C50.5181 56.108 55.5313 54.0376 60.5171 52.99L64.8398 52.3751L66.0297 50.4032L66.8254 49.2095L67.805 52.0925Z"
            fill="#D5191F"
          />
          <g opacity="0.3">
            <path
              d="M68.8797 99.6158C66.3618 99.5621 59.0269 101.676 56.5233 101.945C53.9343 102.224 52.1995 102.559 50.0842 104.077L49.7693 107.399C51.2325 108.119 52.8829 108.293 54.4906 108.567C56.0983 108.84 57.8714 108.599 59.1205 107.55C59.8039 106.976 60.3055 106.187 61.0683 105.725C61.7703 105.299 62.5265 104.61 63.332 104.767C69.4004 105.948 70.9424 100.712 68.8797 99.6158Z"
              fill="black"
            />
          </g>
          <path
            d="M65.3303 52.795C65.3495 52.7906 65.4169 52.9938 65.5215 53.365C65.626 53.7363 65.769 54.2757 65.9393 54.9432C66.2799 56.2788 66.7311 58.128 67.2124 60.1743C67.386 60.9229 67.5536 61.6446 67.7096 62.3154H67.444C67.6319 61.5395 67.9177 60.8479 68.2342 60.2647C68.5611 59.6924 68.8634 59.1761 69.2845 58.8705C69.4915 58.7238 69.7319 58.6487 69.9361 58.6701C70.1387 58.6871 70.3025 58.7621 70.4093 58.853C70.6294 59.0435 70.6053 59.2286 70.5982 59.2204C70.5757 59.2303 70.5615 59.0665 70.3545 58.9264C70.1574 58.7895 69.7445 58.7106 69.3858 59.009C69.031 59.2889 68.7424 59.8074 68.4407 60.3736C68.1472 60.9492 67.8827 61.6249 67.7091 62.3784L67.5782 62.9451L67.443 62.3778C67.2836 61.7076 67.1117 60.9869 66.9337 60.2395C66.4524 58.1931 66.0324 56.3363 65.7421 54.9892C65.597 54.3156 65.4842 53.7692 65.4125 53.3902C65.3418 53.0113 65.3117 52.7994 65.3303 52.795Z"
            fill="#263238"
          />
          <path
            d="M52.7939 105.356L58.0108 106.877C58.0108 106.877 59.6984 110.826 60.0056 115.372C60.3128 119.919 58.318 152.864 58.318 152.864L41.5929 152.142L41.0853 150.619C40.8263 149.843 40.7239 149.023 40.7868 148.207C41.2052 142.795 41.9521 124.004 46.0152 113.07C47.9515 107.858 52.7939 105.356 52.7939 105.356Z"
            fill="#455A64"
          />
          <path
            d="M58.6402 53.0931C58.6402 53.0931 60.2249 56.0879 60.0393 62.6858C59.8537 69.2838 50.7029 115.19 50.7029 115.19L56.532 115.7C56.532 115.7 62.4619 77.5694 62.769 73.7335C63.0757 69.8976 65.2239 55.9094 62.769 52.8533C62.769 52.8533 60.3421 51.7866 58.6402 53.0931Z"
            fill="#455A64"
          />
          <path
            d="M48.5863 108.811C48.5863 108.811 42.4522 116.454 40.4097 130.869L43.1186 131.453L48.5863 108.811Z"
            fill="#455A64"
          />
          <path
            d="M52.1422 112.112C52.1521 112.114 52.1433 112.211 52.1176 112.393C52.0836 112.606 52.0426 112.869 51.9922 113.186C51.8865 113.876 51.7239 114.871 51.5552 116.106C51.1976 118.572 50.8664 122.008 50.16 125.759C49.9771 126.696 49.7936 127.605 49.5757 128.485C49.3468 129.358 49.0445 130.208 48.5128 130.885C47.997 131.573 47.1685 131.97 46.4062 131.961C45.6456 131.97 44.9781 131.814 44.3604 131.72C43.1234 131.507 42.1553 131.217 41.4708 131.095C41.1554 131.033 40.8953 130.981 40.6834 130.939C40.5032 130.902 40.4096 130.878 40.4107 130.868C40.4123 130.859 40.5087 130.863 40.6921 130.882C40.8756 130.9 41.1444 130.934 41.4905 130.986C42.1865 131.075 43.1667 131.336 44.3938 131.521C45.0082 131.602 45.6883 131.745 46.3986 131.725C47.1104 131.717 47.8283 131.362 48.3069 130.723C48.7954 130.092 49.0867 129.272 49.3063 128.415C49.516 127.551 49.6989 126.636 49.8807 125.705C50.5838 121.972 50.9474 118.54 51.357 116.076C51.5519 114.842 51.7447 113.85 51.8843 113.165C51.9555 112.851 52.0141 112.592 52.0623 112.381C52.105 112.203 52.1329 112.11 52.1422 112.112Z"
            fill="#263238"
          />
          <path
            d="M58.1284 107.183C58.1481 107.186 58.1301 107.507 58.0808 108.085C58.0206 108.712 57.9412 109.537 57.8459 110.533C57.7917 111.054 57.7325 111.619 57.6696 112.222C57.5973 112.826 57.5622 113.481 57.3958 114.167C57.2408 114.849 56.8558 115.562 56.2157 116.022C55.5953 116.49 54.7312 116.727 53.912 116.518C53.0999 116.323 52.405 115.75 52.0973 115.015C51.7901 114.276 51.8383 113.507 51.9001 112.826C52.0387 111.455 52.2303 110.23 52.4231 109.208C52.6164 108.186 52.8119 107.367 52.9586 106.805C53.107 106.242 53.2061 105.936 53.2242 105.941C53.2445 105.946 53.1815 106.261 53.067 106.83C52.9537 107.399 52.7889 108.221 52.6219 109.244C52.4554 110.265 52.2851 111.488 52.1619 112.85C52.106 113.522 52.0754 114.25 52.3546 114.905C52.6273 115.551 53.2516 116.066 53.9804 116.242C54.7093 116.428 55.4819 116.221 56.0509 115.798C56.6357 115.379 56.9851 114.751 57.1422 114.102C57.3076 113.454 57.3536 112.8 57.4357 112.195C57.5107 111.593 57.5814 111.03 57.6466 110.51C57.7791 109.518 57.8886 108.697 57.9718 108.073C58.054 107.498 58.1093 107.181 58.1284 107.183Z"
            fill="#263238"
          />
          <path
            d="M58.36 152.903C58.3441 152.918 58.13 152.7 57.745 152.305C57.3606 151.91 56.8048 151.338 56.1006 150.65C55.3953 149.962 54.5405 149.16 53.5587 148.306C53.068 147.879 52.5451 147.438 51.9959 146.994C51.451 146.56 50.8481 146.125 50.1521 145.961C49.806 145.881 49.4523 145.896 49.1309 146.007C48.8083 146.118 48.5192 146.319 48.2503 146.537C47.7148 146.998 47.1979 147.443 46.7034 147.869C45.7216 148.708 44.8317 149.469 44.0689 150.121C43.3423 150.729 42.7388 151.234 42.2767 151.621C41.849 151.97 41.607 152.157 41.5938 152.142C41.5807 152.127 41.7986 151.913 42.2033 151.538C42.6501 151.133 43.2333 150.606 43.9353 149.97C44.6861 149.305 45.5628 148.528 46.5293 147.672C47.0226 147.24 47.539 146.788 48.0735 146.32C48.3522 146.091 48.6649 145.869 49.0372 145.738C49.4096 145.608 49.8258 145.592 50.214 145.682C50.9943 145.869 51.6164 146.332 52.1711 146.776C52.7225 147.228 53.2433 147.674 53.7317 148.108C54.7092 148.977 55.553 149.799 56.243 150.507C56.9318 151.216 57.4668 151.81 57.8266 152.229C58.1875 152.649 58.3737 152.891 58.36 152.903Z"
            fill="#263238"
          />
          <path
            d="M53.6455 116.774C53.7238 116.785 52.886 123.26 51.7744 131.235C50.6628 139.212 49.6985 145.668 49.6207 145.658C49.543 145.647 50.3802 139.173 51.4918 131.196C52.6035 123.221 53.5678 116.763 53.6455 116.774Z"
            fill="#263238"
          />
          <path
            d="M56.161 113.63C56.1369 113.631 56.1304 113.495 56.0597 113.273C55.9896 113.055 55.8314 112.74 55.5001 112.504C55.1803 112.266 54.6639 112.139 54.1705 112.328C53.681 112.502 53.2621 113.023 53.2675 113.63C53.2621 114.237 53.681 114.759 54.1705 114.933C54.6639 115.122 55.1803 114.995 55.5001 114.757C55.8308 114.521 55.9896 114.206 56.0592 113.988C56.1304 113.765 56.1364 113.63 56.161 113.63C56.1758 113.629 56.2087 113.766 56.1676 114.014C56.1265 114.257 55.9836 114.624 55.6227 114.917C55.2745 115.209 54.6749 115.389 54.0813 115.179C53.4926 114.988 52.9751 114.363 52.9823 113.629C52.9751 112.895 53.4926 112.271 54.0813 112.08C54.6749 111.87 55.2745 112.049 55.6227 112.342C55.9836 112.635 56.1265 113.002 56.1676 113.245C56.2087 113.494 56.1753 113.631 56.161 113.63Z"
            fill="#263238"
          />
          <path
            d="M128.199 115.147L136.692 121.676L135.893 128.353L135.723 129.372L132.872 131.139H127.913C127.913 131.139 127.115 129.086 128.939 129.258C128.939 129.258 128.041 127.887 130.577 127.547C133.113 127.207 133.614 126.35 133.614 126.35L133.386 125.153L132.474 124.013L131.611 123.143L126.83 117.799L128.199 115.147Z"
            fill="#FFBE9D"
          />
          <path
            d="M112.395 107.335C112.387 107.394 122.236 117.524 122.236 117.524L128.2 115.148L121.037 100.415L115.064 98.2896L112.395 103.048V107.335Z"
            fill="#FFBE9D"
          />
          <path
            d="M121.954 117.088C121.954 117.088 123.581 119.68 124.892 120.934C126.203 122.188 128.426 123.328 128.426 123.328C128.426 123.328 130.422 128.573 131.733 128.345C133.044 128.117 133.5 127.433 132.759 126.008C132.018 124.582 130.307 119.338 130.307 119.338L127.154 113.069L121.954 117.088Z"
            fill="#FFBE9D"
          />
          <path
            d="M96.8639 56.4541C96.8639 56.4541 100.497 57.5476 102.59 62.4322C104.683 67.3161 113.054 84.816 113.054 84.816L121.952 100.808C121.952 100.808 117.94 100.808 114.975 103.075C112.01 105.343 112.358 108.309 112.358 108.309C112.358 108.309 109.772 107.785 109.408 106.215C109.044 104.645 109.408 103.25 109.408 103.25L106.253 99.2379L104.334 95.4004L98.2974 85.2453L94.2168 69.0607L96.8639 56.4541Z"
            fill="#D5191F"
          />
          <path
            d="M101.884 137.51C101.884 137.51 98.6525 104.072 98.8031 103.672C98.9537 103.273 98.4526 84.1031 98.8031 83.8035C99.1535 83.504 96.863 56.4541 96.863 56.4541L84.9468 52.771L85.1029 52.7951L82.3726 49.9082C82.3726 49.9082 79.04 60.9613 79.0854 61.1404C80.0486 64.9461 83.5028 121.54 83.5028 121.54C83.5028 121.943 84.8641 137.51 84.8641 137.51H101.884Z"
            fill="#D5191F"
          />
          <path
            d="M98.2755 85.5518C98.2826 85.5622 98.143 85.6701 97.8708 85.8666C97.568 86.0797 97.1688 86.3606 96.6699 86.7116L96.6382 86.734L96.6015 86.716C95.1471 86.0117 92.9403 84.9429 90.2735 83.6516L90.2078 83.6199L90.2023 83.5459C90.0479 81.4908 89.8727 79.1614 89.6871 76.7011C89.5228 74.3908 89.3662 72.1982 89.226 70.2285L89.2178 70.1152L89.3278 70.0916C92.0757 69.5085 94.4818 69.7916 96.0944 70.1393C96.9065 70.3161 97.5335 70.5155 97.9551 70.6688C98.3768 70.8232 98.5931 70.9322 98.5871 70.9453C98.5816 70.9585 98.356 70.8758 97.9289 70.7477C97.5017 70.6201 96.8726 70.4459 96.0632 70.2926C94.4555 69.9909 92.0778 69.7505 89.3842 70.3452L89.4861 70.2088C89.6339 72.1774 89.7988 74.37 89.9718 76.6792C90.147 79.14 90.3124 81.47 90.4586 83.5262L90.3874 83.4205C93.0317 84.7572 95.2199 85.8633 96.6623 86.5927L96.5938 86.5971C97.1075 86.2686 97.5187 86.0057 97.8308 85.8064C98.1156 85.6295 98.2684 85.5414 98.2755 85.5518Z"
            fill="#263238"
          />
          <path
            d="M98.4673 66.0571C98.487 66.056 98.5166 66.3452 98.5533 66.8681C98.5922 67.3911 98.6283 68.1489 98.6612 69.0859C98.7274 70.9597 98.7466 73.5504 98.7296 76.4088C98.7126 79.2683 98.6995 81.8551 98.7143 83.7273C98.7209 84.6232 98.7269 85.3685 98.7313 85.9451C98.734 86.4691 98.7263 86.7593 98.7066 86.7599C98.6869 86.761 98.6573 86.4719 98.6206 85.9489C98.5818 85.426 98.5456 84.6681 98.5128 83.7312C98.4465 81.8573 98.4273 79.2667 98.4443 76.4071C98.4613 73.5487 98.4744 70.9619 98.4597 69.0897C98.4525 68.1938 98.4471 67.448 98.4427 66.8719C98.4399 66.3479 98.4476 66.0577 98.4673 66.0571Z"
            fill="#263238"
          />
          <path
            d="M117.056 95.8651C117.07 95.9155 116.457 96.0715 115.541 96.4943C114.626 96.9126 113.428 97.6486 112.338 98.7317C111.253 99.8198 110.514 101.017 110.094 101.931C109.67 102.846 109.513 103.458 109.462 103.444C109.443 103.439 109.469 103.284 109.533 103.004C109.591 102.723 109.718 102.326 109.91 101.85C110.29 100.897 111.022 99.6468 112.137 98.5297C113.256 97.4175 114.508 96.6881 115.461 96.3097C115.938 96.1181 116.335 95.9927 116.616 95.9346C116.895 95.8717 117.051 95.8459 117.056 95.8651Z"
            fill="#263238"
          />
          <path
            d="M114.327 95.2095C114.364 95.2927 112.856 95.8776 111.327 97.1102C109.786 98.3286 108.883 99.6696 108.811 99.6154C108.782 99.5963 108.969 99.2359 109.362 98.7124C109.753 98.1889 110.367 97.5138 111.149 96.8873C111.933 96.2631 112.727 95.814 113.324 95.5479C113.922 95.2801 114.314 95.1766 114.327 95.2095Z"
            fill="#263238"
          />
          <path
            d="M85.1044 52.7949C85.1241 52.7971 85.1104 53.0616 85.0683 53.538C85.0261 54.0139 84.9555 54.7017 84.8591 55.5504C84.6642 57.2474 84.3712 59.5894 83.9682 62.1659C83.8225 63.0946 83.6719 63.9904 83.5213 64.8157L83.4491 65.2121L83.2656 64.8551C82.821 63.9893 82.3572 63.2096 81.9136 62.5289C81.6918 62.1883 81.4744 61.8734 81.2675 61.5838C81.0616 61.2935 80.8672 61.0334 80.6443 60.8544C80.4247 60.6753 80.1772 60.5773 79.9636 60.6019C79.7468 60.6238 79.5727 60.7257 79.4659 60.833C79.2468 61.0636 79.3213 61.2727 79.2939 61.2678C79.2939 61.2859 79.1696 61.0625 79.3969 60.7717C79.508 60.6386 79.6942 60.5099 79.9467 60.4689C80.204 60.4251 80.5003 60.5308 80.7494 60.7186C81.0035 60.9075 81.2171 61.1769 81.4323 61.4617C81.6491 61.7475 81.8769 62.0596 82.1075 62.3991C82.5702 63.0765 83.0504 63.8585 83.5076 64.7297L83.2519 64.7686C83.3877 63.9401 83.5345 63.0475 83.6862 62.1226C84.0886 59.5506 84.4128 57.2151 84.6587 55.5252C84.7786 54.7236 84.8788 54.0528 84.9577 53.5249C85.0338 53.0523 85.0847 52.7922 85.1044 52.7949Z"
            fill="#263238"
          />
          <g opacity="0.3">
            <path
              d="M83.2986 64.6196C83.1069 65.4218 82.9153 66.2246 82.7236 67.0273C82.1519 65.4273 81.5802 63.8278 81.0085 62.2277C80.8957 61.9123 80.7802 61.5914 80.5776 61.3247C80.375 61.0581 80.0678 60.8494 79.7327 60.8483C79.7327 60.8483 80.2638 60.1009 80.9576 60.8483C81.6509 61.5952 83.2986 64.6196 83.2986 64.6196Z"
              fill="black"
            />
          </g>
          <g opacity="0.3">
            <path
              d="M98.7183 85.7349C100.21 80.3805 99.9011 73.6808 98.468 68.3105L98.7183 85.7349Z"
              fill="black"
            />
          </g>
          <path
            d="M98.4961 73.3501C98.4868 73.3917 97.9917 73.3112 97.1923 73.1989C96.3928 73.0867 95.2861 72.9547 94.0579 72.8857C92.8296 72.8184 91.7147 72.826 90.9076 72.849C90.1004 72.8715 89.5994 72.8961 89.595 72.854C89.5912 72.8178 90.0856 72.722 90.8961 72.6475C91.706 72.5731 92.8324 72.5325 94.0737 72.601C95.3146 72.6711 96.4295 72.8359 97.2262 73.0002C98.023 73.1639 98.5043 73.3145 98.4961 73.3501Z"
            fill="#263238"
          />
          <path
            d="M99.0181 112.464C99.0082 112.481 98.8165 112.392 98.4776 112.216C98.1381 112.041 97.6556 111.773 97.0675 111.426C95.8908 110.734 94.3017 109.714 92.6266 108.473C90.952 107.231 89.513 106.007 88.5092 105.082C88.0071 104.62 87.609 104.237 87.3434 103.963C87.0767 103.69 86.9355 103.532 86.9492 103.518C86.9628 103.504 87.1293 103.634 87.419 103.882C87.7552 104.17 88.1637 104.52 88.6429 104.931C89.6778 105.815 91.13 107.008 92.7963 108.245C94.4637 109.479 96.0271 110.522 97.1732 111.255C97.706 111.594 98.1594 111.883 98.5334 112.121C98.8549 112.325 99.0279 112.447 99.0181 112.464Z"
            fill="#263238"
          />
          <path
            d="M50.9894 55.9395C50.9894 55.9395 48.4278 56.4065 45.9954 61.5156C43.5625 66.6246 37.3101 83.5966 36.9043 84.7318L49.6834 93.2282L56.899 71.8415L50.9894 55.9395Z"
            fill="#D5191F"
          />
          <path
            d="M57.6594 102.024L57.7678 94.7713L49.5145 91.9769L51.0708 86.8153L38.3573 80.834C38.3573 80.834 32.6657 95.7203 32.7018 98.4511C32.7155 99.4619 34.9809 104.598 39.7143 105.057C42.7972 105.356 49.7708 105.763 54.4609 105.934C56.5872 106.012 58.2119 104.079 57.6594 102.024Z"
            fill="#D5191F"
          />
          <path
            d="M55.3242 75.4025C55.3992 75.426 54.2832 79.1978 52.8321 83.8266C51.3799 88.4565 50.1429 92.1894 50.0673 92.1659C49.9923 92.1423 51.1077 88.371 52.5599 83.7412C54.0116 79.1129 55.2492 75.3789 55.3242 75.4025Z"
            fill="#263238"
          />
          <path
            d="M53.5339 93.3334C53.5279 93.3521 53.3368 93.3077 52.9968 93.2108C52.6567 93.1144 52.1672 92.9654 51.5637 92.776C50.9542 92.6002 50.2654 92.3029 49.4648 92.0926C48.6686 91.8686 47.7744 91.6885 46.8254 91.6052C44.9214 91.4212 43.1938 91.7383 42.0121 92.1337C40.8293 92.5433 40.1645 92.9644 40.1377 92.9167C40.1273 92.8997 40.2877 92.788 40.5889 92.5986C40.8923 92.414 41.3517 92.1791 41.9442 91.9436C43.1308 91.4908 44.9045 91.1321 46.8506 91.321C48.8017 91.4722 50.4658 92.1375 51.6327 92.5865C52.2258 92.8001 52.7043 92.9775 53.0329 93.1062C53.3609 93.2343 53.54 93.3148 53.5339 93.3334Z"
            fill="#263238"
          />
          <path
            d="M45.3821 87.4634C45.3914 87.4322 45.7775 87.5039 46.3557 87.7607C46.9329 88.0148 47.6886 88.4819 48.3742 89.1675C49.0576 89.8558 49.5225 90.6131 49.7744 91.1908C50.029 91.7696 50.0996 92.1562 50.0684 92.1655C49.9846 92.205 49.5142 90.6947 48.1726 89.369C46.8513 88.0225 45.3422 87.5472 45.3821 87.4634Z"
            fill="#263238"
          />
          <path
            d="M55.2057 68.2935C55.1537 68.2869 55.2517 67.6046 55.2205 66.5051C55.1931 65.4072 54.9817 63.8881 54.3952 62.3165C53.8022 60.7472 52.9611 59.4641 52.2585 58.6198C51.5576 57.7715 51.0352 57.3225 51.0697 57.2836C51.0823 57.2689 51.2214 57.3702 51.4629 57.5678C51.7082 57.7611 52.0357 58.0749 52.4113 58.4889C52.7903 58.9007 53.1949 59.4335 53.5936 60.0583C53.9813 60.6897 54.3558 61.4169 54.6614 62.2169C54.9582 63.0202 55.1542 63.8142 55.2785 64.5441C55.3897 65.2768 55.4362 65.9443 55.4214 66.5034C55.4116 67.0625 55.3716 67.5137 55.3141 67.8209C55.2626 68.1287 55.2248 68.2962 55.2057 68.2935Z"
            fill="#263238"
          />
          <g opacity="0.3">
            <path
              d="M44.6697 90.2833C42.7685 90.3425 40.8935 91.0554 39.4336 92.2749C39.3066 92.3806 39.1735 92.5093 39.1659 92.6741C39.1555 92.8964 39.392 93.0651 39.6143 93.0695C39.8367 93.0733 40.0426 92.9627 40.2402 92.8603C43.1687 91.3413 46.716 91.0631 49.8455 92.1079L50.0197 91.9458C48.487 90.8194 46.5709 90.2242 44.6697 90.2833Z"
              fill="black"
            />
          </g>
          <path
            d="M57.7698 103.49C57.7796 103.492 57.7714 103.559 57.7462 103.686C57.7276 103.815 57.6789 103.999 57.5869 104.23C57.4122 104.681 56.9922 105.344 56.173 105.742C55.7754 105.934 55.2771 106.092 54.7531 106.056C54.2389 106.06 53.6908 106.051 53.1152 106.039C51.9637 106.01 50.6987 105.958 49.3719 105.885C46.7183 105.736 44.3204 105.545 42.5867 105.386C41.7199 105.306 41.019 105.234 40.5354 105.176C40.0519 105.119 39.7852 105.077 39.7869 105.058C39.7885 105.038 40.0579 105.043 40.5442 105.066C41.0852 105.097 41.7757 105.137 42.6015 105.185C44.3932 105.294 46.7648 105.439 49.3867 105.6C50.7113 105.674 51.9735 105.733 53.1218 105.777C53.6924 105.792 54.2367 105.806 54.7503 105.82C55.253 105.865 55.7004 105.729 56.0859 105.56C56.8629 105.211 57.2846 104.61 57.485 104.186C57.5891 103.969 57.6542 103.795 57.6904 103.672C57.7331 103.551 57.7599 103.488 57.7698 103.49Z"
            fill="#263238"
          />
          <path
            d="M59.4875 103.065L66.6138 103.327C67.3262 103.354 67.9916 102.702 68.2933 101.683L73.8727 82.1316C74.2248 80.7802 73.4762 79.6905 72.2595 79.6143L62.8409 79.0229C61.3257 79.0229 60.5158 79.674 60.2453 80.8158L56.5748 96.2912C55.7956 99.574 57.2621 102.983 59.4875 103.065Z"
            fill="#263238"
          />
          <path
            d="M53.3896 93.8347L55.2854 89.8252L58.3985 88.7842L57.444 92.6174C58.6668 93.2071 60.5039 93.5127 61.5597 92.929C62.6155 92.3452 66.7503 88.2339 67.5295 88.6293C68.3088 89.0241 69.0064 89.9659 68.2923 90.8695C67.5777 91.773 63.703 94.5695 64.5036 95.3975C65.3047 96.2255 69.7703 92.5971 71.0856 92.8687C72.4009 93.1403 73.1982 93.9009 72.4666 94.4589C71.7438 95.0109 69.9389 96.8196 69.6191 97.6087C69.698 98.596 70.1651 99.9929 69.7352 100.235C69.1022 100.592 66.8965 101.353 66.4459 101.376C66.2093 101.387 64.543 102.039 63.0141 102.655C61.2908 103.35 59.3759 103.394 57.6225 102.779L56.7283 102.466C55.3369 101.978 54.4104 100.658 54.4257 99.1835L54.4558 96.2233L53.3896 93.8347Z"
            fill="#FFBE9D"
          />
          <path
            d="M58.1705 92.9098C58.1924 92.9152 58.2986 92.5785 58.4716 91.9652C58.6529 91.2944 58.8905 90.4171 59.1764 89.3597C59.4671 88.2383 59.8067 86.929 60.1807 85.485C60.3668 84.7528 60.5612 83.9873 60.7627 83.1944C60.9894 82.4174 61.0749 81.5122 61.617 80.9224C62.1772 80.3393 63.0829 80.3091 63.8687 80.3464C64.6835 80.4077 65.4709 80.4663 66.2233 80.5227C67.7111 80.6295 69.0598 80.7259 70.2147 80.8085C71.3077 80.8753 72.2145 80.9307 72.9083 80.9734C73.5452 81.0051 73.8984 81.0117 73.9 80.9892C73.9016 80.9668 73.5528 80.9175 72.9193 80.8469C72.2277 80.7779 71.323 80.6875 70.2333 80.5791C69.079 80.4762 67.7314 80.3562 66.2447 80.2237C65.4912 80.1613 64.7027 80.0956 63.8857 80.0277C63.4711 80.0058 63.0391 80.0019 62.601 80.083C62.1679 80.1624 61.7117 80.3453 61.3815 80.6979C61.0497 81.0467 60.8838 81.487 60.7627 81.8993C60.6456 82.3171 60.5552 82.7185 60.4539 83.1172C60.259 83.9117 60.07 84.6789 59.8899 85.4127C59.5411 86.8632 59.2246 88.178 58.953 89.3044C58.7076 90.3722 58.5045 91.2577 58.349 91.935C58.2132 92.5571 58.1485 92.9043 58.1705 92.9098Z"
            fill="#455A64"
          />
          <path
            d="M66.7327 83.4545C66.7327 83.84 67.0453 84.1527 67.4308 84.1527C67.8163 84.1527 68.129 83.84 68.129 83.4545C68.129 83.069 67.8163 82.7563 67.4308 82.7563C67.0453 82.7563 66.7327 83.069 66.7327 83.4545Z"
            fill="white"
          />
          <path
            d="M176.077 11.4589C176.443 8.79832 172.689 6.60964 169.867 6.38227C168.522 6.27358 167.124 6.47951 165.85 6.03548C163.087 5.07306 161.985 1.50511 159.29 0.368221C157.275 -0.481224 154.956 0.275269 153.003 1.25771C151.049 2.24086 149.119 3.47785 146.937 3.61728C145.039 3.73883 142.978 3.03454 141.317 3.96192C140.079 4.65263 139.432 6.07838 138.291 6.91996C137.175 7.74295 135.72 7.91742 134.335 7.99249C132.951 8.06757 131.517 8.08187 130.256 8.65889C128.996 9.23592 127.953 10.5694 128.214 11.9308L176.077 11.4589Z"
            fill="#EBEBEB"
          />
        </svg>

        <div className="working_des">
            <h3>“We are working on Vilvakart”</h3>
            <p>Wait for a Good Travel experience.</p>
        </div>
      </div>
    </div>
  );
};

export default Vilvakart;
