import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Emailmarketing = () => {
  return (
    <div>
        <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="email marketing, email campaigns, marketing solutions, customer engagement, email newsletters"
        />
        <meta
          name="description"
          content="Discover Vilva Business's email marketing services. Enhance customer engagement and grow your business with our effective email marketing strategies."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Email Marketing - Vilva Business</title>
      </Helmet>
      <Navbar/>
      

<div className="prod-des container mt-5">
  <div className="prod-head">
    <h5 style={{color: "#4a12f0",textAlign: "center"}}>To unlock the full potential of your email marketing for Vilva</h5>
    <h2 style={{color: "#001e60"}}>Consider these advanced strategies and tips</h2>
  </div>
  <div className="benefits row mx-auto">
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png" alt="" />
      <h3 style={{color: "#001e60"}}>Cost-Effective and High ROI</h3>
      <p>Email marketing is an affordable and efficient way to reach a wide audience. It consistently delivers a high return on investment, making it a powerful tool for driving sales and business growth.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png" alt="" />
      <h3 style={{color: "#001e60"}}>Targeted and Personalized Communication</h3>
      <p>Segmentation allows you to tailor messages based on customer behavior and preferences, ensuring relevance and engagement. Personalization further enhances this by creating content that resonates with individual recipients, boosting connection and conversion rates.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png" alt="" />
      <h3 style={{color: "#001e60"}}>Measurable Results and Insights</h3>
      <p>Track key metrics such as open rates and conversions to measure campaign success. Use these insights to make data-driven decisions, refining strategies and enhancing future email marketing efforts.</p>
    </div>
  </div>
</div>

<div className="usecase-all">
  <div className="usecase-head">
    <h5 style={{color: "#4a12f0"}}>Key Use Cases</h5>
    <h2 style={{color: "#001e60"}}>Manage Every Endpoint in One Place</h2>
    <h5 style={{color: "#28334a"}}>Target your audience to reach them</h5>
  </div>
  <div className="row container pt-5 mx-auto">
    <div className="col-md-6 col-12">
      <div className="useimg">
        <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="" style={{height: "100%", width: "90%"}} />
      </div>
    </div>
    <div className="col-12 col-md-6 mt-5">
      <div className="row" id="row1">
        <h3 style={{ color: "#040e56" }}>Welcome Emails</h3>
        <p>Greet new subscribers or customers with a warm welcome, introducing them to your brand and what they can expect from your emails.</p>
      </div>
      <div className="row" id="row2">
        <h3 style={{ color: "#040e56" }}>Newsletters</h3>
        <p>Announce special offers, discounts, and sales to drive purchases and increase revenue.</p>
      </div>
      <div className="row" id="row3">
        <h3 style={{ color: "#040e56" }}>Promotional Campaigns</h3>
        <p>Regular filing helps reconcile your input tax credit with the GST paid on purchases and sales, ensuring accuracy and avoiding discrepancies.</p>
      </div>
      <div className="row" id="row4">
        <h3 style={{ color: "#040e56" }}>Product Launches</h3>
        <p>Inform subscribers about new products or services, including details and benefits to generate excitement and early sales.</p>
      </div>
    </div>
  </div>
</div>

<div className="ready mt-5 pb-5 container">
  <div className="ready-des">
    <h3 style={{color: "#001e60"}}>Ready to Get Started?</h3>
    <h5 style={{color: "#28334a"}}>Experience device management, made easy!</h5>
    <div className="contact-us">
      <button type="button">Contact Us <i className="fa-solid fa-arrow-right"></i></button>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Emailmarketing
