import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Googleutubemarket = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="Google marketing, YouTube marketing, video advertising, digital marketing, Vilva Business"
        />
        <meta
          name="description"
          content="Discover Vilva Business's Google and YouTube marketing solutions for effective video advertising and digital marketing strategies. Enhance your online presence with our targeted campaigns."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Google & YouTube Marketing - Vilva Business</title>
      </Helmet>
      <Navbar/>
      {/* <section 
  className="page-title bg-overlay-black-60 jarallax" 
  data-speed="0.6" 
  style={{
    backgroundImage: "url(http://staging.vilvabusiness.com/vbtheme/images/marketing/fb.png)", 
    height: "80%", 
    width: "100%", 
    backgroundSize: "cover", 
    backgroundPosition: "top", 
    backgroundRepeat: "no-repeat"
  }}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>Google & Youtube Marketing</h1>
          <p>Use YouTube ads to promote your brand and drive website clicks, phone calls or in-store visits.</p>
        </div>
        <ul className="page-breadcrumb">
          <li><a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i></li>
          <li><span>Google & Youtube Marketing</span></li>
        </ul>
      </div>
    </div>
  </div>
</section> */}

<div className="features-layout2 pt-5 mt-5">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
        </div>
        <h3>Comprehensive Reach</h3>
        <p>Tap into a vast audience through Google Search and YouTube, increasing your brand’s visibility across multiple channels.</p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
        </div>
        <h3>Data-Driven Insights</h3>
        <p>Utilize detailed analytics to refine your strategies and maximize your marketing ROI.</p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
        </div>
        <h3>Enhanced Engagement</h3>
        <p>Capture attention with compelling video content on YouTube and targeted search ads on Google, driving more interactions and conversions.</p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: "#001e60" }}>
      <div className="project-des-con">
        <h2>Start your project with Us today</h2>
        <p>Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!</p>
        <div className="layout-btn">
          <button type="button">Start a Project &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>

    </div>
  )
}

export default Googleutubemarket
