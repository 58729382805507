import React from "react";

const SvgIcons = {
  Home: (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </svg>
  ),
  payment: (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.6667 5H3.33335C2.41288 5 1.66669 5.74619 1.66669 6.66667V13.3333C1.66669 14.2538 2.41288 15 3.33335 15H16.6667C17.5872 15 18.3334 14.2538 18.3334 13.3333V6.66667C18.3334 5.74619 17.5872 5 16.6667 5Z"
      stroke="black"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99998 11.6667C10.9205 11.6667 11.6666 10.9205 11.6666 9.99999C11.6666 9.07952 10.9205 8.33333 9.99998 8.33333C9.07951 8.33333 8.33331 9.07952 8.33331 9.99999C8.33331 10.9205 9.07951 11.6667 9.99998 11.6667Z"
      stroke="black"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 10H5.00833M15 10H15.0083"
      stroke="black"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  ),
  loading: (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5 1.66667L2.5 5V16.6667C2.5 17.1087 2.67559 17.5326 2.98816 17.8452C3.30072 18.1577 3.72464 18.3333 4.16667 18.3333H15.8333C16.2754 18.3333 16.6993 18.1577 17.0118 17.8452C17.3244 17.5326 17.5 17.1087 17.5 16.6667V5L15 1.66667H5Z"
      stroke="black"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 5H17.5"
      stroke="black"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3334 8.33333C13.3334 9.21739 12.9822 10.0652 12.357 10.6904C11.7319 11.3155 10.8841 11.6667 10 11.6667C9.11597 11.6667 8.26812 11.3155 7.643 10.6904C7.01788 10.0652 6.66669 9.21739 6.66669 8.33333"
      stroke="black"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  ),
  activesubscriptions: (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H15.8333C16.7538 2.5 17.5 3.24619 17.5 4.16667V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.66663 14.1665V8.33317" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 14.1665V10.8332" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.3334 14.1665V6.6665" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  ),
  activeservices: (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M10 10H10.0083" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.3333 4.99984V3.33317C13.3333 2.89114 13.1577 2.46722 12.8451 2.15466C12.5326 1.8421 12.1087 1.6665 11.6666 1.6665H8.33329C7.89127 1.6665 7.46734 1.8421 7.15478 2.15466C6.84222 2.46722 6.66663 2.89114 6.66663 3.33317V4.99984" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.3333 10.8335C15.8606 12.466 12.9629 13.3362 9.99996 13.3362C7.037 13.3362 4.1393 12.466 1.66663 10.8335" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.6666 5H3.33329C2.41282 5 1.66663 5.74619 1.66663 6.66667V15C1.66663 15.9205 2.41282 16.6667 3.33329 16.6667H16.6666C17.5871 16.6667 18.3333 15.9205 18.3333 15V6.66667C18.3333 5.74619 17.5871 5 16.6666 5Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  ),
  policies: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H15.8333C16.7538 2.5 17.5 3.24619 17.5 4.16667V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5Z" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66663 14.1665V8.33317" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 14.1665V10.8332" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3334 14.1665V6.6665" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
      ),
  logout: (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5" stroke="#EF1717" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3334 14.1668L17.5 10.0002L13.3334 5.8335" stroke="#EF1717" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5 10H7.5" stroke="#EF1717" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
      ),
}
export default SvgIcons;
