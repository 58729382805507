import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./registration.css";
import vbreg from "./images/vbreg.png";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import axios from "axios";
import { toast } from "react-toastify";

// AES-GCM Encryption and Decryption Functions
const encryptData = async (plainText, key) => {
  const encoder = new TextEncoder();
  try {
    const encodedKey = encoder.encode(key);
    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      await crypto.subtle.digest("SHA-256", encodedKey),
      { name: "AES-GCM" },
      false,
      ["encrypt"]
    );

    const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random IV

    const ciphertext = await crypto.subtle.encrypt(
      { name: "AES-GCM", iv, tagLength: 128 },
      cryptoKey,
      encoder.encode(plainText)
    );

    const encryptedData = new Uint8Array([...iv, ...new Uint8Array(ciphertext)]);
    return btoa(String.fromCharCode(...encryptedData)); // Return Base64 encoded ciphertext
  } catch (err) {
    console.error("Encryption failed:", err);
    throw new Error("Encryption failed");
  }
};

const decryptData = async (encryptedData, key) => {
  const decoder = new TextDecoder();
  try {
    // Decode the Base64 string to Uint8Array
    const data = Uint8Array.from(atob(encryptedData), (char) => char.charCodeAt(0));

    const iv = data.slice(0, 12); // Extract IV from the first 12 bytes
    const ciphertext = data.slice(12); // The rest is the ciphertext

    // Derive a 256-bit key using SHA-256
    const encodedKey = new TextEncoder().encode(key);
    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      await crypto.subtle.digest("SHA-256", encodedKey),
      { name: "AES-GCM" },
      false,
      ["decrypt"]
    );

    // Decrypt the data
    const decrypted = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv, tagLength: 128 },
      cryptoKey,
      ciphertext
    );

    return decoder.decode(decrypted); // Decode and return the plaintext
  } catch (err) {
    console.error("Decryption failed:", err);
    throw new Error("Decryption failed");
  }
};

const Verifyaccount = () => {
  const [username, setUsername] = useState(""); // Store username (phone number)
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Store OTP input (array of 6 values)
  const [isToastDisplayed, setIsToastDisplayed] = useState(false); // Flag for toast display
  const [responseMessage, setResponseMessage] = useState(""); // Store response message
  const [errorMessage, setErrorMessage] = useState(""); // Store error message
  const [isOtpRequested, setIsOtpRequested] = useState(false); // Flag to check if OTP has been requested
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const aesEncrypt = async (plainText, key) => {
    const encoder = new TextEncoder();
    try {
      // Derive a 256-bit key using SHA-256
      const encodedKey = encoder.encode(key);
      const cryptoKey = await crypto.subtle.importKey(
        'raw',
        await crypto.subtle.digest('SHA-256', encodedKey),
        { name: 'AES-GCM' },
        false,
        ['encrypt']
      );

      // Generate random IV (12 bytes)
      const iv = crypto.getRandomValues(new Uint8Array(12));

      // Encrypt the plain text
      const ciphertext = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv, tagLength: 128 },
        cryptoKey,
        encoder.encode(plainText)
      );

      // Combine IV, ciphertext, and tag into one Uint8Array
      const encryptedData = new Uint8Array([...iv, ...new Uint8Array(ciphertext)]);

      // Return Base64-encoded string
      return btoa(String.fromCharCode(...encryptedData));
    } catch (err) {
      console.error('Encryption failed:', err);
      throw new Error('Encryption failed');
    }
  };

  // AES-GCM Decryption Function
  const aesDecrypt = async (encryptedData, key) => {
    const decoder = new TextDecoder();

    try {
      // Decode the Base64-encoded string
      const data = Uint8Array.from(atob(encryptedData), (char) =>
        char.charCodeAt(0)
      );

      // Extract IV, ciphertext, and tag
      const iv = data.slice(0, 12); // First 12 bytes
      const ciphertext = data.slice(12); // Remaining part

      // Derive a 256-bit key using SHA-256
      const encodedKey = new TextEncoder().encode(key);
      const cryptoKey = await crypto.subtle.importKey(
        "raw",
        await crypto.subtle.digest("SHA-256", encodedKey),
        { name: "AES-GCM" },
        false,
        ["decrypt"]
      );

      // Decrypt the data
      const decrypted = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv, tagLength: 128 },
        cryptoKey,
        ciphertext
      );

      return decoder.decode(decrypted);
    } catch (err) {
      console.error("Decryption failed:", err);
      throw new Error("Decryption failed");
    }
  };
  const key = 'waSs57FdgthqfzVkv3RMrpTjBcYGKJJRQWtNxhmbS2EPpUjwy8'; // AES key


  const handleRequestOtp = async (e) => {
    setLoader(true)
    e.preventDefault();

    try {

      const registrationpayload = {
        username: username,
      };

      const encryptedData = await aesEncrypt(JSON.stringify(registrationpayload), key);

      const payload = {
        payload: encryptedData,
      };

      const response = await axios.post(
        'https://clientconnect.vilvabusiness.com/api/customer/login/request-otp',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.code == 200) {
        if (!isToastDisplayed) {
          toast.success("Otp Sent successful!", { autoClose: 2500 });
          setIsToastDisplayed(true);
        }
        const decryptedData = await aesDecrypt(response.data.payload, key);
        // console.log(response.data.payload)
        // console.log(decryptedData);
        setIsOtpRequested(true);
      
        // setError('');
      } else {
        setLoader(false)
     
        // setError(response.data.message);
      }
    } catch (err) {
      if (!isToastDisplayed) {
        toast.error("Otp sent failed. Please check your credentials.", {
          autoClose: 2500,
        });
        setIsToastDisplayed(true);
      }
      setLoader(false)
      // setError(err.message);
      setResponseMessage(null);
    }
    setLoader(false)

  };


  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1 && value !== "") {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  // Handle OTP backspace behavior
  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  // Handle submit OTP
  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    const otpString = otp.join("");
    try {

      const registrationpayload = {
        username: username,
        otp: otpString,
      };

      const encryptedData = await aesEncrypt(JSON.stringify(registrationpayload), key);

      const payload = {
        payload: encryptedData,
      };

      const response = await axios.post(
        'https://clientconnect.vilvabusiness.com/api/customer/login/submit-otp',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.code == 200) {

        if (!isToastDisplayed) {
          toast.success("Otp Sent successful!", { autoClose: 2500 });
          setIsToastDisplayed(true);
        }

        const decryptedData = await aesDecrypt(response.data.payload, key);

        const stringValue = JSON.parse(decryptedData);

        // console.log("stringValue", stringValue)


        const token = stringValue.token;

        // console.log(stringValue.user_id, "stringValue.user_id")

        const userId = stringValue.user_id;

        localStorage.setItem("userId", userId);

        // console.log("userId", userId)

        localStorage.setItem("authToken", token);

        navigate("/customerpannel");

        if (!isToastDisplayed) {
          toast.success("Login successful!", { autoClose: 2500 });
          setIsToastDisplayed(true);
        }

      } else {
        if (!isToastDisplayed) {
          toast.error("Invalid OTP. Please try again.", {
            autoClose: 2500,
          });
          setIsToastDisplayed(true);
        }
        setLoader(false)
        // setError(response.data.message);
      }


    } catch (err) {
      setLoader(false);
      if (!isToastDisplayed) {
        toast.error("Invalid OTP. Please try again.", {
          autoClose: 2500,
        });
        setIsToastDisplayed(true);
      }
      // setError(err.message);
      setResponseMessage(null);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="welcome_all my-5">
        <div className="row container mx-auto">
          <div className="col-md-6 col-12">
            <div className="welcome-back">
              <img src={vbreg} alt="Welcome Back" />
              {!isOtpRequested && (<>
                <h2>Welcome Back Leader!</h2>
                <form onSubmit={handleRequestOtp} >
                  <div>
                    <label htmlFor="username"> Email / Phone Number:</label>
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Enter Your  Email / Phone Number"
                      required
                    />
                  </div>

                  <button type="submit" disabled={loader} className="otp-btn">
                    {loader ? "Request OTP..." : "Request OTP"}
                  </button>
                </form>
              </>)}

              {isOtpRequested && (<>
                <h2>Welcome Back Leader!</h2>
                <form onSubmit={handleSubmitOtp}>
                  <div>
                    <label>OTP:</label>
                    <div style={{ display: "flex", gap: "5px" }}>
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          id={`otp-input-${index}`}
                          type="text"
                          value={digit}
                          onChange={(e) => handleOtpChange(e, index)}
                          onKeyDown={(e) => handleOtpKeyDown(e, index)}
                          maxLength="1"
                          required
                          style={{
                            width: "53px",
                            height: "40px",
                            textAlign: "center",
                            fontSize: "20px",
                            border: "1px solid #ccc",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <button className="otp_sbt_btn" disabled={loader} type="submit">{loader ? "Submit OTP..." : "Submit OTP"}</button>
                </form>
              </>)}

              {responseMessage && <p>Response: {responseMessage}</p>}
              {errorMessage && <p>Error: {errorMessage}</p>}
              <div className="otp-creation">
                <h5>
                  Already have an account?{" "}
                  <span>
                    <a href="/signin">Login Here</a>
                  </span>{" "}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-img">
              <div className="form-img-des">
                <h2>Sign In to connect with Vilva Business</h2>
                <p>
                  Join the Vilva Business community today to unlock exclusive
                  features, enjoy personalized services, and stay ahead with the
                  latest updates. Sign up now and take the first step towards
                  effortless business solutions!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Verifyaccount;
