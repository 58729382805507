import React, { useEffect } from 'react';
import './products.css'
import Navbar from './Navbar'
import Footer from './Footer'
import { Helmet } from 'react-helmet';

const Products = () => {
  const showContent = (id) => {
    const contents = document.querySelectorAll('.content');
    contents.forEach((content) => {
      content.style.display = 'none';
    });

    const headings = document.querySelectorAll('.heading');
    headings.forEach((heading) => {
      heading.classList.remove('selected');
    });

    const selectedContent = document.getElementById(`content${id}`);
    selectedContent.style.display = 'block';

    const selectedHeading = document.querySelector(`[data-id="${id}"]`);
    selectedHeading.classList.add('selected');
  };

  useEffect(() => {
    showContent(1); // Show the first content by default
  }, []);

  return (
    <div>
        <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="our product, business products, customer solutions, product support"
        />
        <meta
          name="description"
          content="Explore Vilva Business products designed to enhance your business efficiency. Discover solutions tailored to meet your specific needs."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Our Product Page - Vilva Business</title>
      </Helmet>
      <Navbar/>
      <div className="heading-about">
        <h1 className="mb-3">Our Area Of Expertise</h1>
        <p>
          We are a team of experts who are passionate about our work. We have expertise in WhatsApp API, AI chatbot, and Ecom SaaS.
          We deliver solutions that fulfill the traditional, transformational, and future needs of clients across the globe.
        </p>
      </div>

      <div className="maincan">
        <div className="container">
          <h2 style={{ textAlign: 'center', color: '#007E60' }}>Our Products</h2>
          <p style={{ textAlign: 'center', fontSize: '25px' }}>
            Send automated, secure messages and share rich media to enhance communication and customer engagement.
          </p>
          <div className="our-products container">
            <div className="con-2">
              <div className="heading selected" data-id="1" onClick={() => showContent(1)}>WhatsApp</div>
              <div className="heading" data-id="2" onClick={() => showContent(2)}>WhatsApp API</div>
              <div className="heading" data-id="3" onClick={() => showContent(3)}>AI Chatbot</div>
              <div className="heading" data-id="4" onClick={() => showContent(4)}>Ecom SaaS</div>
            </div>

            <div className="container">
              <div className="content" id="content1">
                <div className="row content-con" style={{ backgroundColor: 'white' }}>
                  <div className="col-12 col-md-8" style={{ display: 'flex' }}>
                    <div>
                      <p>Instant messaging and voice calls with end-to-end encryption, trusted by billions worldwide.</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 d-flex align-items-center">
                    <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/what.webp" className="img-fluid" alt="WhatsApp" style={{ marginTop: '80px' }} />
                  </div>
                </div>
              </div>

              <div className="content" id="content2">
                <div className="row content-con" style={{ backgroundColor: 'white' }}>
                  <div className="col-md-4 col-12 d-flex align-items-center mb-5 mb-md-0">
                    <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/API.jpg" className="img-fluid" alt="WhatsApp API" style={{ width: '500px' }} />
                  </div>
                  <div className="content-con col-md-8">
                    <p>
                      The WhatsApp API service offers a powerful suite of features specifically tailored to enhance the eCommerce customer experience...
                    </p>
                    <h2 style={{ color: '#007E60' }}>Key Features:</h2>
                    <ul className="ul-content container">
                      <li>Message Templates</li>
                      <li>Message Automation</li>
                      <li>CRM Integration</li>
                      <li>Detailed Analytics</li>
                      <li>Multimedia Messaging</li>
                      <li>Customer Support</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="content" id="content3">
                <div className="row content-con" style={{ backgroundColor: 'white' }}>
                  <div className="col-md-8 col-12 d-flex align-items-center mb-5 mb-md-0">
                    <div>
                      <p>
                        Chatbot services offer eCommerce businesses a robust tool for enhancing customer interactions, providing efficient support, and driving sales...
                      </p>
                      <h3 style={{ color: '#007E60' }}>Chatbot Services:</h3>
                      <ul className="ul-content">
                        <li>Customer Support</li>
                        <li>Sales and Product Recommendations</li>
                        <li>Order Management</li>
                        <li>Marketing and Promotions</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 d-flex align-items-center mb-5 mb-md-0">
                    <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/robot-blank-sign_1048-3553.jpg" className="img-fluid" alt="AI Chatbot" style={{ width: '500px' }} />
                  </div>
                </div>
              </div>

              <div className="content" id="content4">
                <div className="row content-con" style={{ backgroundColor: 'white' }}>
                  <div className="col-md-4 col-12 d-flex align-items-center mb-5 mb-md-0">
                    <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/saasss.png" className="img-fluid" alt="Ecom SaaS" style={{ width: '500px' }} />
                  </div>
                  <div className="col-md-8 col-12 d-flex align-items-center mb-5 mb-md-0">
                    <div>
                      <p>
                        Our eCommerce SaaS service provides a user-friendly, scalable, and secure solution for building and managing an online store...
                      </p>
                      <h3 style={{ color: '#007E60' }}>Key Features of our Ecom SaaS</h3>
                      <ul className="ul-content">
                        <li>Easy Website Builder</li>
                        <li>Product Management</li>
                        <li>Order and Payment Processing</li>
                        <li>Marketing and SEO Tools</li>
                        <li>Customer Engagement</li>
                        <li>Security and Compliance</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="business container " style={{
    display: "flex",
    alignItems: "center",  // AIC - Align items to the center vertically
    justifyContent: "center" // JCC - Justify content to the center horizontally
  }}>
          <div className="b-content">
            <h1 style={{ color: '#007E60' }}>Join Us and Elevate Your eCommerce Business</h1>
            <p>
              Our product is designed to simplify the process of launching and managing an online store, making it accessible for businesses of all sizes and industries...
            </p>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default Products;
