import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Bulkvoice = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="bulk voice calling, voice campaigns, automated calls, customer engagement, voice marketing solutions"
        />
        <meta
          name="description"
          content="Discover Vilva Business's bulk voice calling services. Enhance customer engagement and deliver your message effectively with our voice marketing solutions."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Bulk Voice Calling - Vilva Business</title>
      </Helmet>
      <Navbar/>
    

<div className="prod-des container mt-5">
  <div className="prod-head">
    <h5 style={{ color: "#4a12f0", textAlign: "center"  }}>Unlock Business Potential with Bulk Voice Calling</h5>
    <h2 style={{ color: "#001e60" }}>Revolutionize Your Communication Strategy</h2>
  </div>
  <div className="benifits mx-auto">
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png" alt="" />
      <h3 style={{ color: "#001e60" }}>Improved Customer Experience</h3>
      <p>Keep customers updated with important information, appointments, or promotions, enhancing their overall experience. Immediate Response: Quickly collect feedback and facilitate prompt replies, boosting customer satisfaction and engagement.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png" alt="" />
      <h3 style={{ color: "#001e60" }}>Real-Time Insights</h3>
      <p>Get detailed reports on call performance, including delivery rates and responses, to assess campaign success. Data-Driven Decisions: Leverage real-time insights to refine strategies and enhance future campaigns.</p>
    </div>
    <div className="col-md-3 col-12 ben-ai">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png" alt="" />
      <h3 style={{ color: "#001e60" }}>High Engagement Rates</h3>
      <p>Send tailored voice messages that engage your audience more effectively, boosting response rates. Direct Impact: Voice communications capture more attention than text, making sure your message stands out.</p>
    </div>
  </div>
</div>

<div className="usecase-all">
  <div className="usecase-head">
    <h5 style={{ color: "#4a12f0" }}>Key Use Cases</h5>
    <h2 style={{ color: "#001e60" }}>Manage Every Endpoint in One Place</h2>
    <h5 style={{ color: "#28334a" }}>Target your audience to reach them</h5>
  </div>
  <div className="row container pt-5 mx-auto">
    <div className="col-md-6 col-12">
      <div className="useimg">
        <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="" style={{ height: "100%", width: "90%" }} />
      </div>
    </div>
    <div className="col-12 col-md-6 mt-5">
      <div className="row" id="row1">
        <h3 style={{ color: "#040e56" }}>Marketing and Promotions</h3>
        <p>Businesses use bulk voice calling to quickly and cost-effectively inform potential customers about new products, special offers, or promotions.</p>
      </div>
      <div className="row" id="row2">
        <h3 style={{ color: "#040e56" }}>Debt Collection</h3>
        <p>Financial institutions may use bulk voice calls to remind customers of overdue payments or outstanding balances.</p>
      </div>
      <div className="row" id="row3">
        <h3 style={{ color: "#040e56" }}>Event Notifications</h3>
        <p>Event organizers can use bulk voice calling to notify attendees about event details, changes, or cancellations.</p>
      </div>
      <div className="row" id="row4">
        <h3 style={{ color: "#040e56" }}>Product Launches</h3>
        <p>Inform subscribers about new products or services, including details and benefits to generate excitement and early sales.</p>
      </div>
    </div>
  </div>
</div>

<div className="ready mt-5 pb-5 container">
  <div className="ready-des">
    <h3 style={{ color: "#001e60" }}>Ready to Get Started?</h3>
    <h5 style={{ color: "#28334a" }}>Experience device management, made easy!</h5>
    <div className="contact-us">
      <button type="button">Contact Us <i className="fa-solid fa-arrow-right"></i></button>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Bulkvoice
