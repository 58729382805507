import React, { useEffect, useState } from "react";
import proddash from "../images/proddash.svg";
import t from "../images/t.svg";
import axios from "axios";
import SvgIcons from "./SvgIcons";

const Ecom = () => {
  const [key, setKey] = useState(
    "waSs57FdgthqfzVkv3RMrpTjBcYGKJJRQWtNxhmbS2EPpUjwy8"
  );
  const [data, setData] = useState([]);
  
  // AES-GCM Encryption Function
  const encryptData = async (plainText, key) => {

    const encoder = new TextEncoder();

    try {
      const encodedKey = encoder.encode(key);
      const cryptoKey = await crypto.subtle.importKey(
        "raw",
        await crypto.subtle.digest("SHA-256", encodedKey),
        { name: "AES-GCM" },
        false,
        ["encrypt"]
      );

      const iv = crypto.getRandomValues(new Uint8Array(12));

      const ciphertext = await crypto.subtle.encrypt(
        { name: "AES-GCM", iv, tagLength: 128 },
        cryptoKey,
        encoder.encode(plainText)
      );

      const encryptedData = new Uint8Array([
        ...iv,
        ...new Uint8Array(ciphertext),
      ]);
      return btoa(String.fromCharCode(...encryptedData));
    } catch (err) {
      console.error("Encryption failed:", err);
      throw new Error("Encryption failed");
    }
  };

  // AES-GCM Decryption Function
  const decryptData = async (encryptedData, key) => {
    const decoder = new TextDecoder();

    try {
      // Decode the Base64-encoded string
      const data = Uint8Array.from(atob(encryptedData), (char) =>
        char.charCodeAt(0)
      );

      // Extract IV, ciphertext, and tag
      const iv = data.slice(0, 12); // First 12 bytes
      const ciphertext = data.slice(12); // Remaining part

      // Derive a 256-bit key using SHA-256
      const encodedKey = new TextEncoder().encode(key);
      const cryptoKey = await crypto.subtle.importKey(
        "raw",
        await crypto.subtle.digest("SHA-256", encodedKey),
        { name: "AES-GCM" },
        false,
        ["decrypt"]
      );

      // Decrypt the data
      const decrypted = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv, tagLength: 128 },
        cryptoKey,
        ciphertext
      );

      return decoder.decode(decrypted);
    } catch (err) {
      console.error("Decryption failed:", err);
      throw new Error("Decryption failed");
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("authToken");
      // console.log("User ID token:", token);

      if (!token) {
        console.error("No token found.");
        return;
      }

      try {
        // Axios request configuration
        const response = await axios.post(
          "https://clientconnect.vilvabusiness.com/api/getproducts",
          null, // No body for this POST request, pass null
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`, // Append token to Authorization header
            },
          }
        );

        // console.log("Response data:", response.data);

        // Decrypt and parse data (assuming you have a decryptData function and key)
        const decryptresdata = await decryptData(response.data.payload, key);
        const parsedData = JSON.parse(decryptresdata);
        setData(parsedData.data[3]); // Store the parsed data in state
        // console.log("parsedData",parsedData.data[3])
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="route_navigation_all pe-4">
        <div className="route_navigation">
          <p>
            <span>
              Dashboard{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span>
              Products{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            Ecom Saas
          </p>
        </div>
        <div className="route_filters_all">
          <div className="route_filters">
            <p>Filter</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M2.5 5H17.5"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.83337 10H14.1667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.33337 15H11.6667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="route_filters">
            <p>This Month</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="black"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="ecom_all">
        <div className="ecom_img_all">
          <div className="ecom_img">
            <img src={proddash} alt="img" />
            <div className="ecom_img_des">
              <h6>Ecom Saas</h6>
              <p>
                Streamline your online business with our all-in-one eCommerce
                SaaS solution.
              </p>
            </div>
          </div>
          <div className="create_store_all">
            <button type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clip-path="url(#clip0_1472_196)">
                  <path
                    d="M1.33337 4.66659L4.27337 1.72659C4.39741 1.60181 4.54492 1.50281 4.7074 1.4353C4.86987 1.3678 5.0441 1.33311 5.22004 1.33325H10.78C10.956 1.33311 11.1302 1.3678 11.2927 1.4353C11.4552 1.50281 11.6027 1.60181 11.7267 1.72659L14.6667 4.66659"
                    stroke="#02138E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.66663 8V13.3333C2.66663 13.687 2.8071 14.0261 3.05715 14.2761C3.3072 14.5262 3.64634 14.6667 3.99996 14.6667H12C12.3536 14.6667 12.6927 14.5262 12.9428 14.2761C13.1928 14.0261 13.3333 13.687 13.3333 13.3333V8"
                    stroke="#02138E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 14.6667V12.0001C10 11.6465 9.85952 11.3073 9.60948 11.0573C9.35943 10.8072 9.02029 10.6667 8.66667 10.6667H7.33333C6.97971 10.6667 6.64057 10.8072 6.39052 11.0573C6.14048 11.3073 6 11.6465 6 12.0001V14.6667"
                    stroke="#02138E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.33337 4.66675H14.6667"
                    stroke="#02138E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.6667 4.66675V6.66675C14.6667 7.02037 14.5262 7.35951 14.2762 7.60956C14.0261 7.85961 13.687 8.00008 13.3334 8.00008C12.9439 7.97864 12.5719 7.83125 12.2734 7.58008C12.1938 7.5226 12.0982 7.49166 12 7.49166C11.9019 7.49166 11.8063 7.5226 11.7267 7.58008C11.4282 7.83125 11.0562 7.97864 10.6667 8.00008C10.2772 7.97864 9.90519 7.83125 9.60671 7.58008C9.52716 7.5226 9.43152 7.49166 9.33337 7.49166C9.23523 7.49166 9.13959 7.5226 9.06004 7.58008C8.76155 7.83125 8.38955 7.97864 8.00004 8.00008C7.61053 7.97864 7.23853 7.83125 6.94004 7.58008C6.86049 7.5226 6.76485 7.49166 6.66671 7.49166C6.56856 7.49166 6.47292 7.5226 6.39337 7.58008C6.09489 7.83125 5.72288 7.97864 5.33337 8.00008C4.94386 7.97864 4.57186 7.83125 4.27337 7.58008C4.19383 7.5226 4.09818 7.49166 4.00004 7.49166C3.9019 7.49166 3.80625 7.5226 3.72671 7.58008C3.42822 7.83125 3.05622 7.97864 2.66671 8.00008C2.31309 8.00008 1.97395 7.85961 1.7239 7.60956C1.47385 7.35951 1.33337 7.02037 1.33337 6.66675V4.66675"
                    stroke="#02138E"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1472_196">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Create Store
            </button>
          </div>
        </div>
        <hr style={{ height: "0.8px", background: "#EFEFEF" }} />
        <div className="stores_all">
          <div className="store_name_all">
            <div className="store_name pb-3">
              <h5>Thaithingal</h5>
              <p>www.mypolo.in</p>
            </div>
            <div className="store_name">
              <h5>My Polo</h5>
              <p>www.thaithingal.in</p>
            </div>
          </div>
          <div className="store_count">
            <span>2 Stores</span>
          </div>
        </div>
      </div>
      <div className="list_store_all">
        <div className="list_head">
          <p>List of Stores</p>
        </div>
        <div className="store_details_all">
          <div className="store_details">
            <div className="store_detail_left">
              <div className="company_name_all">
                <div className="company_letter">
                  <img src={t} alt="img" />
                </div>
                <div className="company_name">
                  <h4>Thaithingal</h4>
                  <p>www. thaithingal.in</p>
                </div>
              </div>
              <div className="plan_activate_all">
                <div className="plan_activate">
                  <h4>Activated On</h4>
                  <p>22.05.2024</p>
                </div>
                <div className="plan_pricing_all">
                  <h4>Plan</h4>
                  <p>₹ 20000</p>
                </div>
              </div>
            </div>
            <div className="store_detail_right">
              <div className="store_plan_end">
                <span>Ends at 20.11.2025</span>
              </div>
              <div className="store_plan_status">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle opacity="0.2" cx="8" cy="8" r="8" fill="#0DC410" />
                    <circle
                      cx="7.99996"
                      cy="8.00008"
                      r="5.33333"
                      fill="#0DC410"
                    />
                  </svg>
                  Active Plan
                </p>
              </div>
              <div className="view_my_store">
                <button type="button">View Store</button>
              </div>
            </div>
          </div>
          <hr style={{ height: "0.8px", background: "#EFEFEF" }} />

          <div className="store_details">
            <div className="store_detail_left">
              <div className="company_name_all">
                <div className="company_letter">
                  <img src={t} alt="img" />
                </div>
                <div className="company_name">
                  <h4>My Polo</h4>
                  <p>www. mypolo.in</p>
                </div>
              </div>
              <div className="plan_activate_all">
                <div className="plan_activate">
                  <h4>Activated On</h4>
                  <p>22.05.2024</p>
                </div>
                <div className="plan_pricing_all">
                  <h4>Plan</h4>
                  <p>₹ 20000</p>
                </div>
              </div>
            </div>
            <div className="store_detail_right">
              <div className="store_plan_end">
                <span>Ends at 20.11.2025</span>
              </div>
              <div className="store_plan_status">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle opacity="0.2" cx="8" cy="8" r="8" fill="#0DC410" />
                    <circle
                      cx="7.99996"
                      cy="8.00008"
                      r="5.33333"
                      fill="#0DC410"
                    />
                  </svg>
                  Active Plan
                </p>
              </div>
              <div className="view_my_store">
                <button type="button">View Store</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="invoice_table">
            <div className="table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th> Opening Balance</th>
                    <th>Closing Balance</th>
                    <th></th>
                  </tr>
                </ thead>
                <tbody>
                {data.plans && data.plans.length > 0 ? (
          data.plans.map((payment, index) => (
            <tr key={index}>
              <td>{payment.id}</td>
              <td>
                {payment.created_at ? new Date(payment.created_at).toLocaleString() : "N/A"}
              </td>
              <td>
                {payment.name || "N/A"} </td>
                <td>₹ {payment.price || 0} /-</td>
              <td>{payment.billing_cycle || "N/A"}</td>

             
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" style={{ textAlign: "center" }}>
            <SvgIcons.loading/> Loding...
            </td>
          </tr>
        )}



                </tbody>
              </table>
            </div>
          </div>
    </div>
  );
};

export default Ecom;
