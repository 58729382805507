import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./registration.css";
import vbreg from "./images/vbreg.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Encryptdecrypt from "../pages/customer/Encryptdecrypt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock ,faUnlock } from "@fortawesome/free-solid-svg-icons"; 

// AES-GCM Encryption Function
const encryptData = async (plainText, key) => {

  const encoder = new TextEncoder();

  try {
    const encodedKey = encoder.encode(key);
    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      await crypto.subtle.digest("SHA-256", encodedKey),
      { name: "AES-GCM" },
      false,
      ["encrypt"]
    );

    const iv = crypto.getRandomValues(new Uint8Array(12));

    const ciphertext = await crypto.subtle.encrypt(
      { name: "AES-GCM", iv, tagLength: 128 },
      cryptoKey,
      encoder.encode(plainText)
    );

    const encryptedData = new Uint8Array([
      ...iv,
      ...new Uint8Array(ciphertext),
    ]);
    return btoa(String.fromCharCode(...encryptedData));
  } catch (err) {
    console.error("Encryption failed:", err);
    throw new Error("Encryption failed");
  }
};

// AES-GCM Decryption Function
const decryptData = async (encryptedData, key) => {
  const decoder = new TextDecoder();

  try {
    // Decode the Base64-encoded string
    const data = Uint8Array.from(atob(encryptedData), (char) =>
      char.charCodeAt(0)
    );

    // Extract IV, ciphertext, and tag
    const iv = data.slice(0, 12); // First 12 bytes
    const ciphertext = data.slice(12); // Remaining part

    // Derive a 256-bit key using SHA-256
    const encodedKey = new TextEncoder().encode(key);
    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      await crypto.subtle.digest("SHA-256", encodedKey),
      { name: "AES-GCM" },
      false,
      ["decrypt"]
    );

    // Decrypt the data
    const decrypted = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv, tagLength: 128 },
      cryptoKey,
      ciphertext
    );

    return decoder.decode(decrypted);
  } catch (err) {
    console.error("Decryption failed:", err);
    throw new Error("Decryption failed");
  }
};

const Welcomeback = () => {
  const [username, setUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useState("");
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isToastDisplayed, setIsToastDisplayed] = useState(false); 
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(savedRememberMe);
  }, []);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setRememberMe(isChecked);
    localStorage.setItem('rememberMe', isChecked);
  };

  const [key, setKey] = useState(
    "waSs57FdgthqfzVkv3RMrpTjBcYGKJJRQWtNxhmbS2EPpUjwy8"
  ); 
  
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoader(true)
    try {
      const encryptedUsername = await encryptData(username, key);
      const encryptedPassword = await encryptData(password, key);

      const loginpayload = {
        username: username,
        password: password,
      };
      const payload = {
        payload: await encryptData(JSON.stringify(loginpayload), key),
      };

      const result = await axios.post(
        "https://clientconnect.vilvabusiness.com/api/customer/login",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );


      setResponse(result.data);

      // console.log("result.data",result.data)
     
      const decryptresdata = await decryptData(result.data.payload, key);

      // console.log("decryptresdata",decryptresdata)
      
      const parsedData = JSON.parse(decryptresdata);

      const token = parsedData.token;

      // console.log(parsedData.user_id,"parsedData.user_id")

      const userId = parsedData.user_id;

      localStorage.setItem("userId", userId);

      // console.log("userId",userId)

      localStorage.setItem("authToken", token);
      
      navigate("/customerpannel");

      if (!isToastDisplayed) {
        toast.success("Login successful!", { autoClose: 2500 });
        setIsToastDisplayed(true);
      }
    } catch (err) {
      setError(err);
      setLoader(false)


      if (!isToastDisplayed) {
        toast.error("Login failed. Please check your credentials.", {
          autoClose: 2500,
        });
        setIsToastDisplayed(true);
      }
    }
  };
  


  return (
    <div>
      <Navbar />
      <div className="welcome_all my-5">
        <div className="row container mx-auto">
          <div className="col-md-6 col-12">
            <div className="welcome-back">
              <img src={vbreg} alt="Welcome Back" />
              <h2>Welcome Back Leader!</h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                <div>
                  <label htmlFor="username">Email / Mobile</label>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter Your Email / Mobile"
                    required
                  />
                </div>
                <div style={{position:"relative"}}>
                  <label htmlFor="password">Password</label>
                  <input
                     type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Your Password"
                    required
                  />
                  <div className="password_view" onClick={() => setShowPassword(!showPassword)}
                  >
                  {showPassword ? <FontAwesomeIcon icon={faUnlock} /> : <FontAwesomeIcon icon={faLock} />} 
                  </div>
                </div>
                <div className="forgot_pass">
                  <div className="d-flex align-items-center gap-1">
                <input type="checkbox" id="rememberMe"
        checked={rememberMe}
        onChange={handleCheckboxChange} />
                <label htmlFor="rememberMe">Remember Me</label></div>
                  <a href="/forgotpassword">Forgot Password?</a>
                </div>
                <button disabled={loader} type="submit" className="loginn">
                {loader ? "Login..." : "Login"}
                </button>
                <a href="/verifyaccount">
                  <button type="button" className="otp-btn">
                    Login With OTP
                  </button>
                </a>
              </form>
              {/* {error && <p style={{ color: "red" }}>Error: {error.message}</p>} */}
              {response && (
                <p style={{ color: "green" }}>
                  Response: {JSON.stringify(response)}
                </p>
              )}
              <div className="sign-up-sec">
                <h5>
                  Don’t have an account?{" "}
                  <span>
                    <a href="/registration">SignUp Here</a>
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-img">
              <div className="form-img-des">
                <h2>Sign In to connect with Vilva Business</h2>
                <p>
                  Join the Vilva Business community today to unlock exclusive
                  features, enjoy personalized services, and stay ahead with the
                  latest updates. Sign up now and take the first step towards
                  effortless business solutions!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Welcomeback;
