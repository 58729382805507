import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Customvoicecalling = () => {
  return (
    <div>
         <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="custom voice calling, voice call solutions, business communication, automated calls, Vilva Business"
        />
        <meta
          name="description"
          content="Discover Vilva Business's custom voice calling solutions for efficient business communication. Enhance your outreach with our automated voice call services."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Custom Voice Calling - Vilva Business</title>
      </Helmet>
      <Navbar/>
    

<div className="features-layout2 mt-5 pt-5">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
        </div>
        <h3>Personalized Engagement</h3>
        <p>Tailor your messages to resonate with individual customer needs, increasing engagement and response rates.</p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
        </div>
        <h3>Improved Conversion Rates</h3>
        <p>Create a more compelling call-to-action through personalized voice interactions, leading to higher sales and conversions.</p>
      </div>
    </div>

    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
        </div>
        <h3>Enhanced Customer Experience</h3>
        <p>Provide a memorable and unique customer experience that sets your brand apart from competitors.</p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: "#001e60" }}>
      <div className="project-des-con">
        <h2>Start your project with Us today</h2>
        <p>Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!</p>

        <div className="layout-btn">
          <button type="button">Start a Project&nbsp;&nbsp;&nbsp;<i className="ri-arrow-right-line"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Customvoicecalling
