import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Pagesblog from "../blog/pagesblog/Pagesblog";
import { Helmet } from "react-helmet";

const Appgroceries = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="app for groceries, grocery shopping app, grocery delivery app, food shopping app"
        />
        <meta
          name="description"
          content="Explore our grocery management app designed to simplify shopping and enhance customer convenience. Discover how our app can optimize your grocery shopping experience."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>App for Groceries - Vilva Business</title>
      </Helmet>
      <Navbar />
     

      <div className="prod-des container mt-5">
        <div className="prod-head">
          <h5 style={{ color: "#4a12f0" }}>Enjoy seamless e-commerce</h5>
          <h2 style={{ color: "#001e60" }}>
            Effortless management, scalable growth and secure protection
          </h2>
        </div>
        <div className="benifits mx-auto row">
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Smart shopping List</h3>
            <p>
              The Smart Shopping Lists feature is your personal shopping
              assistant, helping you plan, organize, and optimize your grocery
              shopping like never before. With intelligent suggestions,
              collaborative capabilities, and automatic organization, grocery
              shopping has never been this easy and efficient!
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Real-time Order Tracking</h3>
            <p>
              With Real-time Order Tracking, you're always connected and
              informed, making grocery delivery more transparent and reliable
              than ever. Enjoy the convenience of knowing where your groceries
              are at any moment and plan your day without any guesswork.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Secure Payment Options</h3>
            <p>
              With our Secure Payment Options, enjoy the convenience of flexible
              payment methods and the assurance that your financial information
              is handled with the utmost security. Shop with confidence, knowing
              that every transaction is protected by cutting-edge security
              technologies.
            </p>
          </div>
        </div>
      </div>

      <div className="usecase-all">
        <div className="usecase-head">
          <h5 style={{ color: "#4a12f0" }}>Use Cases</h5>
          <h2 style={{ color: "#001e60" }}>
            Experience the future of grocery shopping
          </h2>
          <h5 style={{ color: "#28334a" }}>
            Unify the management of every endpoint, Revolutionize Your Grocery
            Shopping Experience
          </h5>
        </div>
        <div className="row container pt-5 mx-auto">
          <div className="col-md-6 col-12">
            <div className="useimg">
              <img
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg"
                alt=""
                style={{ height: "75%", width: "90%" }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row" id="row1">
              <h3 style={{ color: "#040e56" }}>E-commerce Stores</h3>
              <p>
                Ideal for creating and managing online retail shops selling
                physical or digital products.
              </p>
            </div>
            <div className="row" id="row2">
              <h3 style={{ color: "#040e56" }}>Subscription Services</h3>
              <p>
                Perfect for businesses offering subscription boxes, memberships,
                or recurring billing services.
              </p>
            </div>
            <div className="row" id="row3">
              <h3 style={{ color: "#040e56" }}>Shop Smarter, Live Better</h3>
              <p>
                Unlock the potential of hassle-free grocery shopping with our
                app's intuitive features designed to save you time and effort.
              </p>
            </div>
            <div className="row" id="row4">
              <h3 style={{ color: "#040e56" }}>Custom Product Sales</h3>
              <p>
                Supports businesses that offer customizable or made-to-order
                products, with tools for managing customizations and orders.
              </p>
            </div>
            <div className="row" id="row5">
              <h3 style={{ color: "#040e56" }}>Multi-channel Sales</h3>
              <p>
                Allows businesses to sell across multiple channels, including
                social media platforms, online marketplaces, and in-person
                through point-of-sale systems.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ready mt-5 pb-5 container">
        <div className="ready-des">
          <h3 style={{ color: "#001e60" }}>
            Unlock New Opportunities – Start Your Success Story!
          </h3>
          <h5 style={{ color: "#28334a" }}>
            Experience device management, made easy!
          </h5>
          <div className="contact-us">
            <button type="button">
              <a href="/contact" target="_blank" style={{ color: "white" }}>
                Join the Community
              </a>
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <Pagesblog />

      <Footer />
    </div>
  );
};

export default Appgroceries;
