import React, { useEffect, useState } from 'react'
import "./dashboard.css";
import axios from "axios";
import SvgIcons from "./SvgIcons";
const History = () => {

  const [key, setKey] = useState(
    "waSs57FdgthqfzVkv3RMrpTjBcYGKJJRQWtNxhmbS2EPpUjwy8"
  );
  const [data, setData] = useState([]);
  
  // AES-GCM Encryption Function
  const encryptData = async (plainText, key) => {

    const encoder = new TextEncoder();

    try {
      const encodedKey = encoder.encode(key);
      const cryptoKey = await crypto.subtle.importKey(
        "raw",
        await crypto.subtle.digest("SHA-256", encodedKey),
        { name: "AES-GCM" },
        false,
        ["encrypt"]
      );

      const iv = crypto.getRandomValues(new Uint8Array(12));

      const ciphertext = await crypto.subtle.encrypt(
        { name: "AES-GCM", iv, tagLength: 128 },
        cryptoKey,
        encoder.encode(plainText)
      );

      const encryptedData = new Uint8Array([
        ...iv,
        ...new Uint8Array(ciphertext),
      ]);
      return btoa(String.fromCharCode(...encryptedData));
    } catch (err) {
      console.error("Encryption failed:", err);
      throw new Error("Encryption failed");
    }
  };

  // AES-GCM Decryption Function
  const decryptData = async (encryptedData, key) => {
    const decoder = new TextDecoder();

    try {
      // Decode the Base64-encoded string
      const data = Uint8Array.from(atob(encryptedData), (char) =>
        char.charCodeAt(0)
      );

      // Extract IV, ciphertext, and tag
      const iv = data.slice(0, 12); // First 12 bytes
      const ciphertext = data.slice(12); // Remaining part

      // Derive a 256-bit key using SHA-256
      const encodedKey = new TextEncoder().encode(key);
      const cryptoKey = await crypto.subtle.importKey(
        "raw",
        await crypto.subtle.digest("SHA-256", encodedKey),
        { name: "AES-GCM" },
        false,
        ["decrypt"]
      );

      // Decrypt the data
      const decrypted = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv, tagLength: 128 },
        cryptoKey,
        ciphertext
      );

      return decoder.decode(decrypted);
    } catch (err) {
      console.error("Decryption failed:", err);
      throw new Error("Decryption failed");
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("authToken");
      // console.log("User ID token:", token);

      if (!token) {
        console.error("No token found.");
        return;
      }

      try {
        // Axios request configuration
        const response = await axios.post(
          "https://clientconnect.vilvabusiness.com/api/wallet/transaction-history",
          null, // No body for this POST request, pass null
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`, // Append token to Authorization header
            },
          }
        );

        // console.log("Response data:", response.data);

        // Decrypt and parse data (assuming you have a decryptData function and key)
        const decryptresdata = await decryptData(response.data.payload, key);
        const parsedData = JSON.parse(decryptresdata);
        setData(parsedData.data[3]); // Store the parsed data in state
        // console.log("parsedData",parsedData.data[3])
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  return (
    <div>
      <div className="route_navigation_all pe-4">
        <div className="route_navigation">
          <p>
            <span>
              Dashboard{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span>
              Wallet{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            History
          </p>
        </div>
        <div className="route_filters_all">
          <div className="route_filters">
            <p>Filter</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M2.5 5H17.5"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.83337 10H14.1667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.33337 15H11.6667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="route_filters">
            <p>This Month</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="black"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="invoice_table">
        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>SI. NO</th>
                <th>Date</th>
                {/* <th>Product</th> */}
                <th>Description</th>
                <th>Amount</th>
                <th> Opening Balance</th>
                <th>Closing Balance</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
               {data.plans && data.plans.length > 0 ? (
          data.plans.map((payment, index) => (
            <tr key={index}>
              <td>{payment.id}</td>
              <td>
                {payment.created_at ? new Date(payment.created_at).toLocaleString() : "N/A"}
              </td>
              <td>
                {payment.name || "N/A"} </td>
                <td>₹ {payment.price || 0} /-</td>
              <td>{payment.billing_cycle || "N/A"}</td>

             
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" style={{ textAlign: "center" }}>
            <SvgIcons.loading/> Loding...
            </td>
          </tr>
        )}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default History
