import React from "react";
import "./dashboard.css";

const Invoice = () => {
  return (
    <div>
      <div className="route_navigation_all pe-4">
        <div className="route_navigation">
          <p>
            <span>
              Dashboard{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span>
              Payments{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            Invoices
          </p>
        </div>
        <div className="route_filters_all">
          <div className="route_filters">
            <p>Filter</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M2.5 5H17.5"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.83337 10H14.1667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.33337 15H11.6667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="route_filters">
            <p>This Month</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="black"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="invoice_table">
        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>Number</th>
                <th>Date</th>
                <th>Product</th>
                <th>Description</th>
                <th>Period</th>
                <th>Created At</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Ecom Saas </td>
                <td>Business Growth Software</td>
                <td>30 Days</td>
                <td>12 Oct 2024</td>
                <td className="payment_status">Paid</td>

                <td>
                  <div className="table_buttons">
                    <div className="view">
                      <button type="button">View</button>
                    </div>
                    <div className="view">
                      <button type="button">Download</button>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 9.99996 9.16666C9.53972 9.16666 9.16663 9.53975 9.16663 9.99999C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333Z"
                        stroke="#666666"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99996 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 9.99996 3.33334C9.53972 3.33334 9.16663 3.70644 9.16663 4.16668C9.16663 4.62691 9.53972 5.00001 9.99996 5.00001Z"
                        stroke="#666666"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
                        stroke="#666666"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Ecom Saas </td>
                <td>Business Growth Software</td>
                <td>30 Days</td>
                <td>12 Oct 2024</td>
                <td className="payment_failed">overdue</td>
                <td>
                  <div className="table_buttons">
                    <div className="view">
                      <button type="button">View</button>
                    </div>
                    <div className="view">
                      <button type="button">Download</button>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 9.99996 9.16666C9.53972 9.16666 9.16663 9.53975 9.16663 9.99999C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333Z"
                        stroke="#666666"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99996 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 9.99996 3.33334C9.53972 3.33334 9.16663 3.70644 9.16663 4.16668C9.16663 4.62691 9.53972 5.00001 9.99996 5.00001Z"
                        stroke="#666666"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
                        stroke="#666666"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Ecom Saas </td>
                <td>Business Growth Software</td>
                <td>30 Days</td>
                <td>12 Oct 2024</td>
                <td className="payment_status">Paid</td>
                <td>
                  <div className="table_buttons">
                    <div className="view">
                      <button type="button">View</button>
                    </div>
                    <div className="view">
                      <button type="button">Download</button>
                    </div>
                    <div className="table_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 9.99996 9.16666C9.53972 9.16666 9.16663 9.53975 9.16663 9.99999C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 9.99996 3.33334C9.53972 3.33334 9.16663 3.70644 9.16663 4.16668C9.16663 4.62691 9.53972 5.00001 9.99996 5.00001Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Ecom Saas </td>
                <td>Business Growth Software</td>
                <td>30 Days</td>
                <td>12 Oct 2024</td>
                <td className="payment_status">Paid</td>
                <td>
                  <div className="table_buttons">
                    <div className="view">
                      <button type="button">View</button>
                    </div>
                    <div className="view">
                      <button type="button">Download</button>
                    </div>
                    <div className="table_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 9.99996 9.16666C9.53972 9.16666 9.16663 9.53975 9.16663 9.99999C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 9.99996 3.33334C9.53972 3.33334 9.16663 3.70644 9.16663 4.16668C9.16663 4.62691 9.53972 5.00001 9.99996 5.00001Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr> <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Ecom Saas </td>
                <td>Business Growth Software</td>
                <td>30 Days</td>
                <td>12 Oct 2024</td>
                <td className="payment_status">Paid</td>
                <td>
                  <div className="table_buttons">
                    <div className="view">
                      <button type="button">View</button>
                    </div>
                    <div className="view">
                      <button type="button">Download</button>
                    </div>
                    <div className="table_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 9.99996 9.16666C9.53972 9.16666 9.16663 9.53975 9.16663 9.99999C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 9.99996 3.33334C9.53972 3.33334 9.16663 3.70644 9.16663 4.16668C9.16663 4.62691 9.53972 5.00001 9.99996 5.00001Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr> <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Ecom Saas </td>
                <td>Business Growth Software</td>
                <td>30 Days</td>
                <td>12 Oct 2024</td>
                <td className="payment_status">Paid</td>
                <td>
                  <div className="table_buttons">
                    <div className="view">
                      <button type="button">View</button>
                    </div>
                    <div className="view">
                      <button type="button">Download</button>
                    </div>
                    <div className="table_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 9.99996 9.16666C9.53972 9.16666 9.16663 9.53975 9.16663 9.99999C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 9.99996 3.33334C9.53972 3.33334 9.16663 3.70644 9.16663 4.16668C9.16663 4.62691 9.53972 5.00001 9.99996 5.00001Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr> <tr>
                <td>IND-12345</td>
                <td>12 Nov 2024</td>
                <td>Ecom Saas </td>
                <td>Business Growth Software</td>
                <td>30 Days</td>
                <td>12 Oct 2024</td>
                <td className="payment_failed">overdue</td>
                <td>
                  <div className="table_buttons">
                    <div className="view">
                      <button type="button">View</button>
                    </div>
                    <div className="view">
                      <button type="button">Download</button>
                    </div>
                    <div className="table_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.99996 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 9.99999C10.8333 9.53975 10.4602 9.16666 9.99996 9.16666C9.53972 9.16666 9.16663 9.53975 9.16663 9.99999C9.16663 10.4602 9.53972 10.8333 9.99996 10.8333Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 5.00001C10.4602 5.00001 10.8333 4.62691 10.8333 4.16668C10.8333 3.70644 10.4602 3.33334 9.99996 3.33334C9.53972 3.33334 9.16663 3.70644 9.16663 4.16668C9.16663 4.62691 9.53972 5.00001 9.99996 5.00001Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
