import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Fbinstamarketing = () => {
  return (
    <div>
        <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="Facebook marketing, Instagram marketing, social media marketing, business promotion, Vilva Business"
        />
        <meta
          name="description"
          content="Discover Vilva Business's Facebook and Instagram marketing solutions to boost your business's online presence. Enhance your social media strategy with our expert services."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Facebook & Instagram Marketing - Vilva Business</title>
      </Helmet>
      <Navbar/>
      {/* <section className="page-title bg-overlay-black-60 jarallax" 
  style={{
    marginTop:'100px',
    color:'black',
    backgroundImage: 'url(http://staging.vilvabusiness.com/vbtheme/images/marketing/sm.png)', 
    height: '100%', 
    width: '100%', 
    backgroundSize: 'cover', 
    backgroundPosition: 'right', 
    backgroundRepeat: 'no-repeat'
  }}>
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="page-title-name">
          <h1>Facebook & Instagram Marketing</h1>
        </div>
        <ul className="page-breadcrumb">
          <li>
            <a href="/"><i className="fa fa-home"></i> Home</a> <i className="fa fa-angle-double-right"></i>
          </li>
          <li><span>Facebook & Instagram Marketing</span></li>
        </ul>
      </div>
    </div>
  </div>
</section> */}

<div className="features-layout2 mt-5 pt-5">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
        </div>
        <h3>Targeted Advertising</h3>
        <p>Reach your ideal audience with precision using advanced targeting options on both platforms.</p>
      </div>
    </div>
    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
        </div>
        <h3>Increased Brand Visibility</h3>
        <p>Boost your brand’s presence and recognition through engaging content and strategic ad placements.</p>
      </div>
    </div>
    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
        </div>
        <h3>Enhanced Customer Interaction</h3>
        <p>Foster deeper connections with your audience through interactive posts, stories, and direct messaging.</p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
    </div>
    <div className="col-12 col-md-6 project-des" style={{color: '#001e60'}}>
      <div className="project-des-con">
        <h2>Start your project with Us today</h2>
        <p>Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!</p>
        <div className="layout-btn">
          <button type="button">
            Start a Project &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Fbinstamarketing
