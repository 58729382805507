import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Pagesblog from "../blog/pagesblog/Pagesblog";
import { Helmet } from "react-helmet";

const Appfruits = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="app for fruits and vegetables, grocery app, fresh produce app, farm-to-table app"
        />
        <meta
          name="description"
          content="Explore our fruits and vegetables app designed to offer fresh produce and enhance customer satisfaction. Discover how our app can simplify your shopping experience for healthy choices."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>App for Fruits & Vegetables - Vilva Business</title>
      </Helmet>
      <Navbar />
     

      <div className="features-layout2 mt-5 py-5">
        <div className="row mx-auto container">
          <div className="col-12 col-md-4 mt-3">
            <div className="layout-col-2 mt-3">
              <div className="top-bg"></div>
              <div className="imgforcol-test">
                <img
                  src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}"
                  alt=""
                />
              </div>
              <h3>Freshness and Quality Tracking</h3>
              <p>
                An Android app can provide real-time information on the
                freshness and quality of fruits and vegetables, allowing
                customers to make informed purchasing decisions and ensuring
                they receive the best produce.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-12 mt-3">
            <div className="layout-col-2 mt-3">
              <div className="top-bg-c"></div>
              <div className="imgforcol">
                <img
                  src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}"
                  alt=""
                />
              </div>
              <h3>Convenient Ordering and Delivery</h3>
              <p>
                The app can streamline the ordering process, offering features
                like easy selection of products, scheduling of deliveries, and
                tracking of orders. This convenience enhances the customer
                experience and drives repeat business.
              </p>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-3">
            <div className="layout-col-2 mt-3">
              <div className="top-bg"></div>
              <div className="imgforcol-test">
                <img
                  src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}"
                  alt=""
                />
              </div>
              <h3>Personalized Recommendations and Offers</h3>
              <p>
                By analyzing customer preferences and purchase history, the app
                can provide tailored recommendations and exclusive offers. This
                personalization increases customer satisfaction and boosts sales
                through targeted promotions.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="start-project mt-5">
        <div className="row mx-auto">
          <div className="col-12 col-md-6 project">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png"
              alt=""
              className="img-fluid"
            />
          </div>
          <div
            className="col-12 col-md-6 project-des"
            style={{ color: "#001e60" }}
          >
            <div className="project-des-con">
              <h2>Your Road to Success Begins Here – Let’s Build Together!</h2>
              <p>
                Grow your fresh produce business with a dedicated app. Offer
                seamless ordering, track deliveries, and connect with your
                customers directly to ensure top-quality service!
              </p>

              <div className="layout-btn">
                <button type="button">
                  <a href="/contact" target="_blank" style={{ color: "white" }}>
                    See the Results
                  </a>{" "}
                  &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagesblog />

      <Footer />
    </div>
  );
};

export default Appfruits;
