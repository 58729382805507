import React, { useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { FaBars } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [activeMainMenu, setActiveMainMenu] = useState(null); // Keep track of main menu
  const [activeSubMenu, setActiveSubMenu] = useState(null); // Keep track of submenus
  const [activeSubSubMenu, setActiveSubSubMenu] = useState(null); // Keep track of sub-submenus
  const [activeSubSubSubMenu, setActiveSubSubSubMenu] = useState(null); // Keep track of sub-submenus

  // Function to toggle the main menu
  const toggleMainMenu = (menuId) => {
    setActiveMainMenu(activeMainMenu === menuId ? null : menuId);
  };

  // Function to toggle the submenu
  const toggleSubMenu = (submenuId) => {
    // Only toggle the submenu, the main menu stays open
    setActiveSubMenu(activeSubMenu === submenuId ? null : submenuId);
  };

  // Function to toggle the sub-submenu
  const toggleSubSubMenu = (submenuId) => {
    // Only toggle the sub-submenu, the main menu and submenu stay open
    setActiveSubSubMenu(activeSubSubMenu === submenuId ? null : submenuId);
  };

  const toggleSubSubSubMenu = (submenuId) => {
    // Only toggle the sub-submenu, the main menu and submenu stay open
    setActiveSubSubSubMenu(
      activeSubSubSubMenu === submenuId ? null : submenuId
    );
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to handle clicks on menu items
  const handleClick = (path) => {
    // console.log(`Navigating to ${path}`);
    // You can add your routing logic here, like using react-router's history.push()
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div>
          <header className="header" id="header">
            <nav
              className="navbar navbar-expand-lg fixed-top"
              style={{ background: "white" }}
            >
              <div className="container">
                <a className="ms-2" href="/">
                  <img
                    src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/navimg.png"
                    alt=""
                    className="vil img img-fluid"
                  />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar"
                  aria-label="Toggle navigation"
                  onClick={toggleMenu}
                >
                  <i>
                    <FaBars
                      className="fa-solid fa-bars"
                      style={{ color: "#0a0a0a" }}
                    />
                  </i>
                </button>
                <div
                  className="offcanvas offcanvas-end"
                  tabIndex="-1"
                  id="offcanvasNavbar"
                  aria-labelledby="offcanvasNavbarLabel"
                >
                  <div className="offcanvas-header">
                    <a className="ms-1" href="/">
                      <img
                        src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/navimg.png"
                        alt=""
                        className="vil img img-fluid"
                      />
                    </a>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  {/* {Desk View } START   */}
                  <div className="offcanvas-body">
                    <ul className="nav__list navbar-nav">
                      <li
                        className="nav__item nav-item"
                        style={{ marginTop: "1px" }}
                      >
                        <a href="/" className="nav__link">
                          <i>
                            {" "}
                            <GoArrowUpRight className="ri-arrow-right-up-line" />
                          </i>

                          {/* <i className="ri-arrow-right-up-line"></i> */}
                          <span style={{ color: "black" }}>Home</span>
                        </a>
                      </li>

                      {/* Navigation Item with Dropdown */}
                      <li className="nav__item nav-item">
                        <a
                          href="#"
                          className="nav__link dropdown-toggle menu"
                          data-toggle="dropdown"
                        >
                          <i>
                            {" "}
                            <GoArrowUpRight className="ri-arrow-right-up-line" />
                          </i>{" "}
                          <span
                            style={{ color: "black" }}
                            className="menu-item"
                          >
                            Products
                            <div className="submenu">
                              <a href="/ecomsaas">
                                <div
                                  className="submenu-item"
                                  onClick={() => handleClick("/ecomsaas")}
                                >
                                  Ecom Saas
                                </div>
                              </a>
                              <a href="/products">
                                <div
                                  className="submenu-item"
                                  onClick={() => handleClick("/products")}
                                >
                                  Products
                                </div>
                              </a>
                              <a href="/growsuite">
                                <div
                                  className="submenu-item"
                                  onClick={() => handleClick("/growsuite")}
                                >
                                  Growsuite
                                </div>
                              </a>
                              <div className="submenu-item">
                                Ecommerce Solutions &nbsp; &nbsp;&nbsp; <b>+</b>
                                <div className="sub-submenu">
                                  <a href="magentosolution">
                                    <div
                                      className="sub-submenu-item"
                                      onClick={() =>
                                        handleClick("/magentosolution")
                                      }
                                    >
                                      Magento website development
                                    </div>
                                  </a>
                                  <a href="opencart">
                                    <div
                                      className="sub-submenu-item"
                                      onClick={() => handleClick("/opencart")}
                                    >
                                      Opencart Website development
                                    </div>
                                  </a>
                                  <a href="wordpress">
                                    <div
                                      className="sub-submenu-item"
                                      onClick={() => handleClick("/wordpress")}
                                    >
                                      WordPress website development
                                    </div>
                                  </a>
                                  <a href="/shopify">
                                    <div
                                      className="sub-submenu-item"
                                      onClick={() => handleClick("/shopify")}
                                    >
                                      Shopify website development
                                    </div>
                                  </a>
                                  <a href="/androidsolution">
                                    <div
                                      className="sub-submenu-item"
                                      onClick={() =>
                                        handleClick("/androidsolution")
                                      }
                                    >
                                      Android website development
                                    </div>
                                  </a>
                                  <a href="/iossolution">
                                    <div
                                      className="sub-submenu-item"
                                      onClick={() =>
                                        handleClick("/iossolution")
                                      }
                                    >
                                      IOS website development
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="submenu-item">
                                Industry &nbsp; &nbsp;&nbsp; <b>+</b>
                                <div className="sub-submenu">
                                  <div className="sub-submenu-item">
                                    Android Apps &nbsp; &nbsp;&nbsp; <b>+</b>
                                    <div className="insidesub-submenu">
                                      <a href="/appbotique">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/appbotique")
                                          }
                                        >
                                          APP for Boutique
                                        </div>
                                      </a>
                                      <a href="/apprestaurant">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/apprestaurant")
                                          }
                                        >
                                          APP for Restaurants{" "}
                                        </div>
                                      </a>
                                      <a href="/apphotels">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/apphotels")
                                          }
                                        >
                                          APP for Hotels{" "}
                                        </div>
                                      </a>
                                      <a href="/appshops">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/appshops")
                                          }
                                        >
                                          APP for Shops
                                        </div>
                                      </a>
                                      <a href="/appecom">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/appecom")
                                          }
                                        >
                                          APP for Ecommerce
                                        </div>
                                      </a>
                                      <a href="/appgroceries">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/appgroceries")
                                          }
                                        >
                                          APP for Groceries
                                        </div>
                                      </a>
                                      <a href="/appfruits">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/appfruits")
                                          }
                                        >
                                          APP for Fruits & Vegetables{" "}
                                        </div>
                                      </a>
                                      <a href="/appwholesaler">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/appwholesaler")
                                          }
                                        >
                                          APP for Wholesalers{" "}
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="sub-submenu-item">
                                    Accounting solution &nbsp; &nbsp;&nbsp;{" "}
                                    <b>+</b>
                                    <div className="insidesub-submenu">
                                      <a href="/gstregistration">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/gstregistration")
                                          }
                                        >
                                          GST Registration
                                        </div>
                                      </a>
                                      <a href="/gstfilling">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/gstfilling")
                                          }
                                        >
                                          GST Filings
                                        </div>
                                      </a>
                                      <a href="/accounting">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/accounting")
                                          }
                                        >
                                          Accounting Softwares
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="sub-submenu-item">
                                    Banking solution &nbsp; &nbsp;&nbsp;{" "}
                                    <b>+</b>
                                    <div className="insidesub-submenu">
                                      <a href="/oracleplsql">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/oracleplsql")
                                          }
                                        >
                                          Oracle pl/sql Web Services{" "}
                                        </div>
                                      </a>
                                      <a href="/paymentgateway">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/paymentgateway")
                                          }
                                        >
                                          Payment Gateway Solutions
                                        </div>
                                      </a>
                                      <a href="/paymentcollection">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/paymentcollection")
                                          }
                                        >
                                          Payment Collection Services
                                        </div>
                                      </a>
                                      <a href="/bankverification">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/bankverification")
                                          }
                                        >
                                          Bank Verification API's
                                        </div>
                                      </a>
                                      <a href="/aadharpan">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/aadharpan")
                                          }
                                        >
                                          Aadhar & PAN Verification API's
                                        </div>
                                      </a>
                                      <a href="/virtualapi">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/virtualapi")
                                          }
                                        >
                                          Virtual Accounts API
                                        </div>
                                      </a>
                                      <a href="/virtualaccount">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/virtualaccount")
                                          }
                                        >
                                          Virtual Current Accounts{" "}
                                        </div>
                                      </a>
                                      <a href="/corporatecard">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/corporatecard")
                                          }
                                        >
                                          Corporate Cards & Expense Cards{" "}
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </a>
                      </li>

                      <li className="nav__item nav-item">
                        <a
                          href="#"
                          className="nav__link dropdown-toggle menu"
                          data-toggle="dropdown"
                        >
                          <i>
                            {" "}
                            <GoArrowUpRight className="ri-arrow-right-up-line" />
                          </i>{" "}
                          <span
                            style={{ color: "black" }}
                            className="menu-item"
                          >
                            Services
                            <div className="submenu">
                              <div
                                className="submenu-item pe-5"
                                onClick={() => handleClick("/technologies")}
                              >
                                Technology
                              </div>
                              <a href="/aiml">
                                <div
                                  className="submenu-item pe-5"
                                  onClick={() => handleClick("/aiml")}
                                >
                                  AI/ML
                                </div>
                              </a>
                              <a href="/fintechsolution">
                                <div
                                  className="submenu-item pe-5"
                                  onClick={() =>
                                    handleClick("/fintechsolution")
                                  }
                                >
                                  FinTech Solution
                                </div>
                              </a>
                              <a href="/cybersecurity">
                                <div
                                  className="submenu-item pe-5"
                                  onClick={() => handleClick("/cybersecurity")}
                                >
                                  Cyber Security
                                </div>
                              </a>
                              <a href="/kycverification">
                                <div
                                  className="submenu-item pe-5"
                                  onClick={() =>
                                    handleClick("/kycverification")
                                  }
                                >
                                  KYC Verification
                                </div>
                              </a>
                              <div className="submenu-item">
                                Marketing &nbsp; &nbsp;&nbsp; <b>+</b>
                                <div className="sub-submenu">
                                  <div className="sub-submenu-item">
                                    Whatsapp Marketing &nbsp; &nbsp;&nbsp;{" "}
                                    <b>+</b>
                                    <div className="insidesub-submenu">
                                      <a href="/vilvabizsender">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/vilvabizsender")
                                          }
                                        >
                                          Vilva Biz Sender Pro
                                        </div>
                                      </a>
                                      <a href="/bulkwhatsapp">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/bulkwhatsapp")
                                          }
                                        >
                                          Bulk WhatsApp Marketing
                                        </div>
                                      </a>
                                      <a href="/whatsappbuisness">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/whatsappbuisness")
                                          }
                                        >
                                          WhatsApp Buisness Api
                                        </div>
                                      </a>
                                      <a href="/officialwhatsapp">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/officialwhatsapp")
                                          }
                                        >
                                          Official WhatsApp Buisness &Facebook
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <a href="/emailmarketing">
                                    <div
                                      className="sub-submenu-item"
                                      onClick={() =>
                                        handleClick("/emailmarketing")
                                      }
                                    >
                                      Email Marketing
                                    </div>
                                  </a>
                                  <a href="/smsmarketing">
                                    <div
                                      className="sub-submenu-item"
                                      onClick={() =>
                                        handleClick("/smsmarketing")
                                      }
                                    >
                                      SMS Marketing
                                    </div>
                                  </a>
                                  <div className="sub-submenu-item">
                                    Voice Marketing &nbsp; &nbsp;&nbsp; <b>+</b>
                                    <div className="insidesub-submenu">
                                      <a href="/bulkvoice">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/bulkvoice")
                                          }
                                        >
                                          Bulk Voice Calling
                                        </div>
                                      </a>
                                      <a href="/customvoicecalling">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/customvoicecalling")
                                          }
                                        >
                                          Custom Voice Calling
                                        </div>
                                      </a>
                                      <a href="/apivoicecalling">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/apivoicecalling")
                                          }
                                        >
                                          API Based Voice Calling
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="sub-submenu-item">
                                    Digital Marketing &nbsp; &nbsp;&nbsp;{" "}
                                    <b>+</b>
                                    <div className="insidesub-submenu">
                                      <a href="/fbinstamarketing">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/fbinstamarketing")
                                          }
                                        >
                                          Facebook & Instagram Marketing
                                        </div>
                                      </a>
                                      <a href="/linkedinmarketing">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/linkedinmarketing")
                                          }
                                        >
                                          Linkedin Marketing
                                        </div>
                                      </a>
                                      <a href="/googleutubemarket">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/googleutubemarket")
                                          }
                                        >
                                          Google & Youtube Marketing
                                        </div>
                                      </a>
                                      <a href="/appinstall">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/appinstall")
                                          }
                                        >
                                          App Installs Marketing
                                        </div>
                                      </a>{" "}
                                      <a href="/salesconversion">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/salesconversion")
                                          }
                                        >
                                          Sales Conversion Marketing
                                        </div>
                                      </a>{" "}
                                      <a href="/leadgeneration">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/leadgeneration")
                                          }
                                        >
                                          Lead Generation Marketing
                                        </div>
                                      </a>
                                      <a href="/eventsignup">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick("/eventsignup")
                                          }
                                        >
                                          Event Signup/Ticket sales Marketing
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="sub-submenu-item">
                                    SEO & SMM &nbsp; &nbsp;&nbsp; <b>+</b>
                                    <div className="insidesub-submenu">
                                      <a href="/socialmediamanagement">
                                        <div
                                          className="insidesub-submenu-item"
                                          onClick={() =>
                                            handleClick(
                                              "/socialmediamanagement"
                                            )
                                          }
                                        >
                                          Social Media Management & Marketing
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </a>
                      </li>

                      <li className="nav__item nav-item">
                        <a
                          href="/partners"
                          className="nav__link dropdown-toggle menu"
                          data-toggle="dropdown"
                        >
                          <i>
                            {" "}
                            <GoArrowUpRight className="ri-arrow-right-up-line" />
                          </i>{" "}
                          <span
                            style={{ color: "black" }}
                            className="menu-item"
                          >
                            Partners
                          </span>
                        </a>
                      </li>

                      <li className="nav__item nav-item">
                        <a
                          href="/registration"
                          className="nav__link dropdown-toggle menu"
                          data-toggle="dropdown"
                        >
                          <i>
                            {" "}
                            <GoArrowUpRight className="ri-arrow-right-up-line" />
                          </i>{" "}
                          <span
                            style={{ color: "black" }}
                            className="menu-item"
                          >
                            Register
                          </span>
                        </a>
                      </li>
                      <li className="nav__item nav-item">
                        <a
                          href="/blog"
                          className="nav__link dropdown-toggle menu"
                          data-toggle="dropdown"
                        >
                          <i>
                            {" "}
                            <GoArrowUpRight className="ri-arrow-right-up-line" />
                          </i>{" "}
                          <span
                            style={{ color: "black" }}
                            className="menu-item"
                          >
                            Blog
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="d-flex"></div>
                  </div>
                  {/* {Desk View } END   */}
                </div>
              </div>
            </nav>
          </header>
        </div>

        {menuOpen && <div className="menu-overlay" onClick={toggleMenu}></div>}

        {/* {Mobile menu} START*/}
        <div className={`Mobilemobile ${menuOpen ? "open" : ""}`}>
          <div className="menu-content">
            <button className="close-button" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faX} />
            </button>
            <ul>
            <div className="primary-menu-item">
  <a href="/" className="primary-menu-item-link "style={{ color: "black" }}>
    Home
  </a>
</div>

              <div className="primary-menu-item ">
                <a href="/products"  style={{ color: "black" }}>PRODUCTS</a>{" "}
                <span
                  className="submenu-indicator"
                  onClick={() => toggleSubMenu("productsSubMenu")}
                >
                  {activeSubMenu === "productsSubMenu" ? (
                    <FontAwesomeIcon icon={faMinus} />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} />
                  )}
                </span>
              </div>
              {activeSubMenu === "productsSubMenu" && (
                <div className=" d-flex flex-column" id="productsSubMenu">
                  <a className="menu-item" href="/ecomsaas">
                    Ecom Saas
                  </a>
                  <a className="menu-item" href="/products"  style={{ color: "black" }}>
                    Products
                  </a>
                  <a className="menu-item" href="/growsuite">
                    GrowSuite
                  </a>
                  <div className="menu-item">
                    Ecommerce Solutions{" "}
                    <span
                      className="submenu-indicator"
                      onClick={() => toggleSubSubMenu("ecommerceSubMenu")}
                    >
                      {activeSubSubMenu === "ecommerceSubMenu" ? (
                        <FontAwesomeIcon icon={faMinus} />
                      ) : (
                        <FontAwesomeIcon icon={faPlus} />
                      )}
                    </span>
                  </div>
                  {activeSubSubMenu === "ecommerceSubMenu" && (
                    <div className="" id="ecommerceSubMenu">
                      <a className="menu-item" href="/magentosolution">
                        {" "}
                        Magento website development
                      </a>
                      <a className="menu-item" href="/opencart-solutions">
                        Opencart Website Development
                      </a>
                      <a className="menu-item" href="/wordpress-solutions">
                        WordPress website development
                      </a>
                      <a className="menu-item" href="/shopify-solutions">
                        Shopify website development
                      </a>
                      <a className="menu-item" href="/android-solutions">
                        Android website development
                      </a>
                      <a className="menu-item" href="/ios-solutions">
                        iOS website development
                      </a>
                    </div>
                  )}
                  <div className="menu-item">
                    Industry{" "}
                    <span
                      className="submenu-indicator"
                      onClick={() => toggleSubSubMenu("industrySubMenu")}
                    >
                      {activeSubSubMenu === "industrySubMenu" ? (
                        <FontAwesomeIcon icon={faMinus} />
                      ) : (
                        <FontAwesomeIcon icon={faPlus} />
                      )}
                    </span>
                  </div>
                  {activeSubSubMenu === "industrySubMenu" && (
                    <div className="" id="industrySubMenu">
                      <div className="menu-item">
                        Android Apps{" "}
                        <span
                          className="submenu-indicator"
                          onClick={() => toggleSubSubSubMenu("industrySubMenu")}
                        >
                          {activeSubSubSubMenu === "industrySubMenu" ? (
                            <FontAwesomeIcon icon={faMinus} />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </span>
                      </div>
                      {activeSubSubSubMenu === "industrySubMenu" && (
                        <>
                          <a className="menu-item" href="/app-for-boutiques">
                            APP For Boutique
                          </a>
                          <a className="menu-item" href="/app-for-restaurants">
                            APP For Restaurants
                          </a>
                          <a className="menu-item" href="/app-for-hotels">
                            APP For Hotels
                          </a>
                          <a className="menu-item" href="/app-for-shops">
                            APP For Shops
                          </a>
                          <a className="menu-item" href="/app-for-ecommerce">
                            APP For Ecommerce
                          </a>
                          <a className="menu-item" href="/app-for-groceries">
                            APP For Groceries
                          </a>
                          <a
                            className="menu-item"
                            href="/app-for-fruits-vegetables"
                          >
                            APP For Fruits & Vegetables
                          </a>
                        </>
                      )}

                      <div className="menu-item">
                        Accounting Solution{" "}
                        <span
                          className="submenu-indicator"
                          onClick={() =>
                            toggleSubSubSubMenu("Accounting Solution")
                          }
                        >
                          {activeSubSubSubMenu === "Accounting Solution" ? (
                            <FontAwesomeIcon icon={faMinus} />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </span>
                      </div>
                      {activeSubSubSubMenu === "Accounting Solution" && (
                        <>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/gst-registration")}
                          >
                            GST Registration
                          </div>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/gst-fillings")}
                          >
                            GST Fillings
                          </div>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/accounting-softwares")}
                          >
                            Accounting Softwares
                          </div>
                        </>
                      )}

                      <div className="menu-item">
                        Banking Solution{" "}
                        <span
                          className="submenu-indicator"
                          onClick={() =>
                            toggleSubSubSubMenu("Banking Solution")
                          }
                        >
                          {activeSubSubSubMenu === "Banking Solution" ? (
                            <FontAwesomeIcon icon={faMinus} />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </span>
                      </div>
                      {activeSubSubSubMenu === "Banking Solution" && (
                        <>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/oracle-plsql-webservices")
                            }
                          >
                            Oracle pI/Sql Web Services
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/payment-gateway-solutions")
                            }
                          >
                            Payment Gateway Solutions
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/payment-collection-services")
                            }
                          >
                            Payment Collection Services
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/bank-verification-api")
                            }
                          >
                            Bank Verification API's
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/aadhar-pan-verification-api")
                            }
                          >
                            Aadhar & PAN Verification API's
                          </div>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/virtual-api-accounts")}
                          >
                            Virtual Accounts API's
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/virtual-current-accounts")
                            }
                          >
                            Virtual Current Accounts
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/corporate-cards-expense-cards")
                            }
                          >
                            Corporate Cards & Expense Cards
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}

              <div className="primary-menu-item ">
                SERVICES{" "}
                <span
                  className="submenu-indicator"
                  onClick={() => toggleSubMenu("servicesSubMenu")}
                >
                  {activeSubMenu === "servicesSubMenu" ? (
                    <FontAwesomeIcon icon={faMinus} />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} />
                  )}
                </span>
              </div>
              {activeSubMenu === "servicesSubMenu" && (
                <div className=" d-flex flex-column" id="servicesSubMenu">
                  <div
                    className="menu-item"
                    onClick={() => handleClick("/technologies")}
                  >
                    Technology
                  </div>
                  <div
                    className="menu-item"
                    onClick={() => handleClick("/ai-ml")}
                  >
                    AI/ML
                  </div>
                  <div
                    className="menu-item"
                    onClick={() => handleClick("/fintechsolution")}
                  >
                    FinTech Solution
                  </div>
                  <div
                    className="menu-item"
                    onClick={() => handleClick("/cybersecurity")}
                  >
                    Cyber Security
                  </div>
                  <div
                    className="menu-item"
                    onClick={() => handleClick("/kycverification")}
                  >
                    KYC Verification
                  </div>
                  <div className="primary-menu-item ">
                    MARKETING{" "}
                    <span
                      className="submenu-indicator"
                      onClick={() => toggleSubSubMenu("marketingSubMenu")}
                    >
                      {activeSubSubMenu === "marketingSubMenu" ? (
                        <FontAwesomeIcon icon={faMinus} />
                      ) : (
                        <FontAwesomeIcon icon={faPlus} />
                      )}
                    </span>
                  </div>
                  {activeSubSubMenu === "marketingSubMenu" && (
                    <div className="" id="marketingSubMenu">
                      <div
                        className="menu-item"
                        onClick={(e) =>
                          toggleSubSubSubMenu("WhatsApp Marketing")
                        }
                      >
                        WhatsApp Marketing{" "}
                        {activeSubSubSubMenu === "WhatsApp Marketing" ? (
                          <FontAwesomeIcon icon={faMinus} />
                        ) : (
                          <FontAwesomeIcon icon={faPlus} />
                        )}
                      </div>

                      {activeSubSubSubMenu === "WhatsApp Marketing" && (
                        <>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/vliva-biz-sender-pro")}
                          >
                            Vilva Biz Sender Pro
                          </div>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/bulk-whatsapp")}
                          >
                            Bulk WhatsApp Marketing
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/whatsapp-business-api")
                            }
                          >
                            WhatsApp Business API
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick(
                                "/official-whatsapp-facebook-business"
                              )
                            }
                          >
                            Official WhatsApp Business & Facebook Business Setup
                          </div>{" "}
                        </>
                      )}
                      <div
                        className="menu-item"
                        onClick={() => handleClick("/email-marketing")}
                      >
                        Email Marketing
                      </div>
                      <div
                        className="menu-item"
                        onClick={() => handleClick("/sms-marketing")}
                      >
                        SMS Marketing
                      </div>
                      <div className="menu-item">
                        Voice Marketing{" "}
                        <span
                          className="submenu-indicator"
                          onClick={(e) =>
                            toggleSubSubSubMenu("Voice Marketing")
                          }
                        >
                          {activeSubSubSubMenu === "Voice Marketing" ? (
                            <FontAwesomeIcon icon={faMinus} />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </span>
                        <div
                          className="dropdown-menu1"
                          id="Voice Marketing"
                        ></div>
                      </div>

                      {activeSubSubSubMenu === "Voice Marketing" && (
                        <>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/bulk-voice-calling")}
                          >
                            Bulk Voice Calling (Transactional & Promotional)
                          </div>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/custom-voice-calling")}
                          >
                            Custom Voice Calling
                          </div>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/api-voice-calling")}
                          >
                            API based Voice Calling
                          </div>
                        </>
                      )}
                      <div className="menu-item">
                        Digital Marketing{" "}
                        <span
                          className="submenu-indicator"
                          onClick={(e) =>
                            toggleSubSubSubMenu("Digital Marketing")
                          }
                        >
                          {activeSubSubSubMenu === "Digital Marketing" ? (
                            <FontAwesomeIcon icon={faMinus} />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </span>
                        <div
                          className="dropdown-menu1"
                          id="Digital Marketing"
                        ></div>
                      </div>

                      {activeSubSubSubMenu === "Digital Marketing" && (
                        <>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/facebook-instagram-marketing")
                            }
                          >
                            Facebook & Instagram Marketing
                          </div>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/linkedin-marketing")}
                          >
                            LinkedIn Marketing
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/google-youtube-marketing")
                            }
                          >
                            Google & YouTube Marketing
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/app-installs-marketing")
                            }
                          >
                            App Installs Marketing
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/sales-conversion-marketing")
                            }
                          >
                            Sales Conversion Marketing
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick("/lead-generation-marketing")
                            }
                          >
                            Lead Generation Marketing
                          </div>
                          <div
                            className="menu-item"
                            onClick={() =>
                              handleClick(
                                "/event-signup-ticket-sales-marketing"
                              )
                            }
                          >
                            Event Signup / Ticket Sales Marketing
                          </div>
                        </>
                      )}
                      <div className="menu-item">
                        SEO & SMM{" "}
                        <span
                          className="submenu-indicator"
                          onClick={(e) => toggleSubSubSubMenu("SEO & SMM")}
                        >
                          {activeSubSubSubMenu === "SEO & SMM" ? (
                            <FontAwesomeIcon icon={faMinus} />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </span>
                        <div className="dropdown-menu1" id="SEO & SMM"></div>
                      </div>

                      {activeSubSubSubMenu === "SEO & SMM" && (
                        <>
                          <div
                            className="menu-item"
                            onClick={() => handleClick("/bulk-voice-calling")}
                          >
                            Social Media Management & Marketing
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* <div
              className="primary-menu-item"
              onClick={() => handleClick("/blog")}
            >
              BLOG
            </div> */}
              <div className="primary-menu-item">
                <a href="/blog" className="primary-menu-item-link" style={{ color: "black" }}>
                  BLOG
                </a>
              </div>
              <div className="primary-menu-item">
                <a href="/partners" className="primary-menu-item-link" style={{ color: "black" }}>
                PARTNERS
                </a>
              </div>

              {/* <div
                className="primary-menu-item"
                onClick={() => handleClick("/partners")}
              >
                PARTNERS
              </div> */}

             
              <div className="primary-menu-item">
                <a href="/signin" className="primary-menu-item-link" style={{ color: "black" }}>
                REGISTER / LOGIN
                </a>
              </div>
            </ul>
          </div>
        </div>
      </div>
      {/* {Mobile menu} END*/}
    </>
  );
};

export default Navbar;
