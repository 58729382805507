import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./registration.css";
import vbreg from "./images/vbreg.png";
import { useNavigate } from "react-router-dom";

// AES-GCM Encryption Function
const aesEncrypt = async (plainText, key) => {
  const encoder = new TextEncoder();
  try {
    // Derive a 256-bit key using SHA-256
    const encodedKey = encoder.encode(key);
    const cryptoKey = await crypto.subtle.importKey(
      'raw',
      await crypto.subtle.digest('SHA-256', encodedKey),
      { name: 'AES-GCM' },
      false,
      ['encrypt']
    );

    // Generate random IV (12 bytes)
    const iv = crypto.getRandomValues(new Uint8Array(12));

    // Encrypt the plain text
    const ciphertext = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv, tagLength: 128 },
      cryptoKey,
      encoder.encode(plainText)
    );

    // Combine IV, ciphertext, and tag into one Uint8Array
    const encryptedData = new Uint8Array([...iv, ...new Uint8Array(ciphertext)]);

    // Return Base64-encoded string
    return btoa(String.fromCharCode(...encryptedData));
  } catch (err) {
    console.error('Encryption failed:', err);
    throw new Error('Encryption failed');
  }
};

// AES-GCM Decryption Function
const aesDecrypt = async (encryptedData, key) => {
  const decoder = new TextDecoder();

  try {
    // Decode the Base64-encoded string
    const data = Uint8Array.from(atob(encryptedData), (char) =>
      char.charCodeAt(0)
    );

    // Extract IV, ciphertext, and tag
    const iv = data.slice(0, 12); // First 12 bytes
    const ciphertext = data.slice(12); // Remaining part

    // Derive a 256-bit key using SHA-256
    const encodedKey = new TextEncoder().encode(key);
    const cryptoKey = await crypto.subtle.importKey(
      "raw",
      await crypto.subtle.digest("SHA-256", encodedKey),
      { name: "AES-GCM" },
      false,
      ["decrypt"]
    );

    // Decrypt the data
    const decrypted = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv, tagLength: 128 },
      cryptoKey,
      ciphertext
    );

    return decoder.decode(decrypted);
  } catch (err) {
    console.error("Decryption failed:", err);
    throw new Error("Decryption failed");
  }
};

const Registration = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const key = 'waSs57FdgthqfzVkv3RMrpTjBcYGKJJRQWtNxhmbS2EPpUjwy8'; // AES key

  // Handle form submission and encryption
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Concatenate all fields into a single string
      //const concatenatedData = `${firstName}|${lastName}|${email}|${mobile}|${password}`;

      const registrationpayload = {
        first_name: firstName,
        last_name: lastName,
        email,
        mobile,
        password
      };
      // Encrypt the concatenated data
      const encryptedData = await aesEncrypt(JSON.stringify(registrationpayload), key);

      // Create payload with the encrypted data
      const payload = {
        payload: encryptedData,
      };

      // Send the encrypted data to the backend
      const response = await axios.post(
        'https://clientconnect.vilvabusiness.com/api/customer/register',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if(response.data.code == 200){
        const decryptedData = await aesDecrypt(response.data.payload, key);
        // console.log(response.data.payload)
        // console.log(decryptedData)
        setResponse(response.data);
        navigate("/customerpannel");
        setError('');
      } else{
        setError(response.data.message);
      }

     
    } catch (err) {
      setError(err.message);
      setResponse(null);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="registration row container mx-auto ">
        <div className="col-md-6 col-12">
          <div className="reg-form">
            <div className="reg-form-all">
              <img src={vbreg} alt="" srcSet="" />
              <h2>Start Your Account Today!</h2>
              <form onSubmit={handleSubmit}>
                {/* Name Field */}
                <div>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    placeholder="Enter Your First Name"
                  />
                </div>

                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    placeholder="Enter Your Last Name"
                  />
                </div>

                {/* Email Field */}
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter Your Email"
                  />
                </div>

                {/* Mobile Field */}
                <div>
                  <label htmlFor="mobile">Mobile</label>
                  <input
                    type="tel"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    required
                    placeholder="Enter Your Mobile Number"
                  />
                </div>

                {/* Password Field */}
                <div>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Enter Your Password"
                  />
                </div>
                <p>
                  * Choose Password Atleast 8 Digits (Upper case, Lower Case,
                  Special Characters)
                </p>
                <button type="submit">Sign Up</button>
              </form>
              <div className="account-creation">
                <h5>
                  Already have an account?{" "}
                  <span>
                    <a href="/signin">Login Here</a>
                  </span>{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mb-3 mb-md-0 ">
          <div className="form-img">
            <div className="form-img-des">
              <h2>Sign Up to connect with Vilva Business</h2>
              <p>
                Join the Vilva Business community today to unlock exclusive
                features, enjoy personalized services, and stay ahead with the
                latest updates. Sign up now and take the first step towards
                effortless business solutions!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Registration;
