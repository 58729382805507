import React, { useState } from 'react';

const Encryptdecrypt = () => {
    const [plainText, setPlainText] = useState('');
    const [encryptedData, setEncryptedData] = useState('');
    const [key, setKey] = useState('');
    const [decryptedData, setDecryptedData] = useState('');
    const [error, setError] = useState('');

    // AES-GCM Encryption Function
    const aesEncrypt = async (plainText, key) => {
        const encoder = new TextEncoder();

        try {
            // Derive a 256-bit key using SHA-256
            const encodedKey = encoder.encode(key);
            const cryptoKey = await crypto.subtle.importKey(
                'raw',
                await crypto.subtle.digest('SHA-256', encodedKey),
                { name: 'AES-GCM' },
                false,
                ['encrypt']
            );

            // Generate random IV (12 bytes)
            const iv = crypto.getRandomValues(new Uint8Array(12));

            // Encrypt the plain text
            const ciphertext = await crypto.subtle.encrypt(
                { name: 'AES-GCM', iv, tagLength: 128 },
                cryptoKey,
                encoder.encode(plainText)
            );

            // Combine IV, ciphertext, and tag into one Uint8Array
            const encryptedData = new Uint8Array([...iv, ...new Uint8Array(ciphertext)]);

            // Return Base64-encoded string
            return btoa(String.fromCharCode(...encryptedData));
        } catch (err) {
            console.error('Encryption failed:', err);
            throw new Error('Encryption failed');
        }
    };

    // AES-GCM Decryption Function
    const aesDecrypt = async (encryptedData, key) => {
        const decoder = new TextDecoder();

        try {
            // Decode the Base64-encoded string
            const data = Uint8Array.from(atob(encryptedData), (char) => char.charCodeAt(0));

            // Extract IV, ciphertext, and tag
            const iv = data.slice(0, 12); // First 12 bytes
            const ciphertext = data.slice(12); // Remaining part

            // Derive a 256-bit key using SHA-256
            const encodedKey = new TextEncoder().encode(key);
            const cryptoKey = await crypto.subtle.importKey(
                'raw',
                await crypto.subtle.digest('SHA-256', encodedKey),
                { name: 'AES-GCM' },
                false,
                ['decrypt']
            );

            // Decrypt the data
            const decrypted = await crypto.subtle.decrypt(
                { name: 'AES-GCM', iv, tagLength: 128 },
                cryptoKey,
                ciphertext
            );

            return decoder.decode(decrypted);
        } catch (err) {
            console.error('Decryption failed:', err);
            throw new Error('Decryption failed');
        }
    };

    // Handle Encryption
    const handleEncrypt = async () => {
        try {
            const result = await aesEncrypt(plainText, key);
            setEncryptedData(result);
            setError('');
        } catch (err) {
            setEncryptedData('');
            setError(err.message);
        }
    };

    // Handle Decryption
    const handleDecrypt = async () => {
        try {
            const result = await aesDecrypt(encryptedData, key);
            setDecryptedData(result);
            setError('');
        } catch (err) {
            setDecryptedData('');
            setError(err.message);
        }
    };

    return (
        <div style={{ padding: '20px', fontFamily: 'Arial' }}>
            <h2>AES-GCM Encryption & Decryption</h2>
            
            {/* Encryption Section */}
            <div>
                <h3>Encrypt Data</h3>
                <div>
                    <label>Key:</label>
                    <br />
                    <input
                        type="text"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                    />
                </div>
                <div>
                    <label>Plain Text:</label>
                    <br />
                    <textarea
                        value={plainText}
                        onChange={(e) => setPlainText(e.target.value)}
                        rows="4"
                        cols="50"
                    />
                </div>
                <button onClick={handleEncrypt} style={{ marginTop: '10px' }}>
                    Encrypt
                </button>
                {encryptedData && (
                    <div>
                        <h4>Encrypted Data:</h4>
                        <p>{encryptedData}</p>
                    </div>
                )}
            </div>

            <hr />

            {/* Decryption Section */}
            <div>
                <h3>Decrypt Data</h3>
                <div>
                    <label>Encrypted Data:</label>
                    <br />
                    <textarea
                        value={encryptedData}
                        onChange={(e) => setEncryptedData(e.target.value)}
                        rows="4"
                        cols="50"
                    />
                </div>
                <div>
                    <label>Key:</label>
                    <br />
                    <input
                        type="text"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                    />
                </div>
                <button onClick={handleDecrypt} style={{ marginTop: '10px' }}>
                    Decrypt
                </button>
                {decryptedData && (
                    <div>
                        <h4>Decrypted Data:</h4>
                        <p>{decryptedData}</p>
                    </div>
                )}
                {error && (
                    <div style={{ color: 'red' }}>
                        <h4>Error:</h4>
                        <p>{error}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Encryptdecrypt;
