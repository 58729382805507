import React, { useEffect, useState } from "react";
import proddash from "../images/proddash.svg";
import "./dashboard.css";
import axios from "axios";
import SvgIcons from "./SvgIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const Activesubscription = () => {
  const [key, setKey] = useState(
    "waSs57FdgthqfzVkv3RMrpTjBcYGKJJRQWtNxhmbS2EPpUjwy8"
  );
  const [data, setData] = useState([]);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  
  const togglePaymentPopup = () => {
    setShowPaymentPopup(!showPaymentPopup);
  }
  const [showReportsPopup, setShowReportsPopup] = useState(false);
  
  const toggleReportsPopup = () => {
    setShowReportsPopup(!showReportsPopup);
  }

  // AES-GCM Encryption Function
  const encryptData = async (plainText, key) => {

    const encoder = new TextEncoder();

    try {
      const encodedKey = encoder.encode(key);
      const cryptoKey = await crypto.subtle.importKey(
        "raw",
        await crypto.subtle.digest("SHA-256", encodedKey),
        { name: "AES-GCM" },
        false,
        ["encrypt"]
      );

      const iv = crypto.getRandomValues(new Uint8Array(12));

      const ciphertext = await crypto.subtle.encrypt(
        { name: "AES-GCM", iv, tagLength: 128 },
        cryptoKey,
        encoder.encode(plainText)
      );

      const encryptedData = new Uint8Array([
        ...iv,
        ...new Uint8Array(ciphertext),
      ]);
      return btoa(String.fromCharCode(...encryptedData));
    } catch (err) {
      console.error("Encryption failed:", err);
      throw new Error("Encryption failed");
    }
  };

  // AES-GCM Decryption Function
  const decryptData = async (encryptedData, key) => {
    const decoder = new TextDecoder();

    try {
      // Decode the Base64-encoded string
      const data = Uint8Array.from(atob(encryptedData), (char) =>
        char.charCodeAt(0)
      );

      // Extract IV, ciphertext, and tag
      const iv = data.slice(0, 12); // First 12 bytes
      const ciphertext = data.slice(12); // Remaining part

      // Derive a 256-bit key using SHA-256
      const encodedKey = new TextEncoder().encode(key);
      const cryptoKey = await crypto.subtle.importKey(
        "raw",
        await crypto.subtle.digest("SHA-256", encodedKey),
        { name: "AES-GCM" },
        false,
        ["decrypt"]
      );

      // Decrypt the data
      const decrypted = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv, tagLength: 128 },
        cryptoKey,
        ciphertext
      );

      return decoder.decode(decrypted);
    } catch (err) {
      console.error("Decryption failed:", err);
      throw new Error("Decryption failed");
    }
  };



  useEffect(() => {
    const fetchData = async () => {

      const token = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      // console.log("userId token:", token);
      // console.log("userId:", userId);

      const encryptedUserId = await encryptData(JSON.stringify({"user_id": userId}), key);
      // console.log("userId token:", encryptedUserId);

      try {
        if (!token) {
          throw new Error("No token found.");
        }
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        const payload = JSON.stringify({
          payload:encryptedUserId,
        });

        const response = await axios.post(
          "https://clientconnect.vilvabusiness.com/api/subscription",
          payload,
          { headers }
        );

        // // console.log(response.data.payload);
        const decryptresdata = await decryptData(response.data.payload, key);
        const parsedData = JSON.parse(decryptresdata);
        // // console.log("parsedData", parsedData)
        setData(parsedData.data);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDownload = async () => {
    try {
      const response = await fetch(
        "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/UPI-QR20-11-202410-46-20.pdf"
      );
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "vilva networks"; // Set the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href); // Cleanup
    } catch (error) {
      console.error("Failed to download the image:", error);
    }
  };

  return (
    <div>
      <div className="route_navigation_all pe-4">
        <div className="route_navigation">
          <p>
            <span>
              Dashboard{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="#999999"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            Active Subscriptions
          </p>
        </div>
        <div className="route_filters_all">
          <div className="route_filters">
            <p>Filter</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M2.5 5H17.5"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.83337 10H14.1667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.33337 15H11.6667"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="route_filters">
            <p>This Month</p>
            <div className="router_icons">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="black"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="jaga">
        <div className="active_sub_all">
          <div className="active_img_all">
            <div className="active_img">
              <img src={proddash} alt="img" />
            </div>
            <div className="active_img_status">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle opacity="0.2" cx="8" cy="8" r="8" fill="#0DC410" />
                  <circle
                    cx="8.00008"
                    cy="7.99984"
                    r="5.33333"
                    fill="#0DC410"
                  />
                </svg>
                Active Plan
              </p>
            </div>
          </div>
          <div className="sub_des">
            <h3>Ecom Saas</h3>
            <p>
              Streamline your online business with our all-in-one eCommerce SaaS
              solution.
            </p>
          </div>
          <hr style={{ margin: "0", padding: "6px" }} />
          <div className="premium_monthly">
            <p>Premium Plan (Monthly)</p>
            <h6>Valid 5 Days - 24.12.2024</h6>
          </div>

          <div className="view_prod_plan_all">
            <a href="#">View Product Details</a>
            <a href="#">View Plan</a>
          </div>
        </div>
        <div className="active_sub_all">
          <div className="active_img_all">
            <div className="active_img">
              <img src={proddash} alt="img" />
            </div>
            <div className="active_img_status">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle opacity="0.2" cx="8" cy="8" r="8" fill="#0DC410" />
                  <circle
                    cx="8.00008"
                    cy="7.99984"
                    r="5.33333"
                    fill="#0DC410"
                  />
                </svg>
                Active Plan
              </p>
            </div>
          </div>
          <div className="sub_des">
            <h3>Ecom Saas</h3>
            <p>
              Streamline your online business with our all-in-one eCommerce SaaS
              solution.
            </p>
          </div>
          <hr style={{ margin: "0", padding: "6px" }} />
          <div className="premium_monthly">
            <p>Premium Plan (Monthly)</p>
            <h6>Valid 5 Days - 24.12.2024</h6>
          </div>

          <div className="view_prod_plan_all">
            <a href="#">View Product Details</a>
            <a href="#">View Plan</a>
          </div>
        </div>
      </div>
      <div className="invoice_table pt-3">
        <div className="table-container">
          <table className="data-table">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Product</th>
                <th>Plan</th>
                <th>	Start Date</th>
                <th>End Date</th>
                <th> Charges	 </th>
                <th>	Platform Charge </th>
                <th>To Be Paid</th>
                <th>	Status	</th>
                <th>	Invoice	</th>
                <th>Payments</th>
                <th>Reports</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(data && data.length > 0) ? (
                data.map((payment, index) => (
                  <tr>
                    <td>{index + 1} </td>
                    <td>{payment.product_plan.name}</td>
                    <td>{payment.product_plan.plan_name}<br />
                      ₹ {payment.product_plan.plan_price} /- </td>
                    <td>{new Date(payment.starts_at).toLocaleString()}</td>
                    <td>{new Date(payment.ends_at).toLocaleString()}</td>
                    <td style={{whiteSpace:'nowrap'}}> ₹ {payment.charges}</td>
                    <td style={{whiteSpace:'nowrap'}}> ₹ {payment.platform_charge}</td>
                    <td style={{whiteSpace:'nowrap'}}> ₹ {payment.payments[0].to_be_paid}</td>
                    <td> <p
                      style={{
                        margin:'0',
                        borderRadius: '4px',
                        textAlign: 'center',
                        fontSize: '12px',
                        fontFamily: "Neue Haas Unica",
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '10px',
                        padding: '8px 8px', // Optional for some padding
                        background: payment.status === 'success'
                          ? '#D4EAD3'
                          : payment.status === 'pending'
                            ? '#FFF9C4' // Light yellow for pending
                            : '#FFEBEE', // Light red for failed
                        color: payment.status === 'success'
                          ? '#16C833'
                          : payment.status === 'pending'
                            ? '#df9f00'
                            : '#E57373', // Red text for failed
                        textTransform: 'capitalize',
                      }} > {payment.status}</p></td>



                    <td>{payment.product_plan.name} </td>

                    <td>
                      <div className="view_btn_dashboard">
                        <button type="button" onClick={togglePaymentPopup} className="">
                          <SvgIcons.payment style={{ color: "black" }} /> View
                        </button>
                      </div>

                      {showPaymentPopup && (
                        <div className="popup_overlay">
                          <div className="popup_content payment_detail_con">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6>Payment Details</h6>
                              <button className="close_btn" onClick={togglePaymentPopup}>
                                x
                              </button>
                            </div>
                            <hr />
                            <div className="qr_section d-flex flex-column justify-content-center align-items-center">
                              <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/icons/vilvanetworksQR.png" width={"250px"} alt="" />
                              {/* <button onClick={handleDownload} className="qr_btn my-3">
                                <FontAwesomeIcon icon={faDownload} />   Download QR Code
                              </button> */}
                            </div>
                            <table>
                              <thead>
                                <tr>
                                  <th>S.NO</th>
                                  <th>ID</th>
                                  <th>Amount</th>
                                  <th>UTR</th>
                                  <th>Status</th>
                                  <th>Remarks</th>
                                  <th>Created</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data && data.length > 0 ? (
                                  data.map((payment, index) => {
                                    // Extract the first payment entry (if it exists) for easier access
                                    const paymentDetails = payment.payments[0] || {}; // Fallback to an empty object if not present

                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{paymentDetails.id}</td>
                                        <td>   {payment.charges}</td>
                                        <td>{paymentDetails.utr || "N/A"}</td>
                                        <td>
                                          <p
                                            style={{
                                              margin:'0',
                                              borderRadius: '4px',
                                              textAlign: 'center',
                                              fontSize: '15px',
                                              fontFamily: 'Neue Haas Unica',
                                              fontStyle: 'normal',
                                              fontWeight: '400',
                                              lineHeight: '10px',
                                              padding: '8px 8px',
                                              background:
                                                paymentDetails.status === 'success'
                                                  ? '#D4EAD3'
                                                  : paymentDetails.status === 'pending'
                                                    ? '#FFF9C4'
                                                    : '#FFEBEE',
                                              color:
                                                paymentDetails.status === 'success'
                                                  ? '#16C833'
                                                  : paymentDetails.status === 'pending'
                                                    ? '#df9f00'
                                                    : '#E57373',
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {paymentDetails.status || "N/A"}
                                          </p>
                                        </td>

                                        <td>{paymentDetails.remarks || "N/A"}</td>
                                        <td>{new Date(paymentDetails.created_at).toLocaleString() || "N/A"}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="12" style={{ textAlign: 'center' }}>
                                     <SvgIcons.loading/> Loding...
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          



                            <button className="close_text_btn" onClick={togglePaymentPopup}>
                              Close
                            </button>

                          </div>
                        </div>
                      )}


                    </td>
                    <td> 
                    <div className="view_btn_dashboard">
                        <button type="button" onClick={toggleReportsPopup} className="">
                          <SvgIcons.payment style={{ color: "black" }} /> View
                        </button>
                      </div>

                      {showReportsPopup && (
                        <div className="popup_overlay">
                          <div className="popup_content payment_detail_con">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6>Reports Details</h6>
                              <button className="close_btn" onClick={toggleReportsPopup}>
                                x
                              </button>
                            </div>
                            <hr />
                           
                    <table>
  <thead>
    <tr>
      <th>S.NO</th>
      <th>Payment Method</th>
      <th>Count</th>
      <th>Value</th>
    </tr>
  </thead>
  <tbody>
    {data && data.length > 0 ? (
      data.map((payment, index) => {
        const reports = payment.reports ? JSON.parse(payment.reports) : null; // Parse reports

        const totalReports = reports
          ? Object.entries(reports).reduce(
              (totals, [key, value]) => {
                if (!["total_count", "total_value"].includes(key)) {
                  totals.count += value.count || 0;
                  totals.value += parseFloat(value.value || 0);
                }
                return totals;
              },
              { count: 0, value: 0 }
            )
          : { count: 0, value: 0 };

        return (
          <React.Fragment key={index}>
            {/* Total Summary Row */}
            <tr>
              <td>{index + 1}</td>
              <td>Total Count</td>
              <td>{totalReports.count}</td>
              <td>{totalReports.value.toFixed(2)}</td>
            </tr>

            {/* Payment Method Rows */}
            {reports
              ? Object.keys(reports).map((gateway, idx) =>
                  !["total_count", "total_value"].includes(gateway) ? (
                    <tr key={`${index}-${idx}`}>
                      <td></td>
                      <td>Pay using {gateway}</td>
                      <td>{reports[gateway].count || 0}</td>
                      <td>{reports[gateway].value || "N/A"}</td>
                    </tr>
                  ) : null
                )
              : (
                <tr>
                  <td colSpan={4}>No Reports Available</td>
                </tr>
              )}
          </React.Fragment>
        );
      })
    ) : (
      <tr>
        <td colSpan="4" style={{ textAlign: "center" }}>
        <SvgIcons.loading/> Loding...
        </td>
      </tr>
    )}
  </tbody>
</table>

                          



                            <button className="close_text_btn" onClick={toggleReportsPopup}>
                              Close
                            </button>

                          </div>
                        </div>
                      )}


                     </td>
                    {/* <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.99984 10.8333C10.4601 10.8333 10.8332 10.4602 10.8332 10C10.8332 9.53976 10.4601 9.16667 9.99984 9.16667C9.5396 9.16667 9.1665 9.53976 9.1665 10C9.1665 10.4602 9.5396 10.8333 9.99984 10.8333Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99984 5C10.4601 5 10.8332 4.6269 10.8332 4.16667C10.8332 3.70643 10.4601 3.33333 9.99984 3.33333C9.5396 3.33333 9.1665 3.70643 9.1665 4.16667C9.1665 4.6269 9.5396 5 9.99984 5Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8333C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8333C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
                          stroke="#666666"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </td> */}

                  </tr>))
              ) : (
                <p className="loading_loder">  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M5 1.66667L2.5 5V16.6667C2.5 17.1087 2.67559 17.5326 2.98816 17.8452C3.30072 18.1577 3.72464 18.3333 4.16667 18.3333H15.8333C16.2754 18.3333 16.6993 18.1577 17.0118 17.8452C17.3244 17.5326 17.5 17.1087 17.5 16.6667V5L15 1.66667H5Z"
                    stroke="black"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.5 5H17.5"
                    stroke="black"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.3334 8.33333C13.3334 9.21739 12.9822 10.0652 12.357 10.6904C11.7319 11.3155 10.8841 11.6667 10 11.6667C9.11597 11.6667 8.26812 11.3155 7.643 10.6904C7.01788 10.0652 6.66669 9.21739 6.66669 8.33333"
                    stroke="black"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg> Loading ...</p>
              )}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};


export default Activesubscription;
