import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Oracleplsql = () => {
  return (
    <div>
          <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="Oracle PL/SQL, web services, database solutions, Oracle development, PL/SQL support"
        />
        <meta
          name="description"
          content="Discover Oracle PL/SQL Web Services by Vilva Business, providing robust database solutions and expert support to enhance your Oracle applications."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>Oracle PL/SQL Web Services - Vilva Business</title>
      </Helmet>
        <Navbar/>
     

<div className="prod-des container mt-5">
    <div className="prod-head">
        <h5 style={{ color: '#4a12f0' }}>Enhance complex query performance.</h5>
        <h2 style={{ color: '#001e60' }}>Optimize data processing efficiency.</h2>
    </div>
    <div className="benifits mx-auto">
        <div className="col-md-3 col-12 ben-ai">
            <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png" alt="" />
            <h3 style={{ color: '#001e60' }}>Enhanced Performance</h3>
            <p>Oracle PL/SQL services are optimized for executing complex queries and processing large volumes of data efficiently, ensuring faster response times.</p>
        </div>
        <div className="col-md-3 col-12 ben-ai">
            <img src="./images/Untitled (1).png" alt="" />
            <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png" alt="" />
            <p>They offer comprehensive security features, including data encryption, access controls, and auditing capabilities, to safeguard sensitive information.</p>
        </div>
        <div className="col-md-3 col-12 ben-ai">
            <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png" alt="" />
            <h3 style={{ color: '#001e60' }}>Seamless Integration</h3>
            <p>Oracle PL/SQL services integrate smoothly with other Oracle products and third-party applications, facilitating a cohesive and flexible IT infrastructure.</p>
        </div>
    </div>
</div>

<div className="usecase-all">
    <div className="usecase-head">
        <h5 style={{ color: '#4a12f0' }}>Use Cases</h5>
        <h2 style={{ color: '#001e60' }}>Manage Every Endpoint in One Place</h2>
        <h5 style={{ color: '#28334a' }}>Efficiently manage large datasets.</h5>
    </div>
    <div className="row container pt-5 mx-auto">
        <div className="col-md-6 col-12">
            <div className="useimg">
                <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="" style={{ height: '100%', width: '90%' }} />
            </div>
        </div>
        <div className="col-12 col-md-6 mt-5">
            <div className="row" id="row1">
                <h3 style={{ color: "#040e56" }}>Bring Your Own Device (BYOD) Programs</h3>
                <p>Improve employee productivity while promoting full privacy and data security. Enable frictionless access to work resources no matter what enrollment type or device employees use.</p>
            </div>
            <div className="row" id="row2">
                <h3 style={{ color: "#040e56" }}>Data Warehousing</h3>
                <p>Efficiently managing and querying large datasets for business intelligence and reporting.</p>
            </div>
            <div className="row" id="row3">
                <h3 style={{ color: "#040e56" }}>Transaction Processing</h3>
                <p>Handling high volumes of transactions with speed and accuracy in financial systems.</p>
            </div>
            <div className="row" id="row4">
                <h3 style={{ color: "#040e56" }}>Batch Processing</h3>
                <p>Automating routine data processing tasks such as ETL (Extract, Transform, Load) operations.</p>
            </div>
            <div className="row" id="row4">
                <h3 style={{ color: "#040e56" }}>Data Integration</h3>
                <p>Seamlessly integrating data from various sources into a unified database for consistent access.</p>
            </div>
            <div className="row" id="row4">
                <h3 style={{ color: "#040e56" }}>Application Development</h3>
                <p>Building robust and scalable database-driven applications with complex business logic.</p>
            </div>
        </div>
    </div>
</div>

<div className="ready mt-5 pb-5 container">
    <div className="ready-des">
        <h3 style={{ color: '#001e60' }}>Ready to Get Started?</h3>
        <h5 style={{ color: '#28334a' }}>Experience device management, made easy!</h5>
        <div className="contact-us">
            <button type="button">Contact Us <i className="fa-solid fa-arrow-right"></i></button>
        </div>
    </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Oracleplsql
