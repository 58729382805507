import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Pagesblog from "../blog/pagesblog/Pagesblog";
import { Helmet } from "react-helmet";

const Apprestaurant = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="app for restaurants, restaurant management app, food service app, dining app"
        />
        <meta
          name="description"
          content="Discover our restaurant management app tailored to streamline operations and enhance customer satisfaction. Explore how our app can elevate your dining experience."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>App for Restaurants - Vilva Business</title>
      </Helmet>
      <Navbar />
      

      <div className="prod-des container mt-5">
        <div className="prod-head">
          <h5 style={{ color: "#4a12f0" }}>
            Streamlined ordering and efficiency
          </h5>
          <h2 style={{ color: "#001e60" }}>
            Enjoy effortless ordering, increased engagement, and operational
            efficiency
          </h2>
        </div>
        <div className="benifits mx-auto">
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Streamlined Ordering Process</h3>
            <p>
              An app allows customers to place orders quickly and easily,
              reducing wait times and increasing order accuracy.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Improved Customer Engagement</h3>
            <p>
              Features such as loyalty programs, push notifications, and
              personalized offers help retain customers and encourage repeat
              business.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Operational Efficiency</h3>
            <p>
              Automating reservations, orders, and payments reduces manual work,
              enhances staff productivity, and improves overall restaurant
              management.
            </p>
          </div>
        </div>
      </div>

      <div className="usecase-all">
        <div className="usecase-head">
          <h5 style={{ color: "#4a12f0" }}>Use Cases</h5>
          <h2 style={{ color: "#001e60" }}>
            Manage Every Endpoint in One Place
          </h2>
          <h5 style={{ color: "#28334a" }}>
            Simplify table reservations seamlessly.
          </h5>
        </div>
        <div className="row container pt-5 mx-auto">
          <div className="col-md-6 col-12">
            <div className="useimg">
              <img
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg"
                alt=""
                style={{ height: "85%", width: "90%" }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row" id="row1">
              <h3  style={{ color: "#040e56" }}>Online Ordering</h3>
              <p>
                Allowing customers to place and customize orders from their
                phones, including delivery and pickup options.
              </p>
            </div>
            <div className="row" id="row2">
              <h3  style={{ color: "#040e56" }}>Table Reservations</h3>
              <p>
                Enabling users to book tables in advance and manage
                reservations, reducing wait times and improving customer
                experience.
              </p>
            </div>
            <div className="row" id="row3">
              <h3  style={{ color: "#040e56" }}>Menu Management</h3>
              <p>
                Providing an up-to-date digital menu with item descriptions,
                prices, and special offers, easily updated by restaurant staff.
              </p>
            </div>
            <div className="row" id="row4">
              <h3  style={{ color: "#040e56" }}>Loyalty Programs</h3>
              <p>
                Implementing rewards and loyalty programs to incentivize repeat
                customers with points, discounts, and special promotions.
              </p>
            </div>
            <div className="row" id="row5">
              <h3  style={{ color: "#040e56" }}>Feedback and Reviews</h3>
              <p>
                Collecting customer feedback and reviews directly through the
                app to improve service and address any issues promptly.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ready mt-5 pb-5 container">
        <div className="ready-des">
          <h3 style={{ color: "#001e60" }}>
            Create, Grow, Thrive – Begin with Vilva Business!
          </h3>
          <h5 style={{ color: "#28334a" }}>
            Experience device management, made easy!
          </h5>
          <div className="contact-us">
            <button type="button">
              <a href="/contact" target="_blank" style={{ color: "white" }}>
                Set Your Plan in Motion
              </a>
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <Pagesblog />

      <Footer />
    </div>
  );
};

export default Apprestaurant;
