import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Searchbar from "./Searchbar";
import profile from "../images/profile.png";
import axios from "axios";
import { toast } from "react-toastify";


const Myaccount = () => {

    const [key, setKey] = useState(
        "waSs57FdgthqfzVkv3RMrpTjBcYGKJJRQWtNxhmbS2EPpUjwy8"
    );
    const [data, setData] = useState([]);

    const [isEditing, setIsEditing] = useState(false); // Controls input editability
    const [inputValue, setInputValue] = useState(""); // Holds the input value


    const [isToastDisplayed, setIsToastDisplayed] = useState(false);

    const [loader, setLoader] = useState(false);

    const aesEncrypt = async (plainText, key) => {
        const encoder = new TextEncoder();
        try {
            // Derive a 256-bit key using SHA-256
            const encodedKey = encoder.encode(key);
            const cryptoKey = await crypto.subtle.importKey(
                'raw',
                await crypto.subtle.digest('SHA-256', encodedKey),
                { name: 'AES-GCM' },
                false,
                ['encrypt']
            );

            // Generate random IV (12 bytes)
            const iv = crypto.getRandomValues(new Uint8Array(12));

            // Encrypt the plain text
            const ciphertext = await crypto.subtle.encrypt(
                { name: 'AES-GCM', iv, tagLength: 128 },
                cryptoKey,
                encoder.encode(plainText)
            );

            // Combine IV, ciphertext, and tag into one Uint8Array
            const encryptedData = new Uint8Array([...iv, ...new Uint8Array(ciphertext)]);

            // Return Base64-encoded string
            return btoa(String.fromCharCode(...encryptedData));
        } catch (err) {
            console.error('Encryption failed:', err);
            throw new Error('Encryption failed');
        }
    };

    // AES-GCM Decryption Function
    const aesDecrypt = async (encryptedData, key) => {
        const decoder = new TextDecoder();

        try {
            // Decode the Base64-encoded string
            const data = Uint8Array.from(atob(encryptedData), (char) =>
                char.charCodeAt(0)
            );

            // Extract IV, ciphertext, and tag
            const iv = data.slice(0, 12); // First 12 bytes
            const ciphertext = data.slice(12); // Remaining part

            // Derive a 256-bit key using SHA-256
            const encodedKey = new TextEncoder().encode(key);
            const cryptoKey = await crypto.subtle.importKey(
                "raw",
                await crypto.subtle.digest("SHA-256", encodedKey),
                { name: "AES-GCM" },
                false,
                ["decrypt"]
            );

            // Decrypt the data
            const decrypted = await crypto.subtle.decrypt(
                { name: "AES-GCM", iv, tagLength: 128 },
                cryptoKey,
                ciphertext
            );

            return decoder.decode(decrypted);
        } catch (err) {
            console.error("Decryption failed:", err);
            throw new Error("Decryption failed");
        }
    };

    // AES-GCM Encryption Function
    const encryptData = async (plainText, key) => {

        const encoder = new TextEncoder();

        try {
            const encodedKey = encoder.encode(key);
            const cryptoKey = await crypto.subtle.importKey(
                "raw",
                await crypto.subtle.digest("SHA-256", encodedKey),
                { name: "AES-GCM" },
                false,
                ["encrypt"]
            );

            const iv = crypto.getRandomValues(new Uint8Array(12));

            const ciphertext = await crypto.subtle.encrypt(
                { name: "AES-GCM", iv, tagLength: 128 },
                cryptoKey,
                encoder.encode(plainText)
            );

            const encryptedData = new Uint8Array([
                ...iv,
                ...new Uint8Array(ciphertext),
            ]);
            return btoa(String.fromCharCode(...encryptedData));
        } catch (err) {
            console.error("Encryption failed:", err);
            throw new Error("Encryption failed");
        }
    };

    // AES-GCM Decryption Function
    const decryptData = async (encryptedData, key) => {
        const decoder = new TextDecoder();

        try {
            // Decode the Base64-encoded string
            const data = Uint8Array.from(atob(encryptedData), (char) =>
                char.charCodeAt(0)
            );

            // Extract IV, ciphertext, and tag
            const iv = data.slice(0, 12); // First 12 bytes
            const ciphertext = data.slice(12); // Remaining part

            // Derive a 256-bit key using SHA-256
            const encodedKey = new TextEncoder().encode(key);
            const cryptoKey = await crypto.subtle.importKey(
                "raw",
                await crypto.subtle.digest("SHA-256", encodedKey),
                { name: "AES-GCM" },
                false,
                ["decrypt"]
            );

            // Decrypt the data
            const decrypted = await crypto.subtle.decrypt(
                { name: "AES-GCM", iv, tagLength: 128 },
                cryptoKey,
                ciphertext
            );

            return decoder.decode(decrypted);
        } catch (err) {
            console.error("Decryption failed:", err);
            throw new Error("Decryption failed");
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("authToken"); // Fetch the auth token
            const userId = localStorage.getItem("userId");
            const encryptedUserId = await encryptData(JSON.stringify({ "user_id": userId }), key);
            // console.log("userId token:", encryptedUserId);

            // console.log("User ID token:", token);

            if (!token) {
                console.error("No token found.");
                return;
            }

            try {
                if (!token) {
                    throw new Error("No token found.");
                }
                const headers = {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                };

                const payload = JSON.stringify({
                    payload: encryptedUserId,
                });

                const response = await axios.post(
                    "https://clientconnect.vilvabusiness.com/api/my-account",
                    payload,
                    { headers }
                );

                // console.log("Response data:", response.data);

                const decryptresdata = await decryptData(response.data.payload, key);
                const parsedData = JSON.parse(decryptresdata);

                // Set the parsed data into the state
                setData(parsedData.data);
                // console.log("Parsed Data:", parsedData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    const handleChangeClick = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const userId = localStorage.getItem("userId");

            if (!token) {
                console.error("No token found. Please log in.");
                return;
            }

            if (!userId) {
                console.error("No user ID found. Unable to proceed.");
                return;
            }

            // Encrypt the user ID
            const encryptedUserId = await encryptData(
                JSON.stringify({ user_id: userId }),
                key
            );
            // console.log("Encrypted User ID:", encryptedUserId);

            // Set up headers
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            };

            // Prepare payload
            const payload = JSON.stringify({
                payload: encryptedUserId,
            });

            // Send POST request to the API
            const response = await axios.post(
                "https://clientconnect.vilvabusiness.com/api/edit/my-account",
                payload,
                { headers }
            );

            // console.log("Response data:", response.data);

            // Decrypt the response payload
            const decryptedData = await decryptData(response.data.payload, key);
            const parsedData = JSON.parse(decryptedData);

            // Set data into state
            setData(parsedData.data);
            // console.log("Parsed Data:", parsedData);
            setIsEditing(false); // Disable editing

        } catch (error) {
            // Handle errors
            if (error.response) {
                console.error("API Error:", error.response.data);
            } else {
                console.error("Network or other error:", error.message);
            }
        }
    };


    const handleEditClick = () => {
        setIsEditing(true); // Enable editing
    };

    const handleChangeClick2 = () => {
        setIsEditing(false); // Disable editing
        console.log("Input value:", inputValue); // Optional: Log or handle the updated input value
    };

    useEffect(() => {
        if (data.first_name) {
            setInputValue(data.first_name);
        }
    }, [data]);


    const [isChangeMode, setIsChangeMode] = useState(false); 
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
  
    const handleSavee = () => {
      console.log("Old Password:", oldPassword);
      console.log("New Password:", newPassword);
      setOldPassword("");
      setNewPassword("");
      setIsChangeMode(false);
    };

    const handleSave = async () => {
        const userId = localStorage.getItem("userId");
        const token = localStorage.getItem("authToken");
      
        if (!token) {
          console.error("No token found.");
          return;
        }
      
        try {
          // Encrypt user ID
          const encryptedUserId = await encryptData(
            JSON.stringify({ user_id: userId }),
            key
          );
      
          // Construct the password payload
          const passwordPayload = {
            current_password: oldPassword, // Assuming 'username' is the old password field
            new_password: newPassword, // Assuming 'password' is the new password field
            payload: encryptedUserId, // Encrypted user ID
          };
      
          // Encrypt the full payload
          const encryptedPayload = {
            payload: await encryptData(JSON.stringify(passwordPayload), key),
          };
      
          // Set up headers
          const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
      
          // Make the API call
          const result = await axios.post(
            "https://clientconnect.vilvabusiness.com/api/change-password",
            encryptedPayload,
            { headers }
          );
          if (!isToastDisplayed) {
            toast.success("Password Updated successfuly!", { autoClose: 2500 });
            setIsToastDisplayed(true);
          }
          // Log success response
          console.log("Password change successful:", result.data);
      
          // Reset inputs and exit change mode
          setOldPassword("");
          setNewPassword("");
          setIsChangeMode(false);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.errors) {
              const errorMessages = Object.values(err.response.data.errors)
                .flat()
                .join("\n"); // Combine all error messages into one string
              console.error("Error changing password:", errorMessages);
        
              if (isToastDisplayed) {
                toast.error(errorMessages, {
                  autoClose: 2500,
                });
                setIsToastDisplayed(true);
              }
            } else {
                if (isToastDisplayed) {
                    toast.error("The current password is incorrect.", {
                      autoClose: 2500,
                    });
                    setIsToastDisplayed(true);
                  }
              console.error("Unknown error:", err);
            }
        }
      };
      

    return (
        <div>
            <div className="route_navigation_all pe-4">
                <div className="route_navigation">
                    <p style={{ cursor: "pointer" }}>
                        <span>
                            Dashboard{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M7.5 15L12.5 10L7.5 5"
                                    stroke="#999999"
                                    strokeWidth="1.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>

                        Edit Profile
                    </p>
                </div>
                {/* <div className="route_filters_all">
            <div className="route_filters">
                <p>Filter</p>
                <div className="router_icons">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                    d="M2.5 5H17.5"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    />
                    <path
                    d="M5.83337 10H14.1667"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    />
                    <path
                    d="M8.33337 15H11.6667"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    />
                </svg>
                </div>
            </div>
            <div className="route_filters">
                <p>This Month</p>
                <div className="router_icons">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                    d="M5 7.5L10 12.5L15 7.5"
                    stroke="black"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    />
                </svg>
                </div>
            </div>
            </div> */}
            </div>

            <div className="myprofile_account_container">
                <div className="myprofile_account_mini  ">
                    <img width={'80px'} src={profile} alt="" className="mx-auto w-full" style={{ marginBottom: '16px' }} srcSet="" />
                    <div className="profile_btn">
                        <button>Change Photo</button>
                        <button>Romove Photo</button>
                    </div>
                </div>

                <div className="form_con_my_pro" style={{ width: '100%' }}>
                    <label htmlFor="first_name">First Name</label>
                    <div className="my_pro_con">
                        <input
                            id="first_name"
                            type="text"
                            value={inputValue}
                            disabled={!isEditing}
                            onChange={(e) => setInputValue(e.target.value)}
                        />

                    </div>
                </div>
                <div className="form_con_my_pro" style={{ width: '100%' }}>
                    <label htmlFor="first_name">Last Name</label>
                    <div className="my_pro_con">
                        <input
                            id="first_name"
                            type="text"
                            value={inputValue}
                            disabled={!isEditing} // Input is editable only if isEditing is true
                            onChange={(e) => setInputValue(e.target.value)} // Update input value on change
                        />

                    </div>
                </div>

                <div className="form_con_my_pro" style={{ alignSelf: "baseline" }}>
                    <label htmlFor="">Mobile</label>
                    <p>{data?.mobile}</p>
                </div>
                <div className="form_con_my_pro" style={{ alignSelf: "baseline" }}>
                    <label htmlFor="">Email</label>
                    <p>{data?.email}</p>
                </div>

               <div className="form_con_my_pro">
      <label htmlFor="">Password</label>
      {isChangeMode ? (<>
        <div className="my_pro_con d-flex gap-1">
        <input
            type="text"
            placeholder="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="mb-3"
          />
          

          
        </div>
        <div className="my_pro_con d-flex gap-1">
        <input
            type="text"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          
          <button onClick={handleSave}>Save</button>
          <button onClick={() => setIsChangeMode(false)}>Cancel</button>

          
        </div>
        </>) : (
        <div className="my_pro_con d-flex gap-1">
          <input disabled value={"123456789"} type="password" />
          <button onClick={() => setIsChangeMode(true)}>Change</button>
        </div>
      )}
    </div>

                <div className="form_con_my_pro mt-4" style={{ width: '100%' }}>
                    {!isEditing ? (
                        <button
                            style={{ width: "100%" }}
                            onClick={handleEditClick}
                        >
                            Edit
                        </button>
                    ) : (
                        <button
                            style={{ width: "100%" }}
                            onClick={handleChangeClick}
                        >
                            Change
                        </button>
                    )}

                </div>
            </div>
        </div>
    );
};

export default Myaccount;
