import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Homepage from "./pages/Homepage.jsx";
import Ecomsaas from "./pages/Ecomsaas.jsx";
import Aadharpan from "./pages/otherpages/Aadharpan.jsx";
import About from "./pages/otherpages/About.jsx";
import Accounting from "./pages/otherpages/Accounting.jsx";
import Aiml from "./pages/otherpages/Aiml.jsx";
import Androidsolution from "./pages/otherpages/Androidsolution.jsx";
import Apivoicecalling from "./pages/otherpages/Apivoicecalling.jsx";
import Appbotique from "./pages/otherpages/Appbotique.jsx";
import Appecom from "./pages/otherpages/Appecom.jsx";
import Appfruits from "./pages/otherpages/Appfruits.jsx";
import Appgroceries from "./pages/otherpages/Appgroceries.jsx";
import Apphotels from "./pages/otherpages/Apphotels.jsx";
import Appinstall from "./pages/otherpages/Appinstall.jsx";
import Apprestaurant from "./pages/otherpages/Apprestaurant.jsx";
import Appshops from "./pages/otherpages/Appshops.jsx";
import Appwholesaler from "./pages/otherpages/Appwholesaler.jsx";
import Bankverification from "./pages/otherpages/Bankverification.jsx";
import Bulkvoice from "./pages/otherpages/Bulkvoice.jsx";
import Bulkwhatsapp from "./pages/otherpages/Bulkwhatsapp.jsx";
import Corporatecard from "./pages/otherpages/Corporatecard.jsx";
import Customvoicecalling from "./pages/otherpages/Customvoicecalling.jsx";
import Cybersecurity from "./pages/otherpages/Cybersecurity.jsx";
import Emailmarketing from "./pages/otherpages/Emailmarketing.jsx";
import Eventsignup from "./pages/otherpages/Eventsignup.jsx";
import Fbinstamarketing from "./pages/otherpages/Fbinstamarketing.jsx";
import Fintechsolution from "./pages/otherpages/Fintechsolution.jsx";
import Googleutubemarket from "./pages/otherpages/Googleutubemarket.jsx";
import Growsuite from "./pages/otherpages/Growsuite.jsx";
import Gstfilling from "./pages/otherpages/Gstfilling.jsx";
import Gstregistration from "./pages/otherpages/Gstregistration.jsx";
import Iossolution from "./pages/otherpages/Iossolution.jsx";
import Kycverification from "./pages/otherpages/Kycverification.jsx";
import Leadgeneration from "./pages/otherpages/Leadgeneration.jsx";
import Linkedinmarketing from "./pages/otherpages/Linkedinmarketing.jsx";
import Magentosolution from "./pages/otherpages/Magentosolution.jsx";
import Officialwhatsapp from "./pages/otherpages/Officialwhatsapp.jsx";
import Opencart from "./pages/otherpages/Opencart.jsx";
import Oracleplsql from "./pages/otherpages/Oracleplsql.jsx";
import Ourpartner from "./pages/otherpages/Ourpartner.jsx";
import Paymentcollection from "./pages/otherpages/Paymentcollection.jsx";
import Paymentgateway from "./pages/otherpages/Paymentgateway.jsx";
import Privacypolicy from "./pages/otherpages/Privacypolicy.jsx";
import Returnandrefund from "./pages/otherpages/Returnandrefund.jsx";
import Salesconversion from "./pages/otherpages/Salesconversion.jsx";
import Shopify from "./pages/otherpages/Shopify.jsx";
import Smsmarketing from "./pages/otherpages/Smsmarketing.jsx";
import Socialmediamanagement from "./pages/otherpages/Socialmediamanagement.jsx";
import Termsandcondition from "./pages/otherpages/Termsandcondition.jsx";
import Vilvabizsender from "./pages/otherpages/Vilvabizsender.jsx";
import Virtualaccount from "./pages/otherpages/Virtualaccount.jsx";
import Virtualapi from "./pages/otherpages/Virtualapi.jsx";
import Whatsappbuisness from "./pages/otherpages/Whatsappbuisness.jsx";
import Wordpress from "./pages/otherpages/Wordpress.jsx";
import Encryption from "./pages/Encryption.jsx";
import Registration from "./pages/Registration.jsx";
import Welcomeback from "./pages/Welcomeback.jsx";
import Verifyaccount from "./pages/Verifyaccount.jsx";
// import Customerpannel from './pages/customer/Customerpannel.jsx';
import Customerpannel from "./pages/customer/Customerpannel.jsx";
import Blogindex from "./pages/blog/Blogindex.jsx";
import Unlockecom from "./pages/blog/Unlockecom.jsx";
import Privacy from "./pages/Policies/Privacypolicy.jsx";
import Return from "./pages/Policies/Returnrefund.jsx";
// import termsandcondition from "./pages/Policies/Termscondition.jsx";
// import Termsandcondition from './pages/otherpages/Termsandcondition.jsx'
import ContactPage from "./pages/ContactPage.jsx"
import PartnershipPage from './pages/PartnershipPage.jsx'
import TechnologiesSection from './pages/Technologiessection.jsx'
import Products from './pages/Products.jsx'
import Allinone from './pages/blog/Allinoneecom.jsx'
import Vilvavsshopify from './pages/blog/Vilvavsshopify.jsx'
import Vilvavsmagento from './pages/blog/Vilvavsmagento.jsx'
import Magentoholding from './pages/blog/Magentoholding.jsx'
import Woocomerce from './pages/blog/Woocomerce.jsx'
import Metacomerce from './pages/blog/Metacomerce.jsx'
import Gamechanger from './pages/blog/Gamechanger.jsx'
import Meesakar from './pages/blog/Meesakar.jsx'
import Allproducts from './pages/customer/Allproducts.jsx'
import Vilvawapro from './pages/customer/Vilvawapro.jsx'
import  ForgotPasword  from "./pages/ForgotPasword.jsx";
import Myaccount from "./pages/customer/Myaccount.jsx";
import Voicebot from "./pages/customer/Voicebot.jsx";
import Vilvawaba from "./pages/customer/Vilvawaba.jsx";
import Ecom from "./pages/customer/Ecom.jsx";
import Verification from "./pages/customer/Verification.jsx";
import Vilvakart from "./pages/customer/Vilvakart.jsx";
import Activesubscription from "./pages/customer/Activesubscription.jsx";
import History from "./pages/customer/History.jsx";
import Invoice from "./pages/customer/Invoice.jsx";
import Wallet from "./pages/customer/Wallet.jsx";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/blog/unlock-ecom" element={<Unlockecom />}/>
                    <Route path="/contactus" element={<ContactPage />} />
                    <Route path="/allproducts" element={<Vilvawapro />} />

                    
                    <Route path="/products" element={<Products />} />
                    <Route path="/blog/how-meesaikarin-achieved-lakhs-in-sales-using-vilva-ecom-saas" element={<Meesakar />} />

                    <Route path="/blog/vilva-ecom-saas-ecommerce-game-changer" element={<Gamechanger />} />

                    <Route path="/blog/integrate-meta-commerce-manager-ecommerce-vilva-ecom" element={<Metacomerce />} />

                    <Route path="/blog/break-free-woocommerce-limitations-vilva-ecom-saas" element={<Woocomerce />} />

                    <Route path="/technolgies" element={<TechnologiesSection />} />
                    <Route path="/blog/vilva-ecom-saas-vs-shopify-best-ecommerce-solution" element={<Vilvavsshopify />} />
                    <Route path="/blog/vilva-ecom-saas-vs-magento-ultimate-e-commerce-showdown" element={<Vilvavsmagento />} />
                    <Route path="/blog/break-free-magento-limitations-vilva-ecom-saas" element={<Magentoholding />} />



                    <Route path="/blog/ecommerce-domination-vilva-ecom-saas-features" element={<Allinone />} />



          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/termsandcondition" element={<Termsandcondition />} />

          <Route path="/return" element={<Return />} />
          <Route path="/partners" element={<PartnershipPage />} />



          <Route path="/" element={<Homepage />} />
         
          <Route path="/customerpannel" element={<Customerpannel />} />
          <Route path="/blog" element={<Blogindex />} />

          <Route path="/registration" element={<Registration />} />
          <Route path="/signin" element={<Welcomeback />} />

          <Route path="/verifyaccount" element={<Verifyaccount />} />
          <Route path="/enc" element={<Encryption />} />

          <Route path="/ecomsaas" element={<Ecomsaas />} />
          <Route path="/aadharpan" element={<Aadharpan />} />
          <Route path="/about" element={<About />} />
          <Route path="/accounting" element={<Accounting />} />
          <Route path="/aiml" element={<Aiml />} />
          <Route path="/androidsolution" element={<Androidsolution />} />
          <Route path="/apivoicecalling" element={<Apivoicecalling />} />
          <Route path="/appbotique" element={<Appbotique />} />
          <Route path="/appecom" element={<Appecom />} />
          <Route path="/appfruits" element={<Appfruits />} />
          <Route path="/appgroceries" element={<Appgroceries />} />
          <Route path="/apphotels" element={<Apphotels />} />
          <Route path="/appinstall" element={<Appinstall />} />
          <Route path="/apprestaurant" element={<Apprestaurant />} />
          <Route path="/appshops" element={<Appshops />} />
          <Route path="/appwholesaler" element={<Appwholesaler />} />
          <Route path="/bankverification" element={<Bankverification />} />
          <Route path="/bulkvoice" element={<Bulkvoice />} />
          <Route path="/bulkwhatsapp" element={<Bulkwhatsapp />} />
          <Route path="/corporatecard" element={<Corporatecard />} />
          <Route path="/customvoicecalling" element={<Customvoicecalling />} />
          <Route path="/cybersecurity" element={<Cybersecurity />} />
          <Route path="/emailmarketing" element={<Emailmarketing />} />
          <Route path="/eventsignup" element={<Eventsignup />} />
          <Route path="/fbinstamarketing" element={<Fbinstamarketing />} />
          <Route path="/fintechsolution" element={<Fintechsolution />} />
          <Route path="/googleutubemarket" element={<Googleutubemarket />} />
          <Route path="/growsuite" element={<Growsuite />} />
          <Route path="/gstfilling" element={<Gstfilling />} />
          <Route path="/gstregistration" element={<Gstregistration />} />
          <Route path="/iossolution" element={<Iossolution />} />
          <Route path="/kycverification" element={<Kycverification />} />
          <Route path="/leadgeneration" element={<Leadgeneration />} />
          <Route path="/linkedinmarketing" element={<Linkedinmarketing />} />
          <Route path="/magentosolution" element={<Magentosolution />} />
          <Route path="/officialwhatsapp" element={<Officialwhatsapp />} />
          <Route path="/opencart" element={<Opencart />} />
          <Route path="/oracleplsql" element={<Oracleplsql />} />
          <Route path="/ourpartner" element={<Ourpartner />} />
          <Route path="/paymentcollection" element={<Paymentcollection />} />
          <Route path="/paymentgateway" element={<Paymentgateway />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/returnandrefund" element={<Returnandrefund />} />
          <Route path="/salesconversion" element={<Salesconversion />} />
          <Route path="/shopify" element={<Shopify />} />
          <Route path="/smsmarketing" element={<Smsmarketing />} />
          <Route
            path="/socialmediamanagement"
            element={<Socialmediamanagement />}
          />
          <Route path="/termsandcondition" element={<Termsandcondition />} />
          <Route path="/vilvabizsender" element={<Vilvabizsender />} />
          <Route path="/virtualaccount" element={<Virtualaccount />} />
          <Route path="/virtualapi" element={<Virtualapi />} />
          {/* <Route path="/whatsappbuisness" element={<Whatsappbuisness />} /> */}
          <Route path="/wordpress" element={<Wordpress />} />
          <Route path="/forgotpassword" element={<ForgotPasword />} />
          <Route path="/myaccount" element={<Myaccount />} />
        </Routes>
        <Routes>
  <Route path="/customerpannel" element={<Customerpannel />}>
    <Route path="myaccount" element={<Myaccount />} />
    <Route path="whatsappbuisness" element={<Vilvawapro />} />
    <Route path="vilvawaba" element={<Vilvawaba />} />
    <Route path="voicebot" element={<Voicebot />} />
    <Route path="ecomsass" element={<Ecom />} />
    <Route path="verification" element={<Verification />} />
    <Route path="vilvakart" element={<Vilvakart />} />
    <Route path="activesubscription" element={<Activesubscription />} />
    <Route path="paymenthistory" element={<History />} />
    <Route path="invoice" element={<Invoice />} />
    <Route path="wallet" element={<Wallet />} />
  </Route>
</Routes>

      </Router>
    </>
  );
}

export default App;
