import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import Pagesblog from "../blog/pagesblog/Pagesblog"
import { Helmet } from 'react-helmet';

const Fintechsolution = () => {
  return (
    <div>
       <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="FinTech solutions, financial technology, digital finance, banking technology, financial services, innovative fintech"
        />
        <meta
          name="description"
          content="Transform your financial operations with Vilva Business's FinTech solutions, offering innovative technology for digital finance, banking, and financial services."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>FinTech Solutions - Vilva Business</title>
      </Helmet>
      <Navbar/>
     

<div className="features-layout2 mt-5 pt-5">
  <div className="row mx-auto container">
    <div className="col-12 col-md-4">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-1.png') }}" alt="" />
        </div>
        <h3>Enhanced Efficiency</h3>
        <p> Automates financial processes and transactions, reducing the need for manual intervention. This increases speed and accuracy in managing finances, allowing users to handle complex tasks with ease. Streamlining operations leads to time and cost savings.</p>
      </div>
    </div>
    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg-c"></div>
        <div className="imgforcol">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-2.png') }}" alt="" />
        </div>
        <h3>Greater Accessibility</h3>
        <p> Provides users with the ability to access financial services from anywhere at any time through digital platforms. This convenience allows for seamless transactions and financial management, overcoming geographical and time constraints. Enhanced accessibility improves overall user experience.</p>
      </div>
    </div>
    <div className="col-md-4 col-12">
      <div className="layout-col-2 mt-3">
        <div className="top-bg"></div>
        <div className="imgforcol-test">
          <img src="{{ asset('https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/layout-3.png') }}" alt="" />
        </div>
        <h3>Advanced Analytics</h3>
        <p> Delivers data-driven insights and personalized recommendations to help users make informed financial decisions. By analyzing financial patterns and trends, fintech solutions offer tailored strategies for optimizing financial outcomes. This capability supports better financial planning and management.</p>
      </div>
    </div>
  </div>
</div>

<div className="start-project mt-5">
  <div className="row mx-auto">
    <div className="col-12 col-md-6 project">
      <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/project.png" alt="" className="img-fluid" />
    </div>
    <div className="col-12 col-md-6 project-des" style={{ color: "#001e60" }}>
      <div className="project-des-con">
        <h2>Start your project with Us today</h2>
        <p>Get started with our cutting-edge e-commerce solutions today and elevate your online business with tailored strategies and innovative technology. Transform your customer experience and drive growth effortlessly!</p>
        <div className="layout-btn">
          <button type="button">Start a Project &nbsp;&nbsp;&nbsp; <i className="ri-arrow-right-line"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="latest-news pt-5 mb-5">
  <h3 style={{ color: "#4a12f0", textAlign: "center", paddingBottom: "30px" }}>Latest News</h3>
  <div className="container">
    <div className="row">
      <div className="col-md-7 col-12">
        <div className="latest-img-all">
          <img src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg" alt="" style={{ height: "85%", width: "50%" }} />
          <div className="img-des">
            <h6 style={{ color: "#4a12f0" }}>Blog</h6>
            <h3 style={{ color: "#001e60" }}>Trends and Innovations in Opencart website development</h3>
            <p style={{ color: "#28334a" }}>Opencart website development is evolving with several key trends and innovations that enhance functionality and user experience. The integration of AI and machine learning facilitates personalized shopping experiences and predictive analytics.</p>
            <div className="read-more">
              <button type="button">Read More &nbsp;&nbsp; <i className="fa-solid fa-arrow-right"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 col-12">
        <div className="row">
          <div className="blog">
            <h5>Open Cart</h5>
            <h4>Advanced Customization</h4>
            <div className="b-btn">
              <button type="button">Read More &nbsp;&nbsp; <i className="fa-solid fa-arrow-right"></i></button>
              <i className="fa-solid fa-newspaper" style={{ color: "#616899", fontSize: "20px" }}></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="blog">
            <h5>Open Cart</h5>
            <h4>AI-Enhanced Personalization</h4>
            <div className="b-btn">
              <button type="button">Read More &nbsp;&nbsp; <i className="fa-solid fa-arrow-right"></i></button>
              <i className="fa-solid fa-newspaper" style={{ color: "#616899", fontSize: "20px" }}></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="blog">
            <h5>Open Cart</h5>
            <h4>Enhanced Security and Multi-Channel Integration</h4>
            <div className="b-btn">
              <button type="button">Read More &nbsp;&nbsp; <i className="fa-solid fa-arrow-right"></i></button>
              <i className="fa-solid fa-newspaper" style={{ color: "#616899", fontSize: "20px" }}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<Pagesblog/>

<Footer/>
    </div>
  )
}

export default Fintechsolution
