import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Pagesblog from "../blog/pagesblog/Pagesblog";
import { Helmet } from "react-helmet";

const Iossolution = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="keywords"
          content="ios solutions, mobile app development, iOS app development"
        />
        <meta
          name="description"
          content="Explore iOS solutions with Vilva Business. Contact us for iOS app development, consulting, and support."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>iOS Solutions - Vilva Business</title>
      </Helmet>
      <Navbar />
      

      <div className="prod-des container mt-5">
        <div className="prod-head">
          <h5 style={{ color: "#4a12f0",textAlign: "center" }}>Unlock premium user engagement</h5>
          <h2 style={{ color: "#001e60" }}>
            Deliver superior app quality and user experience
          </h2>
        </div>
        <div className="benifits mx-auto">
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuite.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>
              High Performance and Reliability
            </h3>
            <p>
              iOS apps are known for their smooth performance and reliability,
              thanks to Apple's optimized hardware and software integration.
              This ensures a seamless user experience and fewer crashes.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimage.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Secure Environment</h3>
            <p>
              iOS provides a robust security framework with features like data
              encryption, secure booting, and app sandboxing. This high level of
              security helps protect user data and maintain privacy.
            </p>
          </div>
          <div className="col-md-3 col-12 ben-ai">
            <img
              src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/growsuiteimages.png"
              alt=""
            />
            <h3 style={{ color: "#001e60" }}>Access to Latest Technologies</h3>
            <p>
              iOS developers often get early access to new technologies and APIs
              introduced by Apple, such as advanced AR capabilities with ARKit,
              which can be integrated into apps before they become available on
              other platforms.
            </p>
          </div>
        </div>
      </div>

      <div className="usecase-all">
        <div className="usecase-head">
          <h5 style={{ color: "#4a12f0" }}>Use Cases</h5>
          <h2 style={{ color: "#001e60" }}>
            Manage Every Endpoint in One Place
          </h2>
          <h5 style={{ color: "#28334a" }}>
            Streamline business workflows efficiently
          </h5>
        </div>
        <div className="row container pt-5 mx-auto">
          <div className="col-md-6 col-12">
            <div className="useimg">
              <img
                src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/vilvabusiness/post.jpg"
                alt=""
                style={{ height: "79%", width: "90%" }}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row" id="row1">
              <h3  style={{ color: "#040e56" }}>Business Applications</h3>
              <p>
                Custom apps for streamlining business processes, managing
                workflows, and enhancing productivity in various industries.
              </p>
            </div>
            <div className="row" id="row2">
              <h3  style={{ color: "#040e56" }}>Healthcare Solutions</h3>
              <p>
                Apps for patient management, telemedicine, fitness tracking, and
                medical information access to improve healthcare delivery.
              </p>
            </div>
            <div className="row" id="row3">
              <h3  style={{ color: "#040e56" }}>Retail and E-Commerce</h3>
              <p>
                Apps for shopping, order management, and customer engagement to
                drive sales and improve the shopping experience.
              </p>
            </div>
            <div className="row" id="row4">
              <h3  style={{ color: "#040e56" }}>Education and E-Learning</h3>
              <p>
                Educational apps for interactive learning, course management,
                and skill development, enhancing the learning process.
              </p>
            </div>
            <div className="row" id="row4">
              <h3  style={{ color: "#040e56" }}>Entertainment and Media</h3>
              <p>
                Apps for streaming content, gaming, and social networking,
                providing engaging and interactive experiences for users.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ready mt-5 pb-5 container">
        <div className="ready-des">
          <h3 style={{ color: "#001e60" }}>
            Craft Your Success – Partner with Vilva Business Today!
          </h3>
          <h5 style={{ color: "#28334a" }}>
            Experience device management, made easy!
          </h5>
          <div className="contact-us">
            <button type="button">
              <a href="/contact" target="_blank" style={{ color: "white" }}>
                Initiate Your Project
              </a>
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <Pagesblog />

      <Footer />
    </div>
  );
};

export default Iossolution;
